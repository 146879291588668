import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from "@angular/core";
import {Subscription} from 'rxjs';
import {HelperFunctions} from "../../HelperFunctions.class";
import {LayoutService} from "../../../dashboard/sharedComponents/sidebar/layout.service";

declare var UIkit;
declare var ResizeObserver;

@Component({
  selector: 'fs-modal',
  template: `
    <div #modal class="uk-modal-full" [id]="id" uk-modal="container: #modal-container">
      <div class="uk-modal-dialog">
        <div #header class="uk-modal-header uk-flex uk-flex-middle" [ngClass]="classTitle">
          <div [class.uk-invisible]="!back" class="uk-width-medium@l uk-width-auto uk-flex uk-flex-center">
            <button class="uk-button uk-button-link" [class.uk-disabled]="!back" [disabled]="!back" (click)="backClicked()">
              <icon name="west" [flex]="true" [ratio]="isMobile?1.4:2"></icon>
            </button>
          </div>
          <div [class.uk-invisible]="!title"
               class="uk-width-expand uk-padding-small uk-padding-remove-vertical uk-flex uk-flex-center">
            <h6 *ngIf="isMobile" class="uk-margin-remove">{{title}}</h6>
            <h2 *ngIf="!isMobile" class="uk-margin-remove">{{title}}</h2>
          </div>
          <div class="uk-width-medium@l uk-width-auto uk-flex" 
               [class.uk-flex-center]="okButton" [class.uk-flex-right]="!okButton">
            <button *ngIf="okButton" class="uk-button uk-button-default" [disabled]="okButtonDisabled"
                    [class.uk-disabled]="okButtonDisabled" (click)="ok()">
              {{okButtonText}}
            </button>
            <button *ngIf="!okButton && cancelButton" class="uk-close uk-icon" (click)="cancel()">
              <icon name="close" [ratio]="1.4"></icon>
            </button>
          </div>
        </div>
        <div #body class="uk-modal-body uk-overflow-auto uk-border-box"  [ngStyle]="{'height.px': bodyHeight}">
          <div class="uk-container" style="min-height: 100%" [ngClass]="classBody">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  `
})
export class FullScreenModalComponent implements AfterViewInit, OnDestroy {
  private static FS_MODAL_COUNTER: number = 0;

  id: string = "fs-modal";
  @Input() classTitle: string = "uk-background-primary uk-light";
  @Input() classBody: string = 'uk-container-large';
  back: boolean = false;
  /**
   * if the value is true then on ok clicked, modal will stay open.
   */
  public stayOpenInBack: boolean = false;
  title: string;
  cancelButton: boolean = true;
  okButton: boolean = false;
  okButtonText = 'OK';
  isMobile: boolean = false;
  @Input()
  okButtonDisabled = false;
  @Output()
  okEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  cancelEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
	@ViewChild('modal') modal: ElementRef;
  @ViewChild('header') header: ElementRef;
  @ViewChild('body') body: ElementRef;
  bodyHeight: number;
  private subscriptions: any[] = [];
  
  constructor(private cdr: ChangeDetectorRef, private layoutService: LayoutService) {
  }
  
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.changeHeight();
  }

  ngOnInit() {
    FullScreenModalComponent.FS_MODAL_COUNTER++;
    this.id = 'fs-modal-' + FullScreenModalComponent.FS_MODAL_COUNTER;
    this.subscriptions.push(this.layoutService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
    }))
  }

  ngAfterViewInit() {
    if(typeof window !== "undefined") {
      let observer = new ResizeObserver(entries => {
        for (let entry of entries) {
          this.changeHeight();
        }
      });
      observer.observe(this.header.nativeElement);
      this.subscriptions.push(observer);
    }
  }
  
  /* Height = Viewport - header - (Body padding) */
  changeHeight() {
    if(typeof window !== "undefined" && this.header) {
      this.bodyHeight = window.innerHeight - this.header.nativeElement.offsetHeight;
      this.cdr.detectChanges();
    }
  }
  
  ngOnDestroy() {
    if(typeof document !== "undefined") {
      const element = document.getElementById("modal-container");
      for (let i = element.childNodes.length - 1; i >= 0; --i) {
        let child: ChildNode = element.childNodes[i];
        if (child['id'] == this.id) {
          child.remove();
        }
      }
    }
    this.subscriptions.forEach(subscription => {
      if(subscription instanceof Subscription) {
        subscription.unsubscribe();
      } else if(typeof ResizeObserver != 'undefined' && subscription instanceof ResizeObserver) {
        subscription.disconnect();
      }
    });
  }
  
  get isOpen() {
    return this.modal && UIkit.modal(this.modal.nativeElement).isToggled();
  }
  
  open() {
    UIkit.modal(this.modal.nativeElement).show();
    this.body.nativeElement.scrollTo(0, 0);
  }

  cancel() {
		UIkit.modal(this.modal.nativeElement).hide();
    HelperFunctions.scroll();
    this.cancelEmitter.emit();
  }

  backClicked() {
    if(this.stayOpenInBack) {
      HelperFunctions.scroll();
      this.cancelEmitter.emit();
    } else {
      this.cancel();
    }
  }
  
  ok() {
    UIkit.modal(this.modal.nativeElement).hide();
    HelperFunctions.scroll();
    this.okEmitter.emit(true);
  }
}
