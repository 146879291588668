import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ContactComponent} from './contact.component';
import {PreviousRouteRecorder} from "../openaireLibrary/utils/piwik/previousRouteRecorder.guard";

@NgModule({
    imports: [
        RouterModule.forChild([
            { path: '', component: ContactComponent, canActivate: [], canDeactivate: [PreviousRouteRecorder]}
        ])
    ]
})
export class ContactRoutingModule { }
