import {
  Author,
  HostedByCollectedFrom,
  Journal,
  OARoutes,
  Organization,
  Project,
  ResultTitle
} from "../result-preview/result-preview";
import {Measure, Metric} from "./resultLandingInfo";

export class SearchResult {
  title: ResultTitle;
  id: string;
  relcanId: string;
  objId: string;
  DOIs: string[] = [];
  identifiers: Map<string, string[]>;
  measure: Measure;
  date: string;
  dateofacceptance: Date;
  journal: Journal;

  //publications & datasets & orp & software & projects & dataproviders:
  description: string;

  //publications & datasets & orp & software & organizations:
  projects: Project[];

  //datasets & orp & publications & software
  year: string;
  embargoEndDate: Date | string;
  authors: Author[];
  countriesForResults: string[];
  languages: string[];
  hostedBy_collectedFrom: HostedByCollectedFrom[];

  //datasets & orp & software:
  publisher: string;

  //software
  programmingLanguages: string[];

  //dataproviders & projects:
  organizations: Organization[];

  //projects:
  acronym: string;
  code: string;
  // callIdentifier?: string; // currently not parsed
  funderName: string;
  funderShortname: string;
  budget?: string;
  contribution?: string;
  currency?: string;
  startYear: number;
  endYear: number;
  openAccessMandatePublications: boolean;
  openAccessMandateDatasets: boolean;

  //organizations:
  country: string;

  //dataproviders:
  englishname: string;
  type: string;
  websiteURL: string;
  OAIPMHURL: string;
  compatibility: string;
  compatibilityUNKNOWN: boolean;
  countries: string[];
  subjects: string[];
  originalId: string;

  entityType: string;
  types: string[];
  enermapsId: string;

  oaRoutes: OARoutes;
  publiclyFunded: boolean;

  constructor() {
  }

}
