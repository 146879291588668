import {Dates} from '../../utils/string-utils.class';
export class Filter{
    public title: string; // eg Type
    public filterId: string; // type (name in url parameter)
    public originalFilterId: string; // (in index)
    public countSelectedValues: number = 0;
    public values: Value[] = [];
    public filterOperator: string ='or';
    public valueIsExact: boolean = true;  // for search table view, if value is contained or is equal with column entry
    public filterType: string = "checkbox";
    public type?: string = "keyword";
    public radioValue?: string = "";
//    public uniqueValueIdSelected: string;
    public countAllValues?: number = -1; // -1: not yet requested, 0: request failed, >0 OK
    // public isOpen?: boolean = false;
    public isViewAllOpen?: boolean = false;
    public countUnfilteredValues?: number = 0;
}

export class Value{
    public name: string; //eg Article, Journal
    public id: string; //0001
    public selected: boolean = false;
    public number: number = 0;

}
export class AdvancedField{
    public id: string; //
    public param:string;
    public name: string; //
    public type: string = "keyword"; //keyword, static or dynamic
    public value: string = '';
    public valueLabel: string = '';
    public includes: boolean = true;
    public operatorId: string;
    public operatorName: string ="";
    public valid: boolean = true;
    public dateValue:DateValue = new DateValue("any");

    constructor(id:string,param:string,name:string, type:string, value:string,operator:string, includes: boolean = true){
      this.id = id;
      this.param = param;
      this.name = name;
      this.type = type;
      this.value = value;
      this.operatorId =  operator;
      this.includes = includes;
      // this.operatorName =  "AND";

    }
}
export class DateValue{
    public types = ["1mon","2mon","3mon","6mon","12mon","2year","5year","10year","range"];
    public typesTitle = ["Last month","Last 2 months","Last 3 months","Last 6 months","Last year","Last 2 years","Last 5 years","Last 10 years", "Specify date range..."];
    public type: string ;
    public from:Date = new Date();
    public to:Date = new Date();
    constructor(type:string = "any"){
      this.setDatesByType(type);
    }
    public setDatesByType(type:string){
      if(this.types.indexOf(type) == -1){
        type=this.types[0];
      }
      this.type = type;
      this.to = Dates.getDateToday();
      if(this.type == "range" || this.type == "any"){ // for type "any" just to  initiate with values
        this.from = Dates.getDateXMonthsAgo(1);
      }else if(this.type == "1mon"){
        this.from = Dates.getDateXMonthsAgo(1);
      }else if(this.type == "2mon"){
        this.from = Dates.getDateXMonthsAgo(2);
      }else if(this.type == "3mon"){
        this.from = Dates.getDateXMonthsAgo(3);
      }else if(this.type == "6mon"){
        this.from = Dates.getDateXMonthsAgo(6);
      }else if(this.type == "12mon"){
        this.from = Dates.getDateXMonthsAgo(12);
      }else if(this.type == "2year"){
        this.from = Dates.getDateXYearsAgo(2);
      }else if(this.type == "5year"){
        this.from = Dates.getDateXYearsAgo(5);
      }else if(this.type == "10year"){
        this.from = Dates.getDateXYearsAgo(10);
      }
    }

}
export class OPERATOR{
    public static AND: string ="and";
    public static OR: string ="or";
    public static NOT: string ="not";

}

export class AutoCompleteValue{
  public id: string;
  public label: string;
}
