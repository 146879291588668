import {SearchFieldsBase} from "src/app/openaireLibrary/utils/properties/searchFields.base";
import {properties} from "src/environments/environment";

export class SearchFields extends SearchFieldsBase {
  public RESULT_STATIC_FIELDS = ["resultbestaccessright", "haslicense", "type",
    "peerreviewed", "isgreen", "isindiamondjournal", "publiclyfunded"];

  public RESULT_REFINE_FIELDS = [
    "instancetypename",
    "openaccesscolor",
    'fos',
    "country",
    "relfunder", "relfundinglevel0_id", "relfundinglevel1_id", "relfundinglevel2_id",
    "relproject", "resulthostingdatasource", "community"];

  RESULT_FIELDS_ORDERED = [
    {type: "refine", title: "", values: ["type"]},
    {type: "refine", title: "", values: ["instancetypename", "peerreviewed"]},
    {type: "refine", title: "", values: ["resultbestaccessright", "haslicense"]},
    {type: "refine", title: "Access Routes", values: ["isgreen", "openaccesscolor", "isindiamondjournal"]},
    {type: "range", title: "", values: ["resultacceptanceyear", "resultacceptanceyear"]},
    {type: "refine", title: "", values: [
        'fos', "publiclyfunded", "country", "relfunder",
        "relfundinglevel0_id", "relfundinglevel1_id", "relfundinglevel2_id",
        "relproject", "resulthostingdatasource", "community"
      ]}
  ];

  ORGANIZATION_REFINE_FIELDS: string[] = [];
  ORGANIZATION_ADVANCED_FIELDS: string[] = ["q", "organizationlegalname", "organizationlegalshortname", "pid"];

  public static getParameterOrder(fieldId: string, params): number {
    let fields = [];
    let searchFields = new SearchFields();
    if (searchFields.RESULT_REFINE_FIELDS.indexOf(fieldId) != -1) {
      fields = searchFields.RESULT_REFINE_FIELDS;
    } else if (searchFields.PROJECT_REFINE_FIELDS.indexOf(fieldId) != -1) {
      fields = searchFields.PROJECT_REFINE_FIELDS
    } else if (searchFields.ORGANIZATION_REFINE_FIELDS.indexOf(fieldId) != -1) {
      fields = searchFields.ORGANIZATION_REFINE_FIELDS;
    } else if (searchFields.DATASOURCE_REFINE_FIELDS.indexOf(fieldId) != -1) {
      fields = searchFields.DATASOURCE_REFINE_FIELDS;
    }
    let index = fields.indexOf(fieldId);
    let paramIndex = 0;
    if (index != -1) {
      for (let keyValue of params) {
        if (fields.indexOf(keyValue[0]) > index) {
          break;
        }
        paramIndex++
      }
    }
    return paramIndex;
  }
}

export enum OpenaireEntities {
  RESULTS = "Research products",
  PUBLICATIONS = "Publications",
  DATASETS = "Research data",
  SOFTWARE = "Research software",
  OTHER = "Other research products",
  PROJECTS = "Projects",
  ORGANIZATIONS = "Organizations",
  DATASOURCES = "Data sources",
  SERVICES = "Services",
  COMMUNITIES = "Research communities",

  RESULT = "Research product",
  PUBLICATION = "Publication",
  DATASET = "Research data",
  SOFTWARE_SINGULAR = "Research software",
  OTHER_SINGULAR = "Other research product",
  PROJECT = "Project",
  ORGANIZATION = "Organization",
  DATASOURCE = "Data source",
  SERVICE = "Service",
  COMMUNITY = "Research community",

  RESULTS_FILE = "research-products",
  PUBLICATIONS_FILE = "publications",
  DATASETS_FILE = "research-data",
  SOFTWARE_FILE = "research-software",
  OTHER_FILE = "other-research-products",
  PROJECTS_FILE = "projects",
  ORGANIZATIONS_FILE = "organizations",
  DATASOURCES_FILE = "data-sources",
  SERVICES_FILE = "services",
}
