import {NgModule} from '@angular/core';
import {SharedModule} from '../../../openaireLibrary/shared/shared.module';
import {SearchInputComponent} from './search-input.component';
import {IconsModule} from '../../utils/icons/icons.module';
import {InputModule} from "../input/input.module";
import {ClickModule} from "../../utils/click/click.module";

@NgModule({
  imports: [SharedModule, IconsModule, InputModule, ClickModule],
  declarations: [SearchInputComponent],
  exports: [SearchInputComponent]
})
export class SearchInputModule {}
