import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {StringUtils} from '../string-utils.class';
import {EnvProperties} from '../properties/env-properties';
import {Observable, of} from "rxjs";
import {ConfigurationService} from "../configuration/configuration.service";
import {switchMap, take} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class PiwikService {
  constructor(private http: HttpClient, private configurationService: ConfigurationService) {
  }

  trackViewForCustomUrl(properties: EnvProperties, title, pageparams): any {
    if (typeof location !== 'undefined' && properties.enablePiwikTrack) {
      return this.configurationService.portalAsObservable.pipe(take(1), switchMap(portal => {
        let piwik = portal ? portal.piwik : null;
        return this.doTrackView(properties, title, piwik, location.href.split("?")[0] + "?" + pageparams);
      }));
    } else {
      return of(null);
    }
  }

  trackView(properties: EnvProperties, title) {
    if (typeof location !== 'undefined' && properties.enablePiwikTrack) {
      return this.configurationService.portalAsObservable.pipe(take(1), switchMap(portal => {
        let piwik = portal ? portal.piwik : null;
        return this.doTrackView(properties, title, piwik, location.href);
      }));
    } else {
      return of(null);
    }
  }

  trackDownload(properties: EnvProperties, type = "") {
    if (typeof location !== 'undefined' && properties.enablePiwikTrack) {
      return this.configurationService.portalAsObservable.pipe(take(1), switchMap(portal => {
        let piwik = portal ? portal.piwik : null;
        return this.doTrackDownload(properties, type, piwik);
      }));
    } else {
      return of(null);
    }
  }

  private doTrackView(properties: EnvProperties, title, siteId, pageURL): Observable<any> {
    let ua = this.getUserAgent();
    let referrer = this.getReferrer();
    let piwikId = ((siteId != null) ? siteId : properties.piwikSiteId);
    if (typeof location !== 'undefined' && piwikId) {
      var url = properties.piwikBaseUrl + piwikId + "&rec=1&url=" + StringUtils.URIEncode(pageURL) + "&action_name=" + StringUtils.URIEncode(title) +
          ((ua != null && ua.length > 0) ? ('&ua=' + StringUtils.URIEncode(ua)) : '') +
          ((referrer != null && referrer.length > 0) ? ('&urlref=' + StringUtils.URIEncode(referrer)) : '');
      // return Observable.of(new Object()); // for testing
      return this.http.get(url, {responseType: 'blob'});
    }
  }

  doTrackDownload(properties: EnvProperties, type = "", siteId): any {
    var ua = this.getUserAgent();
    var referrer = this.getReferrer();
    var url = properties.piwikBaseUrl + ((siteId != null) ? siteId : properties.piwikSiteId) + "&rec=1&url=" + StringUtils.URIEncode(location.href) + "&download=" + StringUtils.URIEncode(location.href + "#" + type) +
        ((ua != null && ua.length > 0) ? ('&ua=' + StringUtils.URIEncode(ua)) : '') +
        ((referrer != null && referrer.length > 0) ? ('&urlref=' + StringUtils.URIEncode(referrer)) : '');
    return this.http.get(url, {responseType: 'blob'});

  }

  public doTrackEvent(properties: EnvProperties, title, siteId, pageURL, eventCategory, eventAction, eventName, eventValue = 0): Observable<any> {
    let ua = this.getUserAgent();
    let referrer = this.getReferrer();
    let piwikId = ((siteId != null) ? siteId : properties.piwikSiteId);
    if (typeof location !== 'undefined' && piwikId) {
      var url =`${properties.piwikBaseUrl}${piwikId}&rec=1&url=${encodeURIComponent(pageURL)}&action_name=${encodeURIComponent(title)}&e_c=${encodeURIComponent(eventCategory)}&e_a=${encodeURIComponent(eventAction)}&e_n=${encodeURIComponent(eventName)}&e_v=${encodeURIComponent(eventValue)}`
        +((ua != null && ua.length > 0) ? ('&ua=' + StringUtils.URIEncode(ua)) : '') +
        ((referrer != null && referrer.length > 0) ? ('&urlref=' + StringUtils.URIEncode(referrer)) : '');
      // console.log(trackingApiUrl)
      console.log(url)
      // return of(new Object()); // for testing

      return this.http.get(url, {responseType: 'blob'});
    }
  }

  private getUserAgent() {
    if (typeof navigator !== 'undefined') {
      return navigator.userAgent;
    } else {
      return null;
    }
  }

  private getReferrer() {
    var referrer = "";
    if (typeof document !== 'undefined') {
      referrer = document.referrer;
    }
    if ((referrer == null || referrer.length == 0) && typeof localStorage !== 'undefined') {
      referrer = localStorage.getItem('previousRoute');
    }
    return referrer;
  }
}
