import {Directive} from "@angular/core";
import {BaseComponent} from "../../sharedComponents/base/base.component";
import {IndicatorUtils, StakeholderUtils} from "./indicator-utils";
import {ConnectHelper} from "../../connect/connectHelper";
import {Indicator, IndicatorSize} from "../../monitor/entities/stakeholder";
import {statsToolParser} from "./cache-indicators/cache-indicators";

@Directive()
export abstract class StakeholderBaseComponent extends BaseComponent {

  stakeholderUtils: StakeholderUtils = new StakeholderUtils();

  get entities() {
    return this.stakeholderUtils.entities;
  }

  get showVisibility() {
    return this.stakeholderUtils.showField(this.stakeholderUtils.visibilities);
  }

  get showType() {
    return this.stakeholderUtils.showField(this.stakeholderUtils.types);
  }

  get showFunderType() {
    return this.stakeholderUtils.showField(this.stakeholderUtils.funderTypes);
  }

  getFunderTypeLabel(value: any) {
    return this.stakeholderUtils.getLabel(this.stakeholderUtils.funderTypes, value);
  }

  get showLocale() {
    return this.stakeholderUtils.showField(this.stakeholderUtils.locales);
  }

  protected navigateToError() {
    this._router.navigate([this.properties.errorLink], {queryParams: {'page': this._router.url}});
  }

  setProperties(id, type = null, configurationService) {
    this.properties.adminToolsCommunity = id;
    if (type) {
      this.properties.adminToolsPortalType = type;
    } else {
      ConnectHelper.setPortalTypeFromPid(id);
    }
    configurationService.initPortal(this.properties, this.properties.adminToolsCommunity);
  }
}

@Directive()
export abstract class IndicatorStakeholderBaseComponent extends StakeholderBaseComponent {

  indicatorUtils: IndicatorUtils = new IndicatorUtils();
  indicator: Indicator;

  public getNumberClassBySize(size: IndicatorSize): string {
    if (size === 'small') {
      return 'uk-width-medium';
    } else if (size === 'medium') {
      return 'uk-width-max-content';
    } else {
      return 'uk-width-max-content';
    }
  }

  public getChartClassBySize(size: IndicatorSize): string {
    if (size === 'small') {
      return 'uk-width-1-3@xl uk-width-1-2@m uk-width-1-1';
    } else if (size === 'medium') {
      return 'uk-width-1-2@l uk-width-1-1';
    } else {
      return 'uk-width-1-1';
    }
  }

  public getActivePathIndex(indicator: Indicator = this.indicator): number {
    return indicator?.activePath ? indicator.activePath : 0;
  }

  public getActiveIndicatorPath(indicator: Indicator = this.indicator) {
    if(indicator?.indicatorPaths.length >  this.getActivePathIndex(indicator)) {
      return indicator.indicatorPaths[this.getActivePathIndex(indicator)];
    }
    return null;
  }
}
