import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {EnvProperties} from "../utils/properties/env-properties";
import {properties} from "../../../environments/environment";
import {RecaptchaComponent} from "ng-recaptcha";

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html'
})

export class ContactUsComponent {
  @Input() public contactForm: FormGroup;
  @Input() public formTitle: string;
  @Input() public organizationTypes: string[];
  @Input() public buttonClass: string;
  @Input() public sendButton: string = "Send";
  @Input() public smallForm: boolean = false;
  @Input() public scrollspy: boolean = false;
  @Input() public sending: boolean = false;
  @Output() sendEmitter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('captchaElem') captchaElem: RecaptchaComponent;

  public properties: EnvProperties = properties;
  
  public send() {
    this.sendEmitter.emit({
      valid: this.contactForm.valid
    });
  }
  
  public handleRecaptcha(captchaResponse: string) {
    this.contactForm.get('recaptcha').setValue(captchaResponse);
  }

  public resetRecaptcha() {
    this.captchaElem.reset();
  }
}
