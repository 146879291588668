export class JsonldDocument {
	id: string;
	title: String[];
	description: String[];
	identifier: Identifier[];
	url: String[];
	sameAs: String[];

}

export class Dataset extends JsonldDocument {
	creator: Person[];
	dateCreated: String[];
	dateModified: String[];
	citation: Citation[];
	license: License[];
	keyword: String[];
	issn:String[];
	headline:String[];
	alternativeHeadline:String[];
	isPartOf;
}

export interface Identifier {
	schema?: string;
	id?: string;
}

export interface Person {
	givenName: string;
	familyName: string;
	name: string;
}

export class Citation {
	title: string[];
	identifier: Identifier[];
}

export interface License {
	title: string[];
	identifier: Identifier[];
}
export class Organization extends JsonldDocument {
	alumni:Person;
	areaServed:string; //The geographic area where a service or offered item is provided. Supersedes serviceArea.
	funder:Organization;
	legalName:string;
	logo:string;
}
