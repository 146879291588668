import {Inject, Injectable, InjectionToken, PLATFORM_ID} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {timeout} from 'rxjs/operators';
import {isPlatformServer} from "@angular/common";
import {properties} from "../../environments/environment";

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {

    private static TIMEOUT_WHITELIST = [
        properties.csvAPIURL, properties.registryUrl, properties.claimsAPIURL,
        properties.searchCrossrefAPIURL, properties.searchDataciteAPIURL,
        properties.statisticsAPIURL, properties.monitorStatsFrameUrl];

    constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number, @Inject(PLATFORM_ID) private platformId: any) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method !== 'GET' || this.isService(req, TimeoutInterceptor.TIMEOUT_WHITELIST)) {
            return next.handle(req);
        }

        let serverTime = properties.environment == "production" ? 3000 : 4000;
        let clientTime = properties.environment == "production" ? 6000 : 12000;
        const timeoutValue = isPlatformServer(this.platformId) ? serverTime : clientTime;//req.headers.get('timeout') || this.defaultTimeout;
        const timeoutValueNumeric = Number(timeoutValue);
        return next.handle(req).pipe(timeout(timeoutValueNumeric));
    }

    isService(req: HttpRequest<any>, service: string | string[]): boolean {
        if (Array.isArray(service)) {
            return !!service.find(element => req.url.indexOf(element) !== -1);
        } else {
            return req.url.indexOf(service) !== -1;
        }
    }
}
