import { NgModule,  ModuleWithProviders }            from '@angular/core';
import { CommonModule}        from '@angular/common';
import { FormsModule }         from '@angular/forms';

// import {  RouterModule } from "@angular/router";

import {HelperComponent} from './helper.component';
// import {HelperServiceModule} from './helperService.module';
import {HelperService} from './helper.service';
import {SafeHtmlPipeModule} from '../pipes/safeHTMLPipe.module';

@NgModule({
  imports: [
    CommonModule, FormsModule,SafeHtmlPipeModule
    //  RouterModule//,HelperServiceModule
  ],
  declarations: [
    HelperComponent    
  ],
  providers:[HelperService],
  exports: [
    HelperComponent
    ]
})


export class HelperModule{
  // static forRoot(config: any): ModuleWithProviders {
  //   // User config get logged here
  //   console.log(config);
  //   return {
  //     ngModule: HelperService,
  //     providers: [HelperService, {provide: 'config', useValue: config}]
  //   };
  // }
}
