import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Email} from './email';
import {CustomOptions} from '../../services/servicesUtils/customOptions.class';
import {EmailRecaptcha} from "../entities/EmailRecaptcha";
import {Observable} from "rxjs";
import {EnvProperties} from "../properties/env-properties";
import {properties} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    constructor(private http:HttpClient) {
    }
    
    notifyManagers(pid: string, role: "manager" | "subscriber", email: Email) {
        return this.http.post(properties.adminToolsAPIURL + 'notifyManagers/' + pid + '/' + role, email, CustomOptions.getAuthOptions())
    }
    
    notifyNewManager(email: Email) {
        return this.http.post(properties.adminToolsAPIURL + 'notifyNewManager', email, CustomOptions.getAuthOptions())
    }

    sendEmail(properties: EnvProperties, email: Email) {
        return this.http.post(properties.adminToolsAPIURL + "sendMail/", email, CustomOptions.registryOptions());
    }

    contact(properties: EnvProperties, email: Email, recaptcha: string = null):Observable<boolean> {
        const data: EmailRecaptcha = new EmailRecaptcha();
        data.email = email;
        data.recaptcha = recaptcha;
        return this.http.post<boolean>(properties.adminToolsAPIURL + 'contact', data);
    }

}
