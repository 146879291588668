import {NgModule} from "@angular/core";
import {ClickOutsideOrEsc} from "./click-outside-or-esc.directive";
import {LongClick} from "./long-click.directive";

@NgModule({
  imports: [],
  declarations: [
    ClickOutsideOrEsc, LongClick
  ],
  exports: [
    ClickOutsideOrEsc, LongClick
  ]
})
export class ClickModule {}
