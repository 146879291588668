import {
  Author,
  HostedByCollectedFrom,
  Journal, OARoutes,
  Organization,
  Project, RelationDatasource,
  RelationResult
} from "../result-preview/result-preview";
import {isArray} from "rxjs/internal-compatibility";
import {OpenaireEntities} from "../properties/searchFields";
import {StringUtils} from "../string-utils.class";

export interface Id {
  type: "pmid" | "doi" | "pmc" | "handle" | "openaire" | "swhid";
  value: string;
  trust: number
}

export interface Reference {
  name?: string;
  ids: Id[];
}

export interface Context {
  labelContext: string;
  idContext: string;
  labelCategory: string;
  idCategory: string;
  labelConcept: string;
  idConcept: string;
  link?: string;
  logo?: string;
}

export interface Measure {
  bip?: Metric[]
  counts?: Metric[]
  countsPerDatasource?: MetricPerDatasource[];
}

export interface MetricPerDatasource {
  datasourceId: string,
  datasourceName: string,
  views: number,
  downloads: number
}

export interface Metric {
  name: string,
  order: number,
  icon: string,
  value: any
}

class Field {
  path: string[];
  value: string;
}

export class BelongsTo {
  public static result: BelongsTo = null;
  public static project: BelongsTo = null;
  public static organization: BelongsTo = null;
  public static datasource: BelongsTo = null;

  fields: Field[];
  message: string;

  public static check(element: any, field: Field): boolean {
    if (field) {
      field = Object.assign({}, field);
      let json: any = element;
      if (field.path.length > 0 && json) {
        if (Array.isArray(json)) {
          return json.findIndex(value => BelongsTo.check(value, field)) != -1;
        } else {
          json = json[field.path[0]];
          field.path = field.path.slice(1);
          return BelongsTo.check(json, field);
        }
      }
      return json == field.value;
    }
    return false;
  }
}

export class ResultLandingInfo {
  relcanId;
  objIdentifier: string;
  // PUBLICATION, DATASET, SOFTWARE, ORP
  record;
  resultType: "publication" | "dataset" | "other" | "software";
  // PUBLICATION, DATASET, SOFTWARE, ORP, DELETED_BY_INFERENCE
  title: string;
  accessMode: string;
  authors: Author[];
  date: string;
  dateofacceptance: Date;
  embargoEndDate: Date;
  types: string[];
  identifiers: Map<string, string[]>;     //key is the classname
  languages: string[];
  countries: string[];
  description: string;

  hostedBy_collectedFrom: HostedByCollectedFrom[];

  // PUBLICATION, DATASET, SOFTWARE, ORP
  fundedByProjects: Project[];

  underCurationMessage: boolean;
  publisher: string;
  journal: Journal;

  subjects: string[];
  otherSubjects: Map<string, string[]>;
  classifiedSubjects: Map<string, string[]>;          //<class of subject, subjects>
  fos: { "id": string, "label": string }[] = [];
  sdg: string[];
  eoscSubjects: any[];

  oaRoutes: OARoutes;
  publiclyFunded: boolean;

  // // percentage is for trust
  // relatedResearchResults: RelationResult[];
  // // percentage is for similarity
  // similarResearchResults: RelationResult[];
  // //isSupplementedBy
  // supplementaryResearchResults: RelationResult[];
  // //isSupplementTo
  // supplementedByResearchResults: RelationResult[];
  //
  // // IsPartOf
  // parentResearchResults: RelationResult[];
  // // HasPart
  // childrenResearchResults: RelationResult[];
  // // Reviews
  // reviewedByResearchResults: RelationResult[];
  // // IsReviewedBy
  // reviewerResearchResults: RelationResult[];
  // // References
  // referencedByResearchResults: RelationResult[];
  // // IsReferencedBy
  // refererResearchResults: RelationResult[];
  // // IsIdenticalTo
  // identicalResearchResults: RelationResult[];
  // // IsContinuedBy
  // continuatorResearchResults: RelationResult[];
  // // Continues
  // continuedByResearchResults: RelationResult[];
  // // IsDocumentedBy
  // documentaryResearchResults: RelationResult[];
  // // Documents
  // documentedByResearchResults: RelationResult[];
  // // IsCompiledBy
  // compilerResearchResults: RelationResult[];
  // // Compiles
  // compiledByResearchResults: RelationResult[];
  // // IsPreviousVersionOf
  // newerResearchResults: RelationResult[];
  // // IsNewVersionOf
  // previousResearchResults: RelationResult[];
  // // IsVersionOf
  // versionedByResearchResults: RelationResult[];
  // // HasVersion
  // isVersionResearchResults: RelationResult[];
  // // IsSourceOf
  // derivedResearchResults: RelationResult[];
  // // IsDerivedFrom
  // sourceResearchResults: RelationResult[];
  // // IsVariantFormOf
  // originalResearchResults: RelationResult[];
  // // IsOriginalFormOf
  // variantResearchResults: RelationResult[];
  // // IsObsoletedBy
  // obsoleteResearchResults: RelationResult[];
  // // Obsolete
  // obsoletedByResearchResults: RelationResult[];

  relatedResults: RelationResult[];
  relatedClassFilters: Set<string> = new Set();

  contexts: Context[];

  deletedByInferenceIds: string[];
  children;

  // PUBLICATION, DATASET, ORP
  references: Reference[];

  // PUBLICATION
  bioentities: Map<string, Map<string, string>>;      //<site name, <>>
  organizations: Organization[];
  openCitations: { "url": string, "title": string, "year": string, "doi": string, "authors": string[] }[];

  relatedServices: RelationDatasource[];
  relatedServicesClassFilters: Set<string> = new Set();

  // DATASET
  subtitle: string;

  // SOFTWARE
  programmingLanguages: string[];

  measure: Measure;

  belongsTo: boolean = true;
  message: string;

  setBelongsTo(typeId: string, id: string) {
    this.belongsTo = !BelongsTo.result || BelongsTo.result.fields.findIndex(field => BelongsTo.check(this, field)) != -1;
    this.message = !this.belongsTo ? BelongsTo.result.message : null;
    if (this.message) {
      this.message = this.message.replace('((result))', StringUtils.capitalizeAll(StringUtils.getEntityName(this.resultType)));
      this.message = this.message.replace('((type_id))', typeId);
      this.message = this.message.replace('((id))', id);
    }
  }
}
