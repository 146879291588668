import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import { FormsModule }         from '@angular/forms';

import {AlertModal} from './alert';
import {ModalModule} from './modal.module';
import {SafeHtmlPipeModule} from "../pipes/safeHTMLPipe.module";
import {IconsModule} from "../icons/icons.module";

@NgModule({
  imports: [CommonModule, FormsModule, ModalModule, SafeHtmlPipeModule, IconsModule],
  declarations: [
    AlertModal
  ],
  exports: [
    AlertModal
  ]
})
export class AlertModalModule { }
