import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EmailService} from "../openaireLibrary/utils/email/email.service";
import {Composer} from "../openaireLibrary/utils/email/composer";
import {Meta, Title} from "@angular/platform-browser";
import {PiwikService} from "../openaireLibrary/utils/piwik/piwik.service";
import {SEOService} from "../openaireLibrary/sharedComponents/SEO/SEO.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NotificationHandler} from "../openaireLibrary/utils/notification-handler";
import {BaseComponent} from '../openaireLibrary/sharedComponents/base/base.component';
import {FullScreenModalComponent} from "../openaireLibrary/utils/modal/full-screen-modal/full-screen-modal.component";

@Component({
    selector: 'contact',
    templateUrl: './contact.component.html'
})
export class ContactComponent extends BaseComponent implements OnInit {
    @Input()
    public page: boolean = true;
    public sending = false;
    public contactForm: UntypedFormGroup;
    @ViewChild('modal') modal;
    @ViewChild('fsModal') fsModal: FullScreenModalComponent;
    
    constructor(protected _route: ActivatedRoute,
                protected _router: Router,
                protected _title: Title,
                protected _meta: Meta,
                protected _piwikService: PiwikService,
                protected seoService: SEOService,
                private emailService: EmailService,
                private fb: UntypedFormBuilder) {
      super();
    }
    
    ngOnInit() {
      if(this.page) {
        this.title = 'Contact us';
        this.description = 'Contact us';
        this.setMetadata();
      }
      this.reset();
    }

    public send(event) {
      if(event.valid === true) {
        this.sendMail(this.properties.admins);
      }
    }

    public reset(subject: string = null) {
      this.contactForm = this.fb.group( {
        name: this.fb.control('', Validators.required),
        email: this.fb.control('', [Validators.required, Validators.email]),
        subject: this.fb.control(subject, Validators.required),
        message: this.fb.control('', Validators.required),
        recaptcha: this.fb.control('', Validators.required),
      });
    }
    
    private sendMail(admins: any) {
      this.sending = true;
      this.subscriptions.push(this.emailService.contact(this.properties,
        Composer.composeEmailforIrishMonitor(this.contactForm.value, admins),
        this.contactForm.value.recaptcha).subscribe(
        res => {
          if (res) {
            this.sending = false;
            if(this.fsModal) {
              this.fsModal.cancel();
            } else {
              this.reset();
            }
            this.modalOpen();
          } else {
            this.handleError('Email <b>sent failed!</b> Please try again.');
          }
        },
        error => {
          this.handleError('Email <b>sent failed!</b> Please try again.', error);
        }
      ));
    }

    public openFsModal() {
      this.reset(this._title.getTitle());
      this.fsModal.open();
    }

    public modalOpen() {
      if(this.modal) {
        this.modal.okButton = true;
        this.modal.alertTitle = 'Your request has been successfully submitted';
        this.modal.alertMessage = false;
        this.modal.cancelButton = false;
        this.modal.okButtonLeft = false;
        this.modal.okButtonText = 'OK';
        this.modal.open();
      }
    }

    handleError(message: string, error = null) {
      if(error) {
          console.error(error);
      }
      NotificationHandler.rise(message, 'danger');
      this.sending = false;
      this.contactForm.get('recaptcha').setValue('');
    }

    public goToHome() {
      if(!this.fsModal) {
        this._router.navigate(['/']);
      }
    }
}
