import {
  ChangeDetectorRef,
  Component, ElementRef, Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, PLATFORM_ID,
  SimpleChanges, ViewChild,
  ViewChildren
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Session, User} from '../login/utils/helper.class';
import {ConfigurationService} from '../utils/configuration/configuration.service';
import {MenuAlignment, MenuItem} from './menu';
import {EnvProperties} from '../utils/properties/env-properties';
import {Subscription} from 'rxjs';
import {HelpContentService} from '../services/help-content.service';
import {properties} from "../../../environments/environment";
import {LayoutService} from "../dashboard/sharedComponents/sidebar/layout.service";
import {NotificationConfiguration} from "../notifications/notifications-sidebar/notifications-sidebar.component";
import {SearchInputComponent} from "./search-input/search-input.component";
import {Filter} from "../searchPages/searchUtils/searchHelperClasses.class";
import {RouterHelper} from "../utils/routerHelper.class";
import {isPlatformServer} from "@angular/common";

declare var UIkit;

export interface Badge {
  asset: string,
  routerLink?: string,
  external?: boolean
}

export interface Header {
  route?: string,
  url?: string,
  title: string,
  logoUrl: string,
  logoSmallUrl: string,
  logoInfo?: string,
  position: 'left' | 'center' | 'right',
  badge: boolean,
  environmentBadge?: Badge,
  darkBg?: boolean,
  menuPosition?:  'center' | 'right',
  replaceHeader?: Header;
}

@Component({
  selector: 'navbar',
  templateUrl: 'navigationBar.component.html'
})
export class NavigationBarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() portal: string = 'connect';
  @Input() onlyTop: boolean = false;
  @Input() logoPath: string = 'assets/common-assets/';
  @Input() userMenu: boolean = true;
  @Input() showHomeMenuItem: boolean = false;
  @Input() communityId;
  @Input() showCommunityName: boolean = false;
  @Input() userMenuItems: MenuItem[];
  @Input() menuItems: MenuItem[];
  @Input() header: Header;
  @Input() showMenu: boolean = true;
  @Input() homeurl: boolean = true;
  @Input() properties: EnvProperties = properties;
  @Input() user: User;
  @Input() enableSearch: boolean = false;
  @Input() searchRoute: string = '/search/find';
  @Input() searchPlaceHolder: string = 'Search for research results';
  @Input() showLogo: boolean = true;
  @Input() notificationConfiguration: NotificationConfiguration;
  replaceHeader: boolean = false;
  hasStickyHeaderOnMobile: boolean = false;
  public activeHeader: Header;
  keyword: string = '';
  public isAuthorized: boolean = false;
  subs: Subscription[] = [];
  showEntity = {};
  showPage = {};
  public searchMode: boolean = false;
  public portalName: string = "";

	public additionalMenuItems: MenuItem[] = [];
	public featuredMenuItems: MenuItem[] = [];
	public isMenuEnabled: boolean = false;
  public isFeaturedMenuEnabled: boolean = false;
  public featuredAlignment: string = MenuAlignment.CENTER.valueOf();

  public hasSearchBar: boolean = false;
  public resultsQuickFilter: { filter: Filter, selected: boolean, filterId: string, value: string } = null;//{
    // filter: null,
    // selected: true,
    // filterId: "resultbestaccessright",
    // value: "Open Access"
  // };
  @ViewChild('search_input') search_input: SearchInputComponent;
  @ViewChild('canvas') canvas: ElementRef;
  public routerHelper: RouterHelper = new RouterHelper();

  public isClient: boolean = false;
  constructor(private router: Router,
              private  route: ActivatedRoute,
              private config: ConfigurationService,
							private _helpContentService: HelpContentService,
              private layoutService: LayoutService,
              private cdr: ChangeDetectorRef,
              @Inject(PLATFORM_ID) private platform: any) {
    this.isClient = !isPlatformServer(this.platform);
  }

  ngOnInit() {
    this.subs.push(this.route.queryParams.subscribe(params => {
      this.searchMode = false;
      this.keyword = "";
    }));
    this.subs.push(this.layoutService.hasStickyHeaderOnMobile.subscribe(hasStickyHeaderOnMobile => {
      this.hasStickyHeaderOnMobile = hasStickyHeaderOnMobile;
    }))
    this.initialize();
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.header) {
      this.setHeader(this.layoutService.replaceHeaderValue);
    }
  }
  
  closeCanvas(element = this.canvas.nativeElement) {
    UIkit.offcanvas(element).hide();
  }

  initialize() {
    if ((['provide', 'develop']).indexOf(this.portal) != -1) {
      this.header = {
        route: '/',
        url: null,
        title: this.portal,
        logoUrl: this.logoPath + 'logo-large-' + this.portal + '.png',
        logoSmallUrl: this.logoPath + 'logo-small-' + this.portal + '.png',
        position: 'left',
        menuPosition: 'right',
        badge: true
      };
    }
    this.isAuthorized = Session.isClaimsCurator(this.user) || Session.isPortalAdministrator(this.user);
    if ((this.properties.adminToolsAPIURL && this.communityId) || properties.adminToolsPortalType == "community") {
      //this.config.getCommunityInformation(this.properties, this.communityId).subscribe(data => {
      this.subs.push(this.config.portalAsObservable.subscribe(data => {
          if (data) {
            if(data.name) {
              this.portalName = data.name;
            }
            this.showEntity = {};
            this.showPage = {};
            if (data['entities']) {
              for (let i = 0; i < data['entities'].length; i++) {

                this.showEntity['' + data['entities'][i]['pid'] + ''] = data['entities'][i]['isEnabled'];
              }
            }
            if (data['pages']) {
              for (let i = 0; i < data['pages'].length; i++) {
                this.showPage[data['pages'][i]['route']] = data['pages'][i]['isEnabled'];

              }
            }
          }
        },
        error => {
          this.handleError('Error getting community information (e.g. pages,entities) for community with id: ' + this.communityId, error);
        }));
    }
		if(this.portal != 'connect' && this.portal != 'connect-admin' && this.properties.adminToolsPortalType == 'community') {
			this.subs.push(
				this._helpContentService.getMenuItems(this.portal).subscribe(
					data => {
						this.isMenuEnabled = data.isMenuEnabled;
            this.isFeaturedMenuEnabled = data.isFeaturedMenuEnabled;
            this.featuredAlignment = data.featuredAlignment;
            this.featuredMenuItems = data.featuredMenuItems;
						this.additionalMenuItems = data.menuItems;
					},
					error => this.handleError("Server error fetching custom menu items", error)
				)
			);
		}
    this.subs.push(this.layoutService.replaceHeader.subscribe(replaceHeader => {
      this.setHeader(replaceHeader);
    }));
    this.subs.push(this.layoutService.hasMenuSearchBar.subscribe(hasSearchBar => {
      this.hasSearchBar = hasSearchBar;
    }));
  }
  
  setHeader(replaceHeader: boolean) {
    this.replaceHeader = this.header.replaceHeader && replaceHeader;
    this.activeHeader = this.replaceHeader?this.header.replaceHeader:this.header;
  }
  
  isEnabled(required, enabled) {
    if (!required) {
      return true;
    }


    for (let requiredEntity of required) {
      if (typeof enabled[requiredEntity] === 'undefined' || enabled[requiredEntity] == false) {
        return false;
      }
    }
    return true;
  }

  isAtleastOneEnabled(required, enabled) {
    if (!required || required.length == 0) {
      return true;
    }

    var count = required.length;
    for (let requiredEntity of required) {
      if (typeof enabled[requiredEntity] === 'undefined' || enabled[requiredEntity] == false) {
        count--;
      }
    }
    return (count > 0);
  }

  private handleError(message: string, error) {
    console.error('NavigationBar (component): ' + message, error);
  }
  
  get isHeaderLeft(): boolean {
    return this.header.position === 'left' || (this.header.position === 'center' && this.header.menuPosition === 'center' && this.menuItems.length > 0);
  }

  get currentRoute() {
    return {
      route: this.router.url.split('?')[0].split('#')[0],
      fragment: this.route.snapshot.fragment
    }
  }

  isTheActiveMenu(menu: MenuItem): boolean {
    // if(menu instanceof MenuItem) {
      return MenuItem.isTheActiveMenu(<MenuItem> menu, this.currentRoute, this.layoutService.activeMenuItem);
    // } else {
    //   let item: MenuItem = menu.rootItem;
    //   item.items = menu.items;
    //   return MenuItem.isTheActiveMenu(item, this.currentRoute, this.layoutService.activeMenuItem);
    // }
  }

  openSearch(event) {
    event.stopPropagation();
    this.searchMode = true;
    this.cdr.detectChanges();
    this.search_input.input.focus(true);
  }

  goTo(canvas = null) {
    let parameterNames = [];
    let parameterValues = [];

    if (this.resultsQuickFilter && this.resultsQuickFilter.selected) {
      parameterNames.push(this.resultsQuickFilter.filterId);
      parameterValues.push('"' + encodeURIComponent(this.resultsQuickFilter.value) + '"');
    }

    if (this.keyword.length > 0) {
      parameterNames.push("fv0");
      parameterValues.push(this.keyword);
      parameterNames.push("f0");
      parameterValues.push("q");
    }
    this.router.navigate([this.properties.searchLinkToAll ? this.properties.searchLinkToAll : this.properties.searchLinkToResults], {queryParams: this.routerHelper.createQueryParams(parameterNames, parameterValues)});
    if(canvas) {
      this.closeCanvas(canvas);
    }
  }
}
