
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Observable}       from 'rxjs';
import {properties} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteRecorder  {
  constructor(private router: Router) {
  }
  canDeactivate(component: any): Observable<boolean> | boolean {
    if (typeof localStorage !== 'undefined') {
      // console.log("In PreviousRouteRecorder : " + properties.domain +properties.baseLink + this.router.url );
      localStorage.setItem('previousRoute', properties.domain + properties.baseLink + this.router.url);
    }
    return true;
  }
}
