import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {OpenAireJsonldConverterService} from './service/open-aire-jsonld-converter.service';
import {JsonldDocumentSerializerService} from './service/jsonld-document-serializer.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'schema2jsonld',
  template: `
    <div *ngIf="html" [innerHTML]="html"></div>
  `
})
export class Schema2jsonldComponent implements OnInit, OnChanges {
  @Input() data; // for project, organization, datasource
  @Input() URL;
  @Input() logoURL; // for home, search
  @Input() otherURL; //for datasource
  @Input() name;
  @Input() searchAction = false;
  @Input() type = 'result';
  @Input() description = null;
  @Input() searchActionRoute = '/search/find/';
  public json;
  public html;
  
  constructor(private documentParser: OpenAireJsonldConverterService,
              private documentSerializer: JsonldDocumentSerializerService, private sanitizer: DomSanitizer) {
    
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.description) {
      this.createJson();
    }
  }
  
  ngOnInit() {
    this.createJson();
  }
  
  getSafeHTML(value) {
    let json = JSON.stringify(value, null, 2);
    let html = '<script type="application/ld+json">' + json + '</script>';
    return this.sanitizer.bypassSecurityTrustHtml(html);
  };
  
  createJson() {
    var docOvject;
    if (this.type == 'project') {
      docOvject = this.documentParser.convertProject(this.data, this.URL);
      this.json = this.documentSerializer.serializeOrganization(docOvject);
    } else if (this.type == 'organization') {
      docOvject = this.documentParser.convertOrganization(this.data, this.URL, this.description);
      this.json = this.documentSerializer.serializeOrganization(docOvject);
    } else if (this.type == 'datasource') {
      docOvject = this.documentParser.convertDatasource(this.data, this.URL, this.otherURL);
      this.json = this.documentSerializer.serializeOrganization(docOvject);
    } else if (this.type == 'home') {
      this.json = this.documentParser.createHome(this.name, this.URL, this.logoURL, this.description, this.searchActionRoute);
    } else if (this.type == 'search') {
      this.json = this.documentParser.createSearchPage(this.name, this.URL, this.logoURL, this.searchAction, this.description, this.searchActionRoute);
    } else if (this.type == 'result') {
      docOvject = this.documentParser.convertResult(this.data, this.URL, this.searchActionRoute);
      this.json = this.documentSerializer.serializeDataset(docOvject);
    } else {
      this.json = this.documentParser.createSimplePage(this.name, this.URL, this.description);
    }
    this.html = this.getSafeHTML(this.json);
  }
}
