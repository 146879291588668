<div *ngIf="showMenu && activeHeader">
  <div id="main-menu-small" class="uk-hidden@m" [attr.uk-sticky]="hasStickyHeaderOnMobile?'':null">
    <nav class="uk-navbar-container uk-navbar" uk-navbar="delay-hide: 400">
        <div *ngIf="!onlyTop || userMenu" class="uk-navbar-left" [class.uk-light]='activeHeader.darkBg'>
          <a class="uk-navbar-toggle" href="#tm-mobile" uk-toggle>
            <div uk-navbar-toggle-icon="" class="uk-navbar-toggle-icon uk-icon custom-navbar-toggle-icon"></div>
            <span class="visually-hidden">toggle menu</span>
          </a>
        </div>
        <div class="uk-navbar-center">
          <ng-container *ngTemplateOutlet="header_template; context: {mobile: true}"></ng-container>
        </div>
        <div *ngIf="!onlyTop || userMenu" class="uk-navbar-right" [class.uk-light]='activeHeader.darkBg'>
          <ng-container *ngIf="userMenu">
            <user-mini [user]="user" mobileView=true [userMenuItems]=userMenuItems [notificationConfiguration]="notificationConfiguration"></user-mini>
          </ng-container>
        </div>
      </nav>
    <div #canvas id="tm-mobile" [attr.uk-offcanvas]="'mode: none; overlay: true'"
         class="uk-offcanvas">
      <div class="uk-offcanvas-bar uk-padding-remove">
        <nav class="uk-navbar uk-background-default" uk-sticky>
          <div class="uk-navbar-left">
            <button class="uk-navbar-toggle uk-icon uk-close" (click)="closeCanvas(canvas)">
              <icon name="close" ratio="1.5" visuallyHidden="close menu" style="margin-left: -0.5em"></icon>
            </button>
          </div>
          <div class="uk-navbar-center">
            <ng-container *ngTemplateOutlet="header_template; context: {mobile: true}"></ng-container>
          </div>
        </nav>
        <ul class="uk-nav uk-nav-primary uk-list uk-list-large uk-margin-large-top" uk-nav>
            <ng-container *ngIf="!onlyTop">
              <li *ngIf="showHomeMenuItem && currentRoute.route !== '/'">
                <a routerLink="/" (click)="closeCanvas(canvas)">Home</a>
              </li>
              <ng-container *ngFor="let menu of menuItems">
                <li [class.uk-active]="isTheActiveMenu(menu)" [class.uk-parent]="menu.items.length > 0" [ngClass]="menu.customClass"
                    *ngIf="isAtleastOneEnabled(menu.entitiesRequired,showEntity) && isAtleastOneEnabled(menu.routeRequired, showPage)">
                  <!--a routerLink="{{menu.rootItem.route}}"  [queryParams]=menu.rootItem.params class="uk-offcanvas-close custom-offcanvas-close">{{menu.rootItem.title}}</a-->
                  <a *ngIf="!menu.url" [routerLink]="menu.route && (isEnabled([menu.route], showPage) || !menu.routeRequired) && menu.items.length === 0?menu.route:null"
                     (click)="menu.items.length === 0?closeCanvas(canvas):null"
                     [queryParams]="menu.params"
                     [fragment]="menu.fragment">
                    <span *ngIf="menu.badge" style="position: relative"><span class="badge-mobile">{{menu.badge}}</span></span>
                    {{menu.title}}<span *ngIf="menu.items.length > 0" class="uk-nav-parent-icon"></span></a>
                  <a *ngIf="menu.url"
                     (click)="menu.items.length === 0?closeCanvas(canvas):null"
                     [href]="menu.items.length === 0?menu.url:null"
                     [class.custom-external]="menu.url && menu.target != '_self'" [target]="menu.url?menu.target:null">
                    {{menu.title}}<span *ngIf="menu.items.length > 0" class="uk-nav-parent-icon"></span></a>
                  <ul *ngIf="menu.items.length > 0" class="uk-nav-sub">
                    <ng-container *ngFor="let submenu of menu.items">
                      <li [class.uk-active]="isTheActiveMenu(submenu)" [ngClass]="submenu.customClass"
                          *ngIf="isEnabled(submenu.entitiesRequired,showEntity) && isEnabled(submenu.routeRequired, showPage)  && (submenu.route.length > 0 || submenu.url.length > 0)"
                          [class.uk-parent]="submenu.items && submenu.items.length > 0">
                        <a *ngIf="submenu.route.length > 0" (click)="closeCanvas(canvas)"
                           routerLink="{{submenu.route}}" [queryParams]=submenu.params
                           [fragment]="submenu.fragment">{{submenu.title}}</a>
                        <a *ngIf="submenu.route.length == 0 && submenu.url.length > 0"
                           href="{{submenu.url}}" (click)="closeCanvas(canvas)" [class.custom-external]="submenu.target != '_self'"
                           target="{{submenu.target}}">{{submenu.title}}</a>
                        <ul *ngIf="submenu.items && submenu.items.length > 0" class="uk-nav-sub">
                          <ng-container *ngFor="let subsubmenu of submenu.items">
                            <li [class.uk-active]="isTheActiveMenu(subsubmenu)" [ngClass]="subsubmenu.customClass">
                              <a *ngIf="subsubmenu.route.length > 0"
                                 routerLink="{{subsubmenu.route}}" [queryParams]="subsubmenu.params"
                                 [fragment]="subsubmenu.fragment" (click)="closeCanvas(canvas)">{{subsubmenu.title}}</a>
                              <a *ngIf="subsubmenu.route.length == 0 && subsubmenu.url.length > 0"
                                 href="{{subsubmenu.url}}" (click)="closeCanvas(canvas)" [class.custom-external]="subsubmenu.target != '_self'"
                                 target="{{subsubmenu.target}}">{{subsubmenu.title}}</a>
                            </li>
                          </ng-container>
                        </ul>
                      </li>
                      <li *ngIf="submenu.route.length == 0 && submenu.url.length == 0 && isEnabled(submenu.entitiesRequired,showEntity) && isEnabled(submenu.routeRequired, showPage)"
                          class="uk-nav-header">{{submenu.title}}</li>
                    </ng-container>
                  </ul>
                  <ul *ngIf="menu.route === '/' && isFeaturedMenuEnabled && featuredMenuItems?.length > 0" class="uk-nav-sub">
                    <li [class.uk-active]="isTheActiveMenu(item)" *ngFor="let item of featuredMenuItems" [ngClass]="item.customClass">
                      <a *ngIf="item.type == 'internal' && item.route && isEnabled([item.route], showPage)"
                         routerLink="{{item.route}}"
                         [queryParams]="item.params"
                         [fragment]="item.fragment"
                         (click)="closeCanvas(canvas)">
                        {{item.title}}
                      </a>
                      <a *ngIf="item.type == 'external' && item.url"
                         href="{{item.url}}" class="custom-external"
                         target="_blank"
                         (click)="closeCanvas(canvas)">
                        {{item.title}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ng-container>
              <ng-container *ngIf="isMenuEnabled && additionalMenuItems?.length > 0">
                <ng-container *ngFor="let menu of additionalMenuItems">
                  <li [class.uk-active]="isTheActiveMenu(menu)" [class.uk-parent]="menu.items.length > 0" [ngClass]="menu.customClass">
                    <a *ngIf="menu.type == 'internal' && menu.route && isEnabled([menu.route], showPage)"
                       routerLink="{{menu.route}}"
                       [queryParams]="menu.params"
                       [fragment]="menu.fragment"
                       (click)="menu.items.length === 0?closeCanvas(canvas):null">
                      {{menu.title}}
                    </a>
                    <a *ngIf="menu.type == 'external' && menu.url"
                       href="{{menu.url}}"
                       target="_blank" class="custom-external"
                       (click)="menu.items.length === 0?closeCanvas(canvas):null">
                      {{menu.title}}
                    </a>
                    <a *ngIf="menu.type == 'noAction'">
                      {{menu.title}}
                    </a>
                    <ul class="uk-nav-sub">
                      <ng-container *ngIf="menu.items?.length">
                        <ng-container *ngFor="let submenu of menu.items">
                          <li [class.uk-active]="isTheActiveMenu(submenu)" [ngClass]="submenu.customClass">
                            <a *ngIf="submenu.type == 'internal' && submenu.route && isEnabled([submenu.route], showPage)"
                               routerLink="{{submenu.route}}"
                               [queryParams]="submenu.params"
                               [fragment]="submenu.fragment"
                               (click)="menu.items.length === 0?closeCanvas(canvas):null">
                              {{submenu.title}}
                            </a>
                            <a *ngIf="submenu.type == 'external' && submenu.url"
                               href="{{submenu.url}}"
                               target="_blank" class="custom-external"
                               (click)="menu.items.length === 0?closeCanvas(canvas):null">
                              {{submenu.title}}
                            </a>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ul>
                  </li>
                </ng-container>
              </ng-container>
              <ng-content select="[extra-s]"></ng-content>
            </ng-container>
          </ul>
          <div *ngIf="hasSearchBar" search-input [(value)]="keyword" [placeholder]="'Search'+(portalName ? (' in '+portalName) : '')"
               [searchInputClass]="'flat background small-vertical'" (searchEmitter)="goTo(canvas)"
               class="uk-width-1-1 uk-padding-small uk-margin-medium-top"></div>
      </div>
    </div>
  </div>
  <div id="main-menu" class="uk-visible@m">
    <div *ngIf="activeHeader" [class.uk-light]='activeHeader.darkBg'>
      <div class="uk-navbar-container" uk-sticky>
        <a *ngIf="(properties.environment =='beta' || properties.environment =='development' || activeHeader.environmentBadge) && showLogo  && activeHeader.badge"
           [routerLink]="activeHeader.environmentBadge?.routerLink?activeHeader.environmentBadge.routerLink:null" target="_blank">
          <img *ngIf="!activeHeader.environmentBadge" class="uk-position-top-left"
               [src]="'assets/common-assets/'+(properties.environment =='beta'?'beta_flag.svg':'prototype_flag.svg')"
               alt="BETA" style="height: 65px; width: 65px; z-index: 1000">
          <img *ngIf="activeHeader.environmentBadge" class="uk-position-top-left"
               [src]="activeHeader.environmentBadge.asset" [alt]="properties.environment"
               style="height: 75px; width: 75px; z-index: 1000">
        </a>
        <div class="uk-container uk-container-expand">
          <nav class="uk-navbar" uk-navbar="delay-hide: 400">
            <ng-container *ngIf="!onlyTop">
              <div class="uk-navbar-left">
                <ng-container *ngIf="showLogo && isHeaderLeft">
                  <ng-container *ngTemplateOutlet="header_template; context: {mobile: false}"></ng-container>
                </ng-container>
              </div>
              <div class="uk-navbar-center">
                <ng-container *ngIf="showLogo && !isHeaderLeft">
                  <ng-container *ngTemplateOutlet="header_template; context: {mobile: false}"></ng-container>
                </ng-container>
                <ng-container *ngIf="!activeHeader.menuPosition || activeHeader.menuPosition === 'center'">
                  <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
                </ng-container>
                <div *ngIf="searchMode" #search_input search-input [(value)]="keyword" [placeholder]="'Search'+(portalName ? (' in '+portalName) : '')"
                     [searchInputClass]="'flat background small-vertical'" (searchEmitter)="goTo()"
                     class="uk-width-large@l uk-width-medium uk-width-xlarge@xl"></div>
              </div>
              <div class="uk-navbar-right">
                <ng-container *ngIf="activeHeader.menuPosition === 'right'">
                  <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
                </ng-container>

                <div *ngIf="searchMode" class="uk-flex uk-flex-middle uk-margin-medium-right" (click)="searchMode = false">
                  <div class="search-icon clickable">
                    <icon name="close" [flex]="true" ratio="1.5" visuallyHidden="close search mode"></icon>
                  </div>
                </div>

                <div *ngIf="!searchMode && hasSearchBar" class="uk-flex uk-flex-middle uk-margin-right" (click)="openSearch($event)">
                  <div class="search-icon clickable">
                    <icon name="search" [flex]="true" ratio="1" visuallyHidden="search"></icon>
                  </div>
                </div>

                <user-mini *ngIf="!searchMode && userMenu" [user]="user" [userMenuItems]=userMenuItems></user-mini>
                <div class="uk-visible@m">
                  <ng-content select="[extra-m]"></ng-content>
                </div>
              </div>
              <ng-template #mainMenu>
                <ul *ngIf="!searchMode" class="uk-navbar-nav" [class.uk-margin-right]="!userMenu">
                  <li class="uk-parent" *ngIf="showHomeMenuItem && currentRoute.route !== '/'">
                    <a routerLink="/">Home</a>
                  </li>
                  <ng-container *ngFor="let menu of menuItems">
                    <li class="uk-parent" [class.uk-active]="isTheActiveMenu(menu)" [ngClass]="menu.customClass"
                        *ngIf="isAtleastOneEnabled(menu.entitiesRequired,showEntity) && isAtleastOneEnabled(menu.routeRequired, showPage)">
                      <!--a routerLink="{{menu.rootItem.route}}"   [queryParams]=menu.rootItem.params class="" aria-expanded="false">{{menu.rootItem.title}}</a-->
                      <a *ngIf="menu.route.length > 0 && (isEnabled([menu.route], showPage) || !menu.routeRequired )"
                         routerLink="{{menu.route}}"
                         [queryParams]="menu.params"
                         [fragment]="menu.fragment"> {{menu.title}}</a>
                      <a *ngIf="menu.route.length == 0 && menu.url.length > 0"
                         href="{{menu.url}}" target="{{menu.target}}"
                         [class.custom-external]="menu.target != '_self'">{{menu.title}}</a>
                      <a *ngIf="(menu.route.length == 0 && menu.url.length == 0) || ( menu.route.length >0 && menu.routeRequired && !isEnabled([menu.route], showPage) && isAtleastOneEnabled(menu.routeRequired, showPage))">
                        <span *ngIf="menu.badge" class="badge">{{menu.badge}}</span>
                        {{menu.title}}</a>
                      <div *ngIf="menu.items.length > 0 && isClient" class="uk-navbar-dropdown uk-navbar-dropdown-bottom-left uk-height-max-medium uk-overflow-auto">
                        <!-- Do not delete this div, because it will remove the margin bottom of navbar -->
                        <div>
                          <ul class="uk-nav uk-navbar-dropdown-nav">
                            <ng-container *ngFor="let submenu of menu.items">
                              <li *ngIf="isEnabled(submenu.entitiesRequired,showEntity) &&
                                isEnabled(submenu.routeRequired, showPage) && (submenu.route.length > 0 ||
                                submenu.url.length > 0)" [class.uk-active]="isTheActiveMenu(submenu)"
                                  [class.uk-parent]="submenu.items && submenu.items.length > 0" [ngClass]="submenu.customClass">
                                <a *ngIf="submenu.route.length > 0"
                                   routerLink="{{submenu.route}}" [queryParams]="submenu.params"
                                   [fragment]="submenu.fragment">{{submenu.title}}</a>
                                <a *ngIf="submenu.route.length == 0 && submenu.url.length > 0"
                                   href="{{submenu.url}}" [class.custom-external]="submenu.target != '_self'"
                                   target="{{submenu.target}}">{{submenu.title}}</a>
                                <ul *ngIf="submenu.items && submenu.items.length > 0" class="uk-nav-sub">
                                  <ng-container *ngFor="let subsubmenu of submenu.items">
                                    <li [class.uk-active]="isTheActiveMenu(subsubmenu)" [ngClass]="subsubmenu.customClass">
                                      <a *ngIf="subsubmenu.route.length > 0"
                                         routerLink="{{subsubmenu.route}}" [queryParams]="subsubmenu.params"
                                         [fragment]="subsubmenu.fragment">{{subsubmenu.title}}</a>
                                      <a *ngIf="subsubmenu.route.length == 0 && subsubmenu.url.length > 0"
                                         href="{{subsubmenu.url}}" [class.custom-external]="subsubmenu.target != '_self'" target="{{subsubmenu.target}}">{{subsubmenu.title}}</a>
                                    </li>
                                  </ng-container>
                                </ul>

                              <li *ngIf="submenu.route.length == 0 && submenu.url.length == 0 && isEnabled(submenu.entitiesRequired,showEntity) && isEnabled(submenu.routeRequired, showPage)"
                                  class="uk-nav-header">{{submenu.title}}</li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                  <ng-container *ngIf="isMenuEnabled && additionalMenuItems?.length > 0">
                    <ng-container *ngFor="let menu of additionalMenuItems">
                      <li class="uk-parent" [class.uk-active]="isTheActiveMenu(menu)" [ngClass]="menu.customClass">
                        <a *ngIf="menu.type == 'internal' && menu.route && isEnabled([menu.route], showPage)"
                           routerLink="{{menu.route}}"
                           [queryParams]="menu.params"
                           [fragment]="menu.fragment">
                          {{menu.title}}
                        </a>
                        <a *ngIf="menu.type == 'external' && menu.url"
                           href="{{menu.url}}"
                           target="_blank" class="custom-external">
                          {{menu.title}}
                        </a>
                        <a *ngIf="menu.type == 'noAction'">
                          {{menu.title}}
                        </a>
                        <div *ngIf="menu.items.length > 0 && isClient" class="uk-navbar-dropdown uk-navbar-dropdown-bottom-left uk-height-max-medium uk-overflow-auto"
                             style="top: 80px; left: 0px;" id="{{menu._id}}" uk-toggle>
                          <div>
                            <ul class="uk-nav uk-navbar-dropdown-nav">
                                <ng-container *ngFor="let submenu of menu.items">
                                  <li [class.uk-active]="isTheActiveMenu(submenu)" [ngClass]="submenu.customClass">
                                    <a *ngIf="submenu.type == 'internal' && submenu.route && isEnabled([submenu.route], showPage)"
                                       routerLink="{{submenu.route}}"
                                       [queryParams]="submenu.params"
                                       [fragment]="submenu.fragment">
                                      {{submenu.title}}
                                    </a>
                                    <a *ngIf="submenu.type == 'external' && submenu.url"
                                       href="{{submenu.url}}"
                                       target="_blank" class="custom-external">
                                      {{submenu.title}}
                                    </a>
                                  </li>
                                </ng-container>
                              </ul>
                          </div>
                        </div>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </ng-template>
            </ng-container>
          </nav>
        </div>
      </div>
    </div>
    <!-- New navbar for featured menu items only -->
    <ng-container *ngIf="isFeaturedMenuEnabled && featuredMenuItems?.length > 0">
			<div class="uk-background-primary uk-light">
				<div class="uk-container uk-container-expand">
					<div class="uk-navbar" uk-navbar="delay-hide: 400">
            <div [ngClass]="'uk-navbar-'+featuredAlignment.toLowerCase()">
              <ul class="uk-navbar-nav">
                <ng-container *ngFor="let item of featuredMenuItems">
                  <li [class.uk-active]="isTheActiveMenu(item)" [ngClass]="item.customClass">
                    <a *ngIf="item.type == 'internal' && item.route && isEnabled([item.route], showPage)"
                       routerLink="{{item.route}}"
                       [queryParams]="item.params"
                       [fragment]="item.fragment">
                      {{item.title}}
                    </a>
                    <a *ngIf="item.type == 'external' && item.url"
											href="{{item.url}}" class="custom-external"
											target="_blank">
											{{item.title}}
										</a>
									</li>
								</ng-container>
							</ul>
						</div>
					</div>
				</div>
			</div>
    </ng-container>
  </div>
</div>
<ng-template #header_template let-mobile="mobile">
  <a *ngIf="!activeHeader.url" [routerLink]="activeHeader.route" [class.uk-padding-remove]="!isHeaderLeft"
     class="uk-logo uk-navbar-item uk-flex uk-flex-middle" [class.small]="activeHeader.logoInfo">
    <img *ngIf="(mobile && activeHeader.logoSmallUrl) || (!mobile && activeHeader.logoUrl)"
           [src]="!mobile?activeHeader.logoUrl:activeHeader.logoSmallUrl"
           [alt]="activeHeader.title">
    <div *ngIf="activeHeader.logoInfo" class="uk-visible@l" [innerHTML]="activeHeader.logoInfo"></div>
    <ng-container *ngIf="(mobile && !activeHeader.logoSmallUrl) || (!mobile && !activeHeader.logoUrl)">
      <div class="multi-line-ellipsis lines-2" [style.max-width]="(!mobile)?'25vw':null" [title]="activeHeader.title">
        <p class="uk-margin-remove">{{activeHeader.title}}</p>
      </div>
    </ng-container>
  </a>
  <a *ngIf="activeHeader.url" [href]="activeHeader.url" [class.uk-padding-remove]="!isHeaderLeft"
     class="uk-logo uk-navbar-item uk-flex uk-flex-middle" [class.small]="activeHeader.logoInfo">
    <img *ngIf="(mobile && activeHeader.logoSmallUrl) || (!mobile && activeHeader.logoUrl)"
         [src]="!mobile?activeHeader.logoUrl:activeHeader.logoSmallUrl"
         [alt]="activeHeader.title">
    <div *ngIf="activeHeader.logoInfo" class="uk-visible@l" [innerHTML]="activeHeader.logoInfo"></div>
    <ng-container *ngIf="(mobile && !activeHeader.logoSmallUrl) || (!mobile && !activeHeader.logoUrl)">
      <div class="multi-line-ellipsis lines-2" [style.max-width]="(!mobile)?'25vw':null" [title]="activeHeader.title">
        <p class="uk-margin-remove">{{activeHeader.title}}</p>
      </div>
    </ng-container>
  </a>
</ng-template>
