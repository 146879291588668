import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Role, User} from "../../login/utils/helper.class";
import {UserManagementService} from "../../services/user-management.service";
import {Subscription} from "rxjs";
import {NotificationService} from "../notification.service";
import {Notification} from "../notifications";
import {InputComponent, Option} from "../../sharedComponents/input/input.component";
import {NotificationHandler} from "../../utils/notification-handler";
import {BaseComponent} from "../../sharedComponents/base/base.component";

@Component({
  selector: '[notify-form]',
  template: `
    <form *ngIf="user && form && properties.notificationsAPIURL" [formGroup]="form">
      <ng-template [ngIf]="form.get('notify')">
        <label><input name="notify" type="checkbox" class="uk-checkbox" formControlName="notify"><span class="uk-margin-small-left">{{label}}</span></label>
        <div [class.uk-hidden]="!form.get('notify').value" class="uk-position-relative uk-margin-medium-top">
          <div class="notification-user">
            <notification-user [name]="user.firstname" [surname]="user.lastname"></notification-user>
          </div>
          <div input [rows]="4" [formInput]="form.get('message')" placeholder="Send a notification" type="textarea"></div>
        </div>
      </ng-template>
      <ng-template [ngIf]="form.get('groups') && availableGroups">
        <div #recipients input type="chips" [options]="groups"
             placeholder="Sent to:" hint="Add a recipient" inputClass="recipients" [formInput]="form.get('groups')">
        </div>
        <div class="uk-position-relative uk-margin-medium-top">
          <div class="notification-user">
            <notification-user [name]="user.firstname" [surname]="user.lastname"></notification-user>
          </div>
          <div input [formInput]="form.get('message')"
               [rows]="4" placeholder="Send a notification"
               type="textarea" inputClass="flat">
            <div tools class="uk-margin-top uk-width-1-1 uk-flex uk-flex-right">
              <button (click)="sendNotification()" class="uk-button uk-button-small uk-button-primary"
                      [class.uk-disabled]="sending || !message" [disabled]="sending || !message">Send</button>
            </div>
          </div>
        </div>
      </ng-template>
    </form>
  `,
  styleUrls: ['notify-form.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class NotifyFormComponent extends BaseComponent implements OnInit {
  @Input()
  public label: string = 'Notify Managers';
  public form: UntypedFormGroup;
  @Input()
  public availableGroups: Option[] = null;
  public groups: Option[] = [];
  @Input() service: string;
  public types: string[] = [];
  public user: User;
  public focused: boolean = false;
  @ViewChild('recipients', { static: false }) recipients: InputComponent;
  private notification: Notification;
  public sending: boolean = false;
  
  constructor(private fb: UntypedFormBuilder,
              private cdr: ChangeDetectorRef,
              private userManagementService: UserManagementService,
              private notificationService: NotificationService) {
    super();
  }
  
  @Input()
  set entities(entities: string[]) {
    this.types = entities.map(entity => Role.typeMember(entity));
    this.types = this.types.concat(entities.map(entity => Role.typeManager(entity)));
  }
  
  ngOnInit() {
    this.reset();
    this.subscriptions.push(this.userManagementService.getUserInfo().subscribe(user => {
      this.user = user;
    }));
  }
  
  reset(message: string = null) {
    if (!this.availableGroups) {
      this.form = this.fb.group({
        notify: this.fb.control(false),
        message: this.fb.control(message)
      });
      this.subscriptions.push(this.form.get('notify').valueChanges.subscribe(value => {
        if (value) {
          this.form.get('message').markAsUntouched();
        }
      }));
    } else {
      this.form = this.fb.group({
        groups: this.fb.array([]),
        message: this.fb.control(message)
      });
      this.groups = this.availableGroups;
      this.subscriptions.push(this.form.get('groups').valueChanges.subscribe(value => {
        let typeGroups = this.typeGroups;
        this.groups = this.availableGroups.map(group => {
          group.hidden = typeGroups.includes(group.value)
          return group;
        });
        this.cdr.detectChanges();
      }));
    }
  }
  
  sendNotification(notification: Notification = null) {
    if (this.message && this.properties.notificationsAPIURL) {
      if(notification === null) {
        notification = new Notification('CUSTOM', [this.service], null, null);
        notification.groups = this.parseGroups();
        this.sending = true;
      }
      this.notification = notification;
      this.notification.message = this.form.value.message;
      // TODO remove
      this.notification.name = this.user.firstname;
      this.notification.surname = this.user.lastname;
      this.subscriptions.push(this.notificationService.sendNotification(this.notification).subscribe(() => {
        this.sending = false;
        NotificationHandler.rise('A notification has been <b>sent</b> successfully');
        this.reset();
      }, error => {
        this.sending = false;
        NotificationHandler.rise('An error has occurred. Please try again later', 'danger');
        this.reset();
      }));
    }
  }
  
  parseGroups(): string[] {
    let typeGroups = this.typeGroups;
    return this.groupsAsFromArray.getRawValue().filter(group => !this.types.includes(group) && !typeGroups.includes(group)).concat(typeGroups);
  }
  
  get typeGroups(): string[] {
    let groups: string[] = [];
    this.groupsAsFromArray.getRawValue().filter(group => this.types.includes(group)).forEach(roleType => {
      if(roleType.includes("MANAGER_")) {
        let type = roleType.replace("MANAGER_", "");
        groups = groups.concat(this.availableGroups.map(option => option.value).filter(group => group.includes(type) && group.includes("_MANAGER")));
      } else {
        groups = groups.concat(this.availableGroups.map(option => option.value).filter(group => group.includes(roleType + "_") && !group.includes("_MANAGER") && !group.includes("MANAGER_")));
      }
    });
    return groups;
  }
  
  get groupsAsFromArray(): UntypedFormArray {
    return this.form.get('groups')?(<UntypedFormArray>this.form.get('groups')):null;
  }
  
  get message(): string {
    if (!this.properties.notificationsAPIURL || (this.form.get('notify') && !this.form.get('notify').value) || (this.groupsAsFromArray && this.groupsAsFromArray.length === 0)) {
      return null;
    }
    return this.form.get('message').value;
  }
  
  focus(event) {
    this.focused = true;
    event.stopPropagation();
    this.cdr.detectChanges();
    setTimeout(() => {
      this.recipients.searchInput.nativeElement.focus();
    }, 0);
  }
}
