//Classes used in linking / inlinelinking when selecting an entity
import {HelperFunctions} from "../../utils/HelperFunctions.class";
import {OpenaireEntities} from "../../utils/properties/searchFields";
import {ClaimsProperties} from "./claims.properties";

export class ClaimResult {
  public source: string;
  public url: string;
  public record: any;
  public accessRights: string = "OPEN";
  public embargoEndDate: string = "";
  public date: string;
  public authors: string[] = [];
  public publisher: string;
  public description: string;
  DOI: string;
  editors = [];
  journal: string;

  constructor() {
    let today = new Date();
    this.embargoEndDate = "" + (today.getFullYear()) + "-" + (today.getMonth() + 1) + "-" + (today.getDate());
    this.authors = [];
    this.accessRights = "OPEN";
    this.editors = [];
  }

}

export class ClaimProject {
  public funderId: string;
  public funderShortname: string;
  public funderName: string;
  public acronym: string;
  public startDate: string;
  public endDate: string;
  public code: string;
  public jurisdiction: string;
  public fundingLevel0: string;
  public url: string;
}
export class ClaimOrganization {
  public name: string;
  // public shortName: string;
  public url: string;
  // public country: string;
}

export class ClaimContext {
  public community: string;
  public category: string;
  public concept: any;
}

export class Message {
  public type: string;
  public resultId: string;
  public resultTitle: string;
  projectId: string;
  projectInfo: { title: string, startDate: string, endDate: string };
}
export class ClaimsErrorMessage {
  public type: string;
  inserted:number;
  failed:number;
  constructor(){
    this.inserted = 0;
    this.failed = 0;
  }

}

export class ClaimEntity {
  public id: string;
  public type: string;
  public title: string;
  warningMessages: Message[];
  errorMessages: Message[];
  result: ClaimResult;
  project: ClaimProject;
  context: ClaimContext;
  organization: ClaimOrganization;

  constructor() {
    this.warningMessages = [];
    this.errorMessages = [];
  }

}

export class ClaimRecord2Insert {
  claimedBy: string;
  sourceId: string;
  sourceType: string;
  sourceCollectedFrom: string;
  sourceAccessRights: string;
  sourceEmbargoEndDate: string;
  targetId: string;
  targetType: string;
  targetCollectedFrom: string;
  targetAccessRights: string;
  targetEmbargoEndDate: string;
  claimedInDashboard: string;
  idSuffix:string;

  constructor() {

  }
}

export class ClaimDBRecord {
  id: string;
  userMail: string;
  date: string;
  sourceType: string;
  targetType: string;
  semantics: string;
  approved: string;
  source: ClaimDBResult | ClaimDBProject | ClaimDBContext;
  target: ClaimDBResult;
  indexed:boolean;
}

export class ClaimDBContext {
  title: string;
  openaireId: string;
}
export class ClaimDBOrganization {
  openaireId: string;
  name: string;
  shortName: string;
  country: string;
}
export class ClaimDBProject {
  openaireId: string;
  name: string;
  funderName: string;
  funderId: string;
}

export class ClaimDBResult {
  title: string;
  authors: string[];
  externalUrl: string;
  openaireId: string;
  doi: string;
  accessRights: string;
  collectedFrom: string;
  resultType: string;
}

export class DirectIndexRecord {
  public id: string;
  record: any;
}

export class ShowOptions {
  show: string;   //show values: source, result, project, context, claim
  linkTo: string;   // linkTo /values: result, project, context
  linkToEntities: string[];   // show linkToEntities /values: result, project, context
  basketShowSources: boolean = true;
  basketShowLinksTo: boolean = false;
  selectOptions= [];
  constructor() {
    this.show = "source";
    this.linkTo = "project";
    this.linkToEntities = ["project", "context", "result"];
    this.basketShowSources = true;
    this.basketShowLinksTo = false;
  }

  showSource() {
    this.show = "source";
    this.basketswitchToSources();
    HelperFunctions.scroll();
  }
  basketswitchToSources() {
    this.basketShowLinksTo = false;
    this.basketShowSources = true;  
  }
  basketswitchToLinkTo() {
    this.basketShowLinksTo = true;
    this.basketShowSources = false;  
  }
  showLinkTo() {
    // console.log(this.linkTo, "showLinkTo")
    this.show = this.linkTo;
    this.basketswitchToLinkTo();
    HelperFunctions.scroll();

  }

  initSelectOptions(claimProperties:ClaimsProperties){
    let options =[];
    if(this.linkToEntities.indexOf('result')!=-1){
      options.push({value: 'result',label: OpenaireEntities.RESULTS})
    }
    if(this.linkToEntities.indexOf('project')!=-1){
      options.push({value: 'project',label: claimProperties.SELECT_ENTITIES.projects})
    }
    if(this.linkToEntities.indexOf('context')!=-1){
      options.push({value: 'context',label: OpenaireEntities.COMMUNITIES})
    }
    this.selectOptions = options;
    // console.log(options, claimProperties.SELECT_ENTITIES.projects)
  }
}
