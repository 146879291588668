import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import { FormsModule }         from '@angular/forms';

import { RouterModule } from "@angular/router";

import { NavigationBarComponent} from './navigationBar.component';

import { UserMiniModule} from '../login/userMiniModule.module';
import {SearchBarModule} from "./searchBar/searchBar.module";
import {HelpContentService} from '../services/help-content.service';
import {IconsModule} from "../utils/icons/icons.module";
import {SearchInputModule} from "./search-input/search-input.module";

@NgModule({
  imports: [
    CommonModule, FormsModule,
    RouterModule,
    UserMiniModule, IconsModule, SearchInputModule,
    // , SearchBarModule
  ],
  declarations: [
    NavigationBarComponent
  ],
  providers:[HelpContentService],
  exports: [
    NavigationBarComponent
    ]
})
export class NavigationBarModule{ }
