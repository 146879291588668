import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'loading',
  template: `
    <ng-template [ngIf]="full" [ngIfElse]="loading">
      <div class="uk-position-relative" style="height: 100vh; width: 99vw;">
        <div class="uk-position-center">
          <ng-container [ngTemplateOutlet]="loading"></ng-container>
        </div>
      </div>
    </ng-template>
    <ng-template #loading>
      <div class="uk-flex uk-flex-center" [class.uk-margin-small-top]="top_margin">
        <div [ngStyle]="style">
          <span class="uk-icon uk-spinner" [ngClass]="color">
          <svg width="60" height="60" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" data-svg="spinner"><circle
              fill="none" stroke="#000" cx="15" cy="15" r="14" style="stroke-width: 2px;"></circle></svg>
          </span>
        </div>
      </div>
    </ng-template>`
})
export class LoadingComponent implements OnInit {
  @Input() color: string = 'uk-text-primary';
  @Input() full: boolean = false;
  @Input() top_margin: boolean = true;
  @Input() size: "small" | "medium" | "large" = "large";
  public style;
  
  constructor() {
  }
  
  ngOnInit() {
    let size = 1;
    if (this.size === 'medium') {
      size = 2;
    } else if (this.size === "large") {
      size = 3;
    }
    this.style = {
      width: size * 20 + 'px',
      height: size * 20 + 'px'
    };
  }
}
