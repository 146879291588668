import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'notification-user',
  template: `
    <svg *ngIf="firstLetters" height="44" width="44" [ngClass]="colorClass" [class.outlined]="outline">
      <circle cx="22" cy="22" r="20" stroke-width="2"></circle>
      <text  x="50%" y="50%" text-anchor="middle" dy=".4em" font-size="16">
        {{firstLetters}}
      </text>
    </svg>
  `,
  styleUrls: ['notification-user.component.less']
})
export class NotificationUserComponent implements OnInit{
  @Input()
  public name: string;
  @Input()
  public surname: string;
  @Input()
  public colorClass = 'uk-text-primary';
  @Input()
  public outline: boolean = false;
  public firstLetters: string;
  
  ngOnInit() {
    if(this.name && this.surname) {
      this.firstLetters = this.name.charAt(0) + this.surname.charAt(0);
    }
  }
}
