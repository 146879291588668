import { NgModule}            from '@angular/core';
import { CommonModule }        from '@angular/common';
import { FormsModule }         from '@angular/forms';
import { OpenAireJsonldConverterService } from './service/open-aire-jsonld-converter.service';
import { JsonldDocumentSerializerService } from './service/jsonld-document-serializer.service';
import {Schema2jsonldComponent} from './schema2jsonld.component';
@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [Schema2jsonldComponent],
  providers:[OpenAireJsonldConverterService, JsonldDocumentSerializerService],
  exports: [Schema2jsonldComponent]
})
export class Schema2jsonldModule { }
