import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

export interface ClickEvent {
  event: any,
  clicked: boolean;
}

@Directive({
  selector: '[click-outside-or-esc]'
})
export class ClickOutsideOrEsc {
  @Output('clickOutside') clickOutside: EventEmitter<ClickEvent> = new EventEmitter<ClickEvent>();

  constructor(private elementRef: ElementRef) {}
  
  @HostListener('document:click', ['$event'])
  click(event) {
    if(event.isTrusted) {
      this.clickOutside.emit({
        event: event,
        clicked: !(this.elementRef && this.elementRef.nativeElement.contains(event.target))
      });
    }
  }
  
  @HostListener('window:keydown.escape', ['$event'])
  esc(event: KeyboardEvent) {
    this.clickOutside.emit({
      event: event,
      clicked: true
    });
  }
}
