import { NgModule}            from '@angular/core';
import { CommonModule }        from '@angular/common';
import { FormsModule }         from '@angular/forms';
import {SearchResearchResultsService} from "./searchResearchResults.service";

@NgModule({
  imports: [
    CommonModule, FormsModule
  ],
  declarations: [
  ],
  providers: [
    SearchResearchResultsService
  ],
  exports: [
  ]
})

export class SearchResearchResultsServiceModule { }
