import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NotificationsSidebarComponent} from "./notifications-sidebar.component";
import {IconsModule} from "../../utils/icons/icons.module";
import {NotificationUserModule} from "../notification-user/notification-user.module";
import {NotifyFormModule} from "../notify-form/notify-form.module";
import {SafeHtmlPipeModule} from "../../utils/pipes/safeHTMLPipe.module";

@NgModule({
  imports: [CommonModule, IconsModule, NotificationUserModule, NotifyFormModule, SafeHtmlPipeModule],
  declarations: [NotificationsSidebarComponent],
  exports: [NotificationsSidebarComponent]
})
export class NotificationsSidebarModule {}
