import { NgModule}            from '@angular/core';
import { CommonModule }        from '@angular/common';
import { FormsModule }         from '@angular/forms';
import {SearchOrcidService} from "./searchOrcid.service";


@NgModule({
  imports: [
    CommonModule, FormsModule
  ],
  declarations: [
  ],
  providers:[
    SearchOrcidService
],
  exports: [
    ]
})
export class SearchOrcidServiceModule { }
