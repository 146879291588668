import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from "@angular/router";

import {BottomComponent} from './bottom.component';
import {HelperModule} from "../utils/helper/helper.module";
import {IconsModule} from "../utils/icons/icons.module";
import {IconsService} from "../utils/icons/icons.service";
import {by, cc, facebook, linkedin, newsletter, slideshare, twitter, youtube} from "../utils/icons/icons";

@NgModule({
  imports: [
    CommonModule, FormsModule, RouterModule, HelperModule, IconsModule
  ],
  declarations: [
    BottomComponent
  ],
  exports: [BottomComponent]
})
export class BottomModule{
  constructor(private iconsService: IconsService) {
    this.iconsService.registerIcons([by, cc, twitter, facebook, linkedin, slideshare, youtube, newsletter]);
  }
}
