import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Irish} from "./shared/irish";
import {ErrorPageComponent} from "./openaireLibrary/error/errorPage.component";
import {HasConsentGuard} from "./shared/hasConsent.guard";

const routes: Routes = [
  {
    path: 'reload',
    loadChildren: () => import('./openaireLibrary/reload/reload.module').then(m => m.ReloadModule),
    data: {hasSidebar: false}
  },
  {
    path: 'user-info',
    loadChildren: () => import('./openaireLibrary/login/user.module').then(m => m.UserModule),
    data: {hasSidebar: false}
  },
  {
    path: 'rpo',
    loadChildren: () => import('./rpo/rpo.module').then(m => m.RpoModule),
    data: {type: 'organization', title: Irish.METADATA_PREFIX}
  },
  {
    path: 'rfo',
    loadChildren: () => import('./rfo/rfo.module').then(m => m.RfoModule),
    data: {type: 'funder', title: Irish.METADATA_PREFIX}
  },
  {
    path: 'researcher',
    loadChildren: () => import('./researcher/researcher.module').then(m => m.ResearcherModule),
    data: {title: Irish.METADATA_PREFIX}
  },
  {
    path: 'repository',
    loadChildren: () => import('./repository/repository.module').then(m => m.RepositoryModule),
    data: {type: 'datasource', title: Irish.METADATA_PREFIX}
  },
  {
    path: 'upload-dois',
    loadChildren: () => import('./upload-dois/upload-dois.module').then(m => m.UploadDoisModule),
    data: {title: Irish.METADATA_PREFIX}, canActivate: [HasConsentGuard]
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
    data: {title: Irish.METADATA_PREFIX}
  },
  {
    path: 'how-it-works',
    loadChildren: () => import('./resources/how-it-works/how-it-works.module').then(m => m.HowItWorksModule)
  },
  {
    path: 'methodology',
    loadChildren: () => import('./resources/methodology/methodology.module').then(m => m.MethodologyModule)
  },
  {
    path: 'stats-logs', 
    loadChildren: () => import('./public-logs/public-logs.module').then(m => m.PublicLogsModule)
  },
  {
    path: 'engagement-training',
    loadChildren: () => import('./resources/engagement/engagement.module').then(m => m.EngagementModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./resources/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    data: {title: Irish.METADATA_PREFIX + ' Admin | '},
    canActivateChild: [HasConsentGuard]
  },
  {path: 'user-policy', loadChildren: () => import('./user-policy/user-policy.module').then(m => m.UserPolicyModule)},
  {path: 'participate', loadChildren: () => import('./claims/claims.module').then(m => m.ClaimsModule), canActivate: [HasConsentGuard]},
  {
    path: 'national',
    loadChildren: () => import('./national/national.module').then(m => m.NationalModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {path: 'orcid', loadChildren: () => import('./orcid/orcid.module').then(m => m.LibOrcidModule), canActivate: [HasConsentGuard]},
  {path: 'my-orcid-links', loadChildren: () => import('./orcid/my-orcid-links/myOrcidLinks.module').then(m => m.LibMyOrcidLinksModule), canActivate: [HasConsentGuard]},
  {path: 'orcid-discover-links', loadChildren: () => import('./orcid/discover-links/discover-links.module').then(m => m.DiscoverLinksModule), canActivate: [HasConsentGuard]},
  {path: 'error', component: ErrorPageComponent},
  {path: '**', pathMatch: 'full', component: ErrorPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
