import {EnvProperties} from "../app/openaireLibrary/utils/properties/env-properties";
import {common, commonBeta} from "../app/openaireLibrary/utils/properties/environments/environment";

let props: EnvProperties = {
  dashboard: "irish",
  adminToolsAPIURL: "https://beta.services.openaire.eu/irish-monitor-service/",
  monitorServiceAPIURL: "https://beta.services.openaire.eu/irish-monitor-service/",
  monitorStatsFrameUrl:"https://beta.services.openaire.eu/stats-tool/",
  piwikSiteId: "786",
  enablePiwikTrack: true,
  adminToolsPortalType: 'country',
  adminToolsCommunity: 'irish',
  domain: "https://beta.oamonitor.ireland.openaire.eu",
  logServiceUrl: "https://beta.oamonitor.ireland.openaire.eu/",
  logFilesPath: "/srv/nodejs/irish-monitor-action-logs/",
  myClaimsLink:"/participate/myclaims",
  loginServiceURL: ["https://beta.services.openaire.eu/irish-monitor-service/", <string>commonBeta.loginServiceURL],
  afterLoginRedirectLink: "/user-policy",
  helpdeskEmail: 'oamonitor.ireland@openaire.eu',
  admins: ['oamonitor.ireland@openaire.eu'],
  useHelpTexts: true,
  orcidDiscoverLinksPage: "/orcid-discover-links"
}

export let properties: EnvProperties = {
  ...common, ...commonBeta, ...props
}
