import {Component, Inject, Input, Optional} from '@angular/core';
import {Location}                 from '@angular/common';
import {ActivatedRoute}           from '@angular/router';
import {Title, Meta}              from '@angular/platform-browser';
import {SEOService} from "../sharedComponents/SEO/SEO.service";
import {properties} from "../../../environments/environment";
import {RESPONSE} from '../utils/tokens';
import {Response} from 'express';
import {OpenaireEntities} from "../utils/properties/searchFields";
import {EnvProperties} from "../utils/properties/env-properties";

@Component({
    selector: 'error',
    template: `
    <div class="uk-section">
      <div class="uk-margin-small-top uk-grid">
       <div class="tm-main uk-width-1-1@s uk-width-1-1@m  uk-width-1-1@l uk-row-first">
        <div class="uk-container">
            <h3>
              <span  *ngIf="page !='-1'">Bad karma: we can't find that page!</span>
              <div  *ngIf="page =='-1'" class="uk-text-center uk-margin-large-top">
                <svg _ngcontent-my-app-c8="" fill="black" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-my-app-c8="" d="M0 0h24v24H0z" fill="none"></path><path _ngcontent-my-app-c8="" d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path></svg>
              Private Data</div>
            </h3>
            <h6 *ngIf="page_type" class="uk-margin-remove">
              Not valid or missing {{page_type_name}} id. 
              <a *ngIf="page_type && properties.adminToolsPortalType !== 'eosc'" routerLinkActive="router-link-active" [routerLink]="searchPage" [queryParams]="searchPagePrams">Search </a>another {{page_type_name}}?
              <a *ngIf="page_type && properties.adminToolsPortalType == 'eosc'" [href]="searchPage" target="_blank" class="custom-external">Search </a>another {{page_type_name}} in OpenAIRE?
            </h6>
            <br>
          <div *ngIf="page !='-1'">
            <p>
                You asked for {{page}}, but despite our computers looking very hard, we could not find it. What happened ?
            </p>
            <ul>
                <li>the link you clicked to arrive here has a typo in it</li>
                <li>or somehow we removed that page, or gave it another name</li>
                <li>or, quite unlikely for sure, maybe you typed it yourself and there was a little mistake ?</li>
            </ul>
          </div>
        </div>
      </div>
     </div>
  </div>
    `
})

export class ErrorPageComponent {
    @Input() public page: string;
    public page_type: string;
    public searchPage: string;
    public searchPagePrams: {};
    public searchPagePramsString: string = "";
    public page_type_name: string;
    public properties: EnvProperties = properties;

    constructor (private _location: Location, private _meta: Meta,
                 private _title: Title, private route: ActivatedRoute,
                 @Optional() @Inject(RESPONSE) private response: Response,
                 private seoService: SEOService) {}
                 
    ngOnInit() {
      this.seoService.createLinkForCanonicalURL(properties.domain + properties.baseLink + "/error");
      this.route.queryParams.subscribe(data => {
        this.page = this.page?this.page:data['page'];
        if (!this.page) {
          this.page = this._location.path(true);
        }
        if(this.page != "-1") {
          let title = "OpenAIRE | Error page";
          this._meta.updateTag({content:title},"property='og:title'");
          this._title.setTitle(title);
          if(this.response) {
            this.response.statusCode = 404;
            this.response.statusMessage = '404 - Page not found';
          }
        } else{
          let title = "OpenAIRE | Private data";
          this._meta.updateTag({content:title},"property='og:title'");
          this._title.setTitle(title);
          if(this.response) {
            this.response.statusCode = 403;
            this.response.statusMessage = '403 - Private data';
          }
        }
        this.page_type = data['page_type'];
        if(this.page_type) {
          if (this.page_type == "publication") {
            this.searchPage = properties.searchLinkToResults;
            this.searchPagePrams = {type: 'publications'}
            this.searchPagePramsString = "&type=publications";
            this.page_type_name = OpenaireEntities.PUBLICATION.toLowerCase();
          } else if (this.page_type == "software") {
            this.searchPage = properties.searchLinkToResults;
            this.searchPagePrams = {type: 'software'}
            this.searchPagePramsString = "&type=software";
            this.page_type_name = OpenaireEntities.SOFTWARE_SINGULAR.toLowerCase();
          } else if (this.page_type == "dataset") {
            this.searchPagePrams = {type: 'datasets'}
            this.searchPagePramsString = "&type=datasets";
            this.searchPage = properties.searchLinkToResults;
            this.page_type_name = OpenaireEntities.DATASET.toLowerCase();
          } else if (this.page_type == "orp") {
            this.searchPagePrams = {type: 'orps'}
            this.searchPagePramsString = "&type=orps";
            this.searchPage = properties.searchLinkToResults;
            this.page_type_name = OpenaireEntities.OTHER_SINGULAR.toLowerCase();
          } else if (this.page_type == "organization") {
            this.searchPage = properties.searchLinkToOrganizations;
            this.page_type_name = OpenaireEntities.ORGANIZATION.toLowerCase();
          } else if (this.page_type == "project") {
            this.searchPage = properties.searchLinkToProjects;
            this.page_type_name = OpenaireEntities.PROJECT.toLowerCase();
          } else if (this.page_type == "dataprovider") {
            this.searchPage = properties.searchLinkToDataProviders;
            this.page_type_name = OpenaireEntities.DATASOURCE.toLowerCase();
          } else if (this.page_type == "service") {
            this.searchPage = properties.searchLinkToServices;
            this.page_type_name = OpenaireEntities.SERVICE.toLowerCase();
          } else {
            this.searchPage = properties.searchLinkToResults;
            this.page_type_name = OpenaireEntities.RESULT.toLowerCase();
          }


          if(properties.adminToolsPortalType == 'eosc') {
            this.searchPage = "https://explore.openaire.eu"+this.searchPage+this.searchPagePramsString;
          }
        }
      });
    }
}
