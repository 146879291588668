import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {properties} from "../../../environments/environment";
import {Observable} from "rxjs";
import {Notification, NotificationUser} from "./notifications";
import {CustomOptions} from "../services/servicesUtils/customOptions.class";
import {not} from "rxjs/internal-compatibility";
import {map} from "rxjs/operators";
import {StringUtils} from '../utils/string-utils.class';

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  
  
  constructor(private httpClient: HttpClient) {
  }
  
  public getAllNotifications(): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(properties.notificationsAPIURL + 'all', CustomOptions.registryOptions()).pipe(map(notifications => {
      notifications.forEach(notification => {
        NotificationService.formatNotification(notification);
      })
      return notifications;
    }));
  }
  
  public getNotifications(service: string): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(properties.notificationsAPIURL + encodeURIComponent(service), CustomOptions.registryOptions()).pipe(map(notifications => {
      notifications.forEach(notification => {
        NotificationService.formatNotification(notification);
      })
      return notifications;
    }));
  }
  
  public sendNotification(notification: Notification): Observable<Notification> {
    return this.httpClient.post<Notification>(properties.notificationsAPIURL + 'save', notification, CustomOptions.registryOptions());
  }
  
  public markAllAsRead(service: string): Observable<NotificationUser> {
    return this.httpClient.put<NotificationUser>(properties.notificationsAPIURL + "all/" + encodeURIComponent(service), null, CustomOptions.registryOptions());
  }
  
  public readNotification(id: string): Observable<NotificationUser> {
    return this.httpClient.put<NotificationUser>(properties.notificationsAPIURL + encodeURIComponent(id), null, CustomOptions.registryOptions());
  }
  
  private static formatNotification(notification: Notification): Notification {
    if (notification.title) {
      notification.preview = notification.title;
    } else {
      notification.preview = StringUtils.HTMLToString(notification.message);
    }
    return notification;
  }
}
