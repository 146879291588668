import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, from, Observable, Subscriber} from "rxjs";
import {SearchCustomFilter} from "../searchPages/searchUtils/searchUtils.class";


@Injectable({
  providedIn: "root"
})
export class CustomFilterService {

  private customFilterSubject: BehaviorSubject<SearchCustomFilter[]> = null;
  private promise: Promise<void>;
  private sub;

  constructor(private http: HttpClient) {
    this.customFilterSubject = new BehaviorSubject<SearchCustomFilter[]>(null);
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  clearSubscriptions() {
    if (this.sub instanceof Subscriber) {
      this.sub.unsubscribe();
    }
  }

  async getCustomFilterAsync() {
    if (this.promise) {
      await this.promise;
      this.promise = null;
    }
    this.clearSubscriptions();
    return this.customFilterSubject.getValue();
  }

 
  getCustomFilterAsObservable(): Observable<SearchCustomFilter[]> {
    return this.customFilterSubject.asObservable();
  }

  setCustomFilter(customFilters: SearchCustomFilter[]) {
    this.customFilterSubject.next(customFilters);
  }
/*
  static getDefaultFilters(){
    let defaultFilters = new Map<string, SearchCustomFilter[]>();
    defaultFilters.set("result",[new SearchCustomFilter("National", "country", "IE", "Irish National Monitor")]);
    defaultFilters.set("project",[new SearchCustomFilter("National", "country", "IE", "Irish National Monitor")]);
    defaultFilters.set("organization",[new SearchCustomFilter("National", "country", "IE", "Irish National Monitor")]);
    defaultFilters.set("datasource",[new SearchCustomFilter("National", "country", "IE", "Irish National Monitor")]);
    return defaultFilters;
  }*/
}
