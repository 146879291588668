import {Page} from './page';
import {Entity} from './entity';

export class Portal {
    _id: string;
    pid: string;
    name: string;
    type: string;
    piwik:number;
		twitterAccount: string;
    entities: string[] | Entity[];
    pages: string[] | Page[];
    static: boolean = false;

     private static getmockPortalInfo():any{
       return {
         "pid": "",
         "name": "",
         "type": "",
         "pages": [
           //deposit
           {
             "route": "/participate/deposit/search",
             "isEnabled": true
           },
           {
             "route": "/participate/deposit/learn-how",
             "isEnabled": true
           },
           //home
           {
             "route": "/",
             "isEnabled": true
           },
           //claims
           {
             "route": "/participate/claim",
             "isEnabled": true,

           },
           {
             "route": "/myclaims",
             "isEnabled": true,
           },
           {
             "route": "/claims",
             "isEnabled": true,
           },
           {
             "route": "/participate/direct-claim",
             "isEnabled": true
           },
           // /advanced search
           {
             "route": "/search/advanced/dataproviders",
             "isEnabled": true
           },
           {
             "route": "/search/advanced/services",
             "isEnabled": true
           },
           {
             "route": "/search/advanced/organizations",
             "isEnabled": true
           },
           {
             "route": "/search/advanced/projects",
             "isEnabled": true
           },
           {
             "route": "/search/advanced/research-outcomes",
             "isEnabled": true
           },
           //search all

           {
             "route": "/search/find",
             "isEnabled": true
           },
           //simple search
           {
             "route": "/search/find/dataproviders",
             "isEnabled": true
           },
           {
             "route": "/search/find/services",
             "isEnabled": true
           },

           {
             "route": "/search/find/organizations",
             "isEnabled": true
           },

           {
             "route": "/search/find/projects",
             "isEnabled": true
           },

           {
             "route": "/search/find/research-outcomes",
             "isEnabled": true
           },
           //landing
           {
             "route": "/search/dataprovider",
             "isEnabled": true
           },

           {
             "route": "/search/organization",
             "isEnabled": true,
           },
           {
             "route": "/search/other",
             "isEnabled": true,
           },
           {
             "route": "/search/project",
             "isEnabled": true
           },
           {
             "route": "/project-report",
             "isEnabled": true
           },
           {
             "route": "/search/publication",
             "isEnabled": true
           },
           {
             "route": "/search/dataset",
             "isEnabled": true
           },
           {
             "route": "/search/content-providers",
             "isEnabled": true
           },
           {
             "route": "/search/software",
             "isEnabled": true
           },
           {
             "route": "/search/organization",
             "isEnabled": true
           },
           {
             "route": "/develop",
             "isEnabled": true
           }
         ],
         "entities": [
           {
             "pid": "publication",
             "isEnabled": true
           },
           {
             "pid": "dataset",
             "isEnabled": true
           },
           {
             "pid": "software",
             "isEnabled": true
           },
           {
             "pid": "project",
             "isEnabled": true
           },
           {
             "pid": "organization",
             "isEnabled": true
           },
           {
             "pid": "datasource",
             "isEnabled": true
           },
           {
             "pid": "service",
             "isEnabled": true
           },
           {
             "pid": "orp",
             "isEnabled": true
           }
         ],
         "static": true
       };
     };

  public static getMockCommunityInfo(pid: string, name:string, disabledEntities:string[],disabledPages:string[]): any {
    let communityInfo = Object.assign({}, Portal.getmockPortalInfo());
    communityInfo.pid = pid;
    communityInfo.name = name;
      for(let disabledPage of disabledPages) {
        for (var i = 0; i < communityInfo.pages.length; i++) {
          if (communityInfo.pages[i].route == disabledPage) {
            communityInfo.pages.splice(i, 1);
            break;
          }
        }
      }
      for(let disableEntity of disabledEntities){
        for (var i = 0; i < communityInfo.entities.length; i++) {
          if (communityInfo.entities[i].pid == disableEntity) {
            communityInfo.entities.splice(i, 1);
            break;
          }
        }
      }

    return communityInfo;
  }
}

export interface CheckPortal {
   portal: Portal;
   checked: boolean;
}

