import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SearchResult} from '../utils/entities/searchResult';
import {RefineResultsUtils} from './servicesUtils/refineResults.class';
import {Dates, DOI, StringUtils} from '../utils/string-utils.class';
import {ParsingFunctions} from '../landingPages/landing-utils/parsingFunctions.class';
import {EnvProperties} from '../utils/properties/env-properties';
import {map} from "rxjs/operators";
import {properties} from "../../../environments/environment";
import {HostedByCollectedFrom} from "../utils/result-preview/result-preview";


@Injectable()
export class SearchResearchResultsService {
  public parsingFunctions: ParsingFunctions = new ParsingFunctions();

  constructor(private http: HttpClient = null) {
  }


  search(resultType: string, params: string, refineParams: string, page: number, size: number, sortBy: string, refineFields: string[], properties: EnvProperties): any {
    let link = properties.searchAPIURLLAst + this.getEntityQueryName(resultType, true);

    let url = link + "?";
    if (params != null && params != '') {
      url += params;
    }
    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    if (sortBy) {
      url += "&sortBy=" + sortBy;
    }
    url += "&page=" + (page - 1) + "&size=" + size + "&format=json";

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties), RefineResultsUtils.parse(res['refineResults'], refineFields, "publication")]));
  }

  searchById(resultType: string, id: string, properties: EnvProperties): any {
    let url = properties.searchAPIURLLAst + this.getEntityQueryName(resultType, true) + "/" + id + "?format=json";

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => this.parseResults(resultType, res, properties)));
  }

  searchAggregators(resultType: string, id: string, params: string, refineParams: string, page: number, size: number, properties: EnvProperties): any {
    let link = properties.searchAPIURLLAst + this.getEntityQueryName(resultType, true);

    let url = link + "?" + "&format=json";
    if (params != null && params != '') {
      url += params;
    }
    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    url += "&page=" + (page - 1) + "&size=" + size;

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => this.parseRefineResults(id, res['refineResults'])));
  }

  searchByListOfDOI(resultType: string, DOIs: string[], refineParams: string, page: number, size: number, refineFields: string[], properties: EnvProperties): any {
    let link = properties.searchAPIURLLAst + this.getEntityQueryName(resultType, true);

    let url = link + "?" + "&format=json&";
    var doisParams = "";

    for (var i = 0; i < DOIs.length; i++) {
      doisParams += (doisParams.length > 0 ? "&" : "") + 'doi="' + DOIs[i] + '"';
    }
    if (doisParams.length > 0) {
      url += "&" + doisParams;

    }
    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    url += "&page=" + (page - 1) + "&size=" + size;

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties), RefineResultsUtils.parse(res['refineResults'], refineFields, "publication")]));
  }

  advancedSearch(resultType: string, params: string, page: number, size: number, sortBy: string, properties: EnvProperties, refineParams: string = null, refineFields: string[] = null, refineQuery: string = null): any {
    let url = properties.searchResourcesAPIURL;
    var basicQuery = "(oaftype exact result) and (resulttypeid exact " + this.getEntityQueryName(resultType, false) + ") ";
    url += "?query=";
    if (params != null && params != '') {
      url += " ( " + basicQuery + " ) " + " and (" + params + ")";
    } else {
      url += " ( " + basicQuery + " ) ";
    }
    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    if (sortBy) {
      let sortOptions = sortBy.split(",");
      url += "sortBy " + sortOptions[0] + "/sort." + sortOptions[1] + " ";
    }
    if (refineQuery) {
      url += "&" + refineQuery;
    }

    url += "&page=" + (page - 1) + "&size=" + size;
    url += "&format=json";

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties), RefineResultsUtils.parse(res['refineResults'], refineFields, "publication")]));
  }

  advancedSearchResults(resultType: string, params: string, page: number, size: number, sortBy: string, properties: EnvProperties, refineParams: string = null, refineFields: string[] = null, refineQuery: string = null, minRef: boolean = false): any {
    let url = properties.searchAPIURLLAst + "resources2/?format=json";
    if (params != null && params != '') {
      url += "&query=(" + params + ")";
    }
    if (sortBy) {
      let sortOptions = sortBy.split(",");
      url += (params ? " " : "&query=(*) ") + "sortBy " + sortOptions[0] + "/sort." + sortOptions[1] + (params ? " " : " ");
    }
    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    if (refineQuery) {
      url += "&" + refineQuery;
    }

    url += "&page=" + (page - 1) + "&size=" + size;
    url += minRef ? "&minRef=true" : "";
    // url += "&format=json";

    // return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
    return this.http.get((properties.useLongCache && size == 0 && !params && (!refineQuery || !refineQuery.includes("fq="))) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties), RefineResultsUtils.parse(res['refineResults'], refineFields, "publication")]));
  }
 countResults(field:string,value:string): any {
    let url = properties.utilsService + "/portals/countResults";
    if (field && value) {
      url += "?field=" + encodeURIComponent(field) + "&value="+encodeURIComponent(value);
    }

    return this.http.get((properties.useLongCache  ) ? (properties.cacheUrl + encodeURIComponent(url)) : url);
  }
  searchResultForEntity(resultType: string, params: string, page: number, size: number, properties: EnvProperties): any {
    let link = properties.searchAPIURLLAst;
    //let url = link+params+"/"+this.getEntityQueryName(resultType,true)+ "?format=json";
    //url += "&page="+(page-1)+"&size="+size;
    //url += "&sortBy=resultdateofacceptance,descending";

    //let url = link+"/resources2?format=json&query="+params+" sortBy resultdateofacceptance/sort.descending&type="+this.getEntityQueryName(resultType,true);

    let url = link + this.getEntityQueryName(resultType, true);
    url += "?format=json";
    url += "&fq=" + params;
    url += "&sortBy=resultdateofacceptance,descending";
    url += "&page=" + (page - 1) + "&size=" + size;

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties)]));
  }

//???? why different from above?
  searchForDataproviders(resultType: string, params: string, page: number, size: number, properties: EnvProperties): any {
    let link = properties.searchAPIURLLAst;
    let url = link + params;
    url += "&sortBy=resultdateofacceptance,descending";
    url += "&page=" + (page - 1) + "&size=" + size + "&format=json";
    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties)]));
  }

  searchForMyOrcidLinks(resultType: string, orcidQuery: string, typeQuery: string, page: number, size: number): any {
    let url = properties.searchAPIURLLAst + "resources2/?format=json";
    if (orcidQuery != null && orcidQuery != '') {
      url += "&query=(" + orcidQuery + ")";
    }
    url += typeQuery;
    url += "&page=" + (page - 1) + "&size=" + size;

    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => [res['meta'].total, this.parseResults(resultType, res['results'], properties)]));
  }

  parseResults(resultType: string, data: any, properties: EnvProperties): SearchResult[] {
    let results: SearchResult[] = [];
    if (data == null) {
      return results;
    }

    let length = Array.isArray(data) ? data.length : 1;

    for (let i = 0; i < length; i++) {

      let resData = Array.isArray(data) ? data[i]: data;
      var result: SearchResult = new SearchResult();
      try {
        if (resData.header['recordType']) {
          result.entityType = resData.header['recordType'];
        } else {
          result.entityType = resultType;
        }

        result['title'] = {"name": '', "accessMode": ''};
        result['title'].name = (resData.result['maintitle']) ? StringUtils.HTMLToString(resData.result['maintitle']) : "";

        if (resData.result['bestaccessright'] && resData.result['bestaccessright']['label']) {
          result['title'].accessMode = resData.result['bestaccessright']['label'];
        }

        result.types = new Array<string>();
        let types = new Set<string>();

        let instance;
        let length = Array.isArray(resData.result['instance']) ? resData.result['instance'].length : 1;
        result.hostedBy_collectedFrom = new Array<HostedByCollectedFrom>();
        for (let i = 0; i < length; i++) {
          instance = Array.isArray(resData.result['instance']) ? resData.result['instance'][i] : resData.result['instance'];
          this.parsingFunctions.parseTypes(result.types, types, instance);
          if (instance && instance["hostedby"]) {
            if (instance["url"]) {
              let url = Array.isArray(instance['url']) ? instance['url'][0] : instance['url'];
              if (url.includes('&amp;')) {
                url = url.replace(/&amp;/gmu, '&');
              }

              this.parsingFunctions.parseHostedBy_collectedFrom(result.hostedBy_collectedFrom, instance, url, result.title.accessMode);
            }
          }
        }
        result.hostedBy_collectedFrom.sort(this.parsingFunctions.compareHostedByCollectedFrom);
        // Measure
        result.measure = this.parsingFunctions.parseMeasures(resData['measures']);
        /////////////////////////// Athena Code ///////////////////////////
        if (resData['pid']) {
          if (!Array.isArray(resData['pid'])) {
            if (resData['pid'].typeCode && resData['pid'].typeCode == 'doi') {
              if (resData['pid'].value != '' && resData['pid'].value != null) {
                result.DOIs.push((resData['pid'].value + "").replace("https://doi.org/", ""));
              }
            }
          } else {
            for (let i = 0; i < resData['pid'].length; i++) {
              if (resData['pid'][i].typeCode == 'doi') {
                if (resData['pid'][i].value != '' && resData['pid'][i].value != null && resData['pid'][i].value) {
                  result.DOIs.push((resData['pid'][i].value + "").replace("https://doi.org/", ""));
                }
              }
            }
          }
          result.identifiers = this.parsingFunctions.parseIdentifiers(resData['pid']);
        }
        /////////////////////////// Athena Code ///////////////////////////
        if (resData.result['programmingLanguage'] && resData.result['programmingLanguage'] != null) {
          result.programmingLanguages = new Array<string>();

          if (!Array.isArray(resData.result['programmingLanguage'])) {
            if (resData.result['programmingLanguage'] != "Undetermined" && resData.result['programmingLanguage']) {
              result.programmingLanguages.push(resData.result['programmingLanguage']);
            }
          } else {
            for (let i = 0; i < resData.result['programmingLanguage'].length; i++) {
              if (resData.result['programmingLanguage'][i] != "Undetermined" && resData.result['programmingLanguage'][i]) {
                result.programmingLanguages.push(resData.result['programmingLanguage'][i]);
              }
            }
          }
        }

        if (resData['language'] && resData['language'] != null) {
          result.languages = new Array<string>();

          if (!Array.isArray(resData['language'])) {
            if (resData['language'].label != "Undetermined" && resData['language'].label) {
              result.languages.push(resData['language'].label);
            }
          } else {
            for (let i = 0; i < resData['language'].length; i++) {
              if (resData['language'][i].label != "Undetermined" && resData['language'][i].label) {
                result.languages.push(resData['language'][i].label);
              }
            }
          }
        }

        if (resData.result['country'] && resData.result['country'] != null) {
          result.countriesForResults = new Array<string>();

          if (!Array.isArray(resData.result['country'])) {
            if (resData.result['country'].label != "Undetermined" && resData.result['country'].label) {
              result.countriesForResults.push(resData.result['country'].label);
            }
          } else {
            for (let i = 0; i < resData.result['country'].length; i++) {
              if (resData.result['country'][i].label != "Undetermined" && resData.result['country'][i].label) {
                result.countriesForResults.push(resData.result['country'][i].label);
              }
            }
          }
        }

        result['id'] = resData['header']['id'];
        result['objId'] = result['id'];
        //TODO canID
        let canId = ParsingFunctions.parseRelCanonicalId(Array.isArray(data) ? data[i] : data, "result");
        if (canId) {
          result['id'] = canId;
        }
        result['relcanId'] = result['id'];
        if (resData['links']) {
          let relLength = Array.isArray(resData['links']) ? resData['links'].length : 1;

          for (let j = 0; j < relLength; j++) {
            let relation = Array.isArray(resData['links']) ? resData['links'][j] : resData['links'];

              if (relation['header']['relationClass'] && relation['header']['relationClass'].toLowerCase() == "isproducedby") {
                result['projects'] = this.parseProjects(result['projects'], relation);
              }
            // }
          }
        }

        if (resData['result']["author"]) {
          if (result['authors'] == undefined) {
            result['authors'] = new Array<{ "fullName": string, "orcid": string, "orcid_pending": string }>();
          }

          let authors = resData['result']['author'];
          let length = Array.isArray(authors) ? authors.length : 1;

          for (let i = 0; i < length; i++) {
            let author = Array.isArray(authors) ? authors[i] : authors;
            if (author) {
              let pids = author.pid?author.pid:[];
              for(let pid of pids) {
                if (pid.typeCode == 'orcid') {
                  author.orcid = pid.value.toUpperCase();
                  break;
                } else if (pid.typeCode == "orcid_pending") {
                  author.orcid_pending = pid.value.toUpperCase();
                }
              }
              /* The same author with the same rank if it  */
              if (result['authors'][author.rank] && result['authors'][author.rank].fullName == author.fullName) {
                if (!author.orcid && result['authors'][author.rank].orcid) {
                  author.orcid = result['authors'][author.rank].orcid;
                } else if (!author.orcid_pending && result['authors'][author.rank].orcid_pending) {
                  author.orcid_pending = result['authors'][author.rank].orcid_pending;
                }

              }

              result['authors'][author.rank] = {
                "fullName": author.fullname,
                "orcid": author.orcid? author.orcid: "",
                "orcid_pending": author.orcid_pending ? author.orcid_pending : ""
              };
            }
          }
          result.authors = result.authors.filter(function (item) {
            return (item != undefined && item.fullName != undefined);
          });
        }

        var date: string = (resData['result'].publicationdate ? resData['result'].publicationdate : '') + ''; // transform to string in case it is an integer
        result.year = (date && (date).indexOf('-') !== -1) ? date.split('-')[0] : date;
        let abstracts = this.parsingFunctions.parseDescription(resData['result'].description, true);
        result.description = abstracts;
        // if (result.description && result.description.length > this.sizeOfDescription) {
        //   result.description = result.description.substring(0, this.sizeOfDescription) + "...";
        // }

        if (resData['result'].embargoenddate && resData['result'].embargoenddate != '') {
          result.embargoEndDate = Dates.getDate(resData['result'].embargoenddate);
        }

        if (!Array.isArray(resData['result'].publisher)) {
          result.publisher = resData['result'].publisher;
        } else {
          for (let i = 0; i < resData.publisher['result'].length; i++) {
            if (result.publisher != undefined) {
              result.publisher += ', ' + resData['result']['publisher'][i];
            } else {
              result.publisher = resData['result']['publisher'][i];
            }
          }
        }
        //todo DOUBLEcheck with connect
        if (resData['context'] != null) {
          result.enermapsId = ParsingFunctions.getEnermapsConceptId(this.parsingFunctions.parseContexts(resData['context']));
        }
        if (resData['result'].publicationdate && resData['result'].publicationdate != null) {
          let date: string = (resData['result'].publicationdate ? resData['result'].publicationdate : '') + ''; // transform to string in case it is an integer
          result.date = (date && (date).indexOf('-') !== -1) ? date.split('-')[0] : date;
          result.dateofacceptance = resData['result'].publicationdate ? Dates.getDate(resData['result'].publicationdate) : null;
        }
        if (resData['result'].journal && resData['result'].journal != null) {
          result.journal = {
            "journal": "",
            "issn": "",
            "lissn": "",
            "eissn": "",
            "issue": "",
            "volume": "",
            "start_page": "",
            "end_page": ""
          }
          result.journal['journal'] = resData['result'].journal.name;
          result.journal['issn'] = resData['result'].journal.issnPrinted;
          result.journal['lissn'] = resData['result'].journal.issnLinking;
          result.journal['eissn'] = resData['result'].journal.issnOnline;
          result.journal['issue'] = resData['result'].journal.iss;
          result.journal['volume'] = resData['result'].journal.vol;
          result.journal['start_page'] = resData['result'].journal.sp;
          result.journal['end_page'] = resData['result'].journal.ep;
        }

        result.hostedBy_collectedFrom = this.parsingFunctions.addPublisherToHostedBy_collectedFrom(
          result.hostedBy_collectedFrom, result.publisher,
          result['journal'] ? result['journal'].journal : null, result.identifiers);


        if (resData['result'].publiclyFunded) {
          result.publiclyFunded = resData['result'].publiclyFunded;
        }
        if (resData['result'].isGreen
          || resData['result'].openAccessColor
          || resData['result'].isInDiamondJournal) {
          result.oaRoutes = {
            "green": resData['result'].isGreen,
            "oaColor": resData['result'].openAccessColor,
            "isInDiamondJournal": resData['result'].isInDiamondJournal
          };
        }
      }catch (e){
        console.error(e)
      }

      results.push(result);
    }
    return results;
  }

  parseProjects(projects: {
    "id": string, "acronym": string, "title": string,
    "funderShortname": string, "funderName": string,
    "code": string
  }[], relation: any): {
    "id": string, "acronym": string, "title": string,
    "funderShortname": string, "funderName": string,
    "code": string
  }[] {
    if (projects == undefined) {
      projects = new Array<{
        "id": string, "acronym": string, "title": string,
        "funderShortname": string, "funderName": string,
        "code": string
      }>();
    }

    let countProjects = projects.length;

    projects[countProjects] = {
      "id": "", "acronym": "", "title": "",
      "funderShortname": "", "funderName": "",
      "code": ""
    };

    if (relation.projectTitle != 'unidentified') {
      projects[countProjects]['id'] = relation['header']['identifier'];
      projects[countProjects]['acronym'] = relation.acronym;
      projects[countProjects]['title'] = relation.projectTitle;
      projects[countProjects]['code'] = relation.code;
    } else {
      projects[countProjects]['id'] = "";
      projects[countProjects]['acronym'] = "";
      projects[countProjects]['title'] = "";
      projects[countProjects]['code'] = "";
    }

    if (relation["funding"]) {
      let fundingLength = Array.isArray(relation['funding']) ? relation['funding'].length : 1;

      for (let z = 0; z < fundingLength; z++) {
        let fundingData = Array.isArray(relation['funding']) ? relation['funding'][z] : relation['funding'];

        if (fundingData["funder"]) {
          projects[countProjects]['funderShortname'] = fundingData['funder'].shortname;
          projects[countProjects]['funderName'] = fundingData['funder'].name;
        }
      }
    }

    return projects;
  }

  parseRefineResults(id: string, data: any): any {
    var results: any = [];
    if (data["resulthostingdatasource"]) {
      let length = Array.isArray(data['resulthostingdatasource']) ? data['resulthostingdatasource'].length : 1;

      for (let i = 0; i < length; i++) {
        let datasource = Array.isArray(data['resulthostingdatasource']) ? data['resulthostingdatasource'][i] : data['resulthostingdatasource'];

        let result: { "name": string, "id": string, "count": number } = {"name": "", "id": "", "count": 0};
        result['name'] = datasource.name;
        result['id'] = datasource.id.split("||")[0];
        result['count'] = datasource.count;

        if (result['id'] != id && result['name'] != "Unknown Repository") {
          results.push(result);
        }
      }
    }
    return results;
  }

  private numOfResults(url: string, properties: EnvProperties): any {
    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => res['total']));
  }

  numOfEntityResults(resultType: string, id: string, entity: string, properties: EnvProperties): any {
    var parameters: string = "";
    parameters = this.getEntityQueryName(entity, true) + "/" + id + "/" + this.getEntityQueryName(resultType, true) + "/count";
    let url = properties.searchAPIURLLAst + parameters + "?format=json";
    return this.numOfResults(url, properties);
  }

  numOfResearchOutcomes(params: string, properties: EnvProperties, refineParams: string = null): any {
    let url = properties.searchAPIURLLAst + "resources2/?format=json&size=0&type=results";
    if (params.length > 0) {
      // var DOIs:string[] = DOI.getDOIsFromString(params);
      // var doisParams = "";
      //
      // for(var i =0 ;i < DOIs.length; i++){
      //   doisParams+=(doisParams.length > 0?"&":"")+'doi="'+ DOIs[i]+'"';
      // }
      // if(doisParams.length > 0){
      //   url += "&"+doisParams;
      // }else{
      //   url += "&query=" + StringUtils.URIEncode(params);
      // }
      url += "&query=" + params;
    }

    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => res['meta']['total']));
  }

  numOfSearchResults(resultType: string, params: string, properties: EnvProperties, refineParams: string = null): any {
    let url = properties.searchAPIURLLAst + this.getEntityQueryName(resultType, true) + "/count?format=json";
    if (params.length > 0) {
      var DOIs: string[] = DOI.getDOIsFromString(params);
      var doisParams = "";

      for (var i = 0; i < DOIs.length; i++) {
        doisParams += (doisParams.length > 0 ? "&" : "") + 'doi="' + DOIs[i] + '"';
      }
      if (doisParams.length > 0) {
        url += "&" + doisParams;
      } else {
        url += "&q=" + StringUtils.URIEncode(params);
      }
    }

    if (refineParams != null && refineParams != '') {
      url += refineParams;
    }
    return this.numOfResults(url, properties);
  }

  numOfSearchResultsLinkedToPub(resultType: string, properties: EnvProperties): any {
    let url = properties.searchAPIURLLAst + "resources?query=" + encodeURIComponent("( (oaftype exact result) and (resulttypeid exact " + resultType + ") and (relresulttype=publication)  )") + "&page=0&size=0&format=json";
    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => res['meta']['total']));
  }

  countTotalResults(resultType: string, properties: EnvProperties, refineParams: string = null): any {
    let url = properties.searchAPIURLLAst + this.getEntityQueryName(resultType, true) + "/count?format=json" + refineParams;
    return this.numOfResults(url, properties);
  }

  /*
      private quote(word: any): string {
          return '"'+word+'"';
      }
  */

  private getEntityQueryName(entityType: string, plural: boolean) {
    if (entityType == "publication" || entityType == "dataset" || entityType == "organization" || entityType == "datasource" || entityType == "project") {
      if (plural) {
        return entityType + "s";
      } else {
        return entityType;
      }
    } else {
      return entityType;
    }
  }

  public countCollectedResultsWithFundingInfo(datasourceId: string) {
    let url = properties.searchAPIURLLAst + "resources?query=" + encodeURIComponent("(oaftype=result and collectedfromdatasourceid exact \"" + datasourceId + "\" and relprojectid=*)") + "&page=0&size=0&format=json";
    return this.http.get((properties.useCache) ? (properties.cacheUrl + encodeURIComponent(url)) : url)
        .pipe(map(res => res['meta']['total']));
  }
  fetchByDOIs(DOIs:string[],query:string): any {
    let url = properties.searchAPIURLLAst + "researchProducts/byDoi?type=publications" + (query?query:"");
    return this.http.post(url, {doiArray: DOIs})
      .pipe(map(res => [res['meta'].total, this.parseResults("result", res['results'], properties), RefineResultsUtils.parse(res['refineResults'], null, "publication")]));
  }
}
