import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FullScreenModalComponent} from "./full-screen-modal.component";
import {IconsModule} from "../../icons/icons.module";

@NgModule({
  imports: [CommonModule, IconsModule],
  declarations: [FullScreenModalComponent],
  exports: [FullScreenModalComponent]
})
export class FullScreenModalModule {
}
