import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MobileDropdownComponent} from "./mobile-dropdown.component";
import {IconsModule} from "../icons/icons.module";

@NgModule({
  imports: [CommonModule, IconsModule],
  declarations: [MobileDropdownComponent],
  exports: [MobileDropdownComponent]
})
export class MobileDropdownModule {}
