import { NgModule }            from '@angular/core';


import {SafeHtmlPipe} from './safeHTML.pipe';

@NgModule({
  imports: [

  ],
  declarations: [
    SafeHtmlPipe
  ],
  providers:[],
  exports: [
    SafeHtmlPipe
    ]
})


export class SafeHtmlPipeModule{

}
