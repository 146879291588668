import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AppRoutingModule} from '../../app-routing.module';
import {IconsModule} from '../../openaireLibrary/utils/icons/icons.module';
import {HelpPopUpComponent} from './help-pop-up.component';
import {AlertModalModule} from '../../openaireLibrary/utils/modal/alertModal.module';
import {ContactModule} from '../../contact/contact.module';


@NgModule({
  imports: [
    CommonModule, FormsModule, AppRoutingModule, IconsModule, ContactModule, AlertModalModule
  ],
  declarations: [
    HelpPopUpComponent
  ],
  providers:[],
  exports: [
    HelpPopUpComponent
  ]
})
export class HelpPopUpModule {
}
