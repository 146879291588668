import {Directive} from "@angular/core";
import {BaseComponent} from "../openaireLibrary/sharedComponents/base/base.component";
import {SearchOrcidService} from "../openaireLibrary/claims/claim-utils/service/searchOrcid.service";
import {SearchResearchResultsService} from "../openaireLibrary/services/searchResearchResults.service";
import {properties} from "../../environments/environment";
import {StringUtils} from "../openaireLibrary/utils/string-utils.class";
import {StakeholderBaseComponent} from "../openaireLibrary/monitor-admin/utils/stakeholder-base.component";

interface Resolver {
  success?: Function;
  error?: Function;
}

@Directive()
export class ResearcherBaseComponent extends  StakeholderBaseComponent {
  author: any;
  authorId: string;
  orcid: Resolver;
  results: Resolver;
  param: string;

  protected _searchOrcidService: SearchOrcidService;
  protected _searchResearchResultsService: SearchResearchResultsService;


  search() {
    this.subscriptions.push(this._searchOrcidService.searchOrcidSingleAuthor(this.authorId, properties, true).subscribe(data => {
      this.author = data;
      if(this.orcid?.success) {
        this.orcid.success(data);
      }
      this.getResultsForAuthor();
    }, () => {
      if(this.orcid?.error) {
        this.orcid.error();
      }
    }));
  }

  getResultsForAuthor(){
    this.param = '(authorid="' + StringUtils.URIEncode(this.author.id) + '")';
    this.subscriptions.push(this._searchResearchResultsService.numOfResearchOutcomes(this.param, properties, null).subscribe(res => {
      if(this.results?.success) {
        this.results?.success(res);
      }
    }));
  }
}
