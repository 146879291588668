


export class RouterHelper {
  //Use this class function to create queryParams Objects in format {key1:value1} or {key1:value1,key2:value2,key3:value3,...} for  multiple parameters
  constructor(){}
  //  <a  [queryParams]="RouterHelper.createQueryParam(filter.filterId,value.id)" routerLinkActive="router-link-active" [routerLink]=baseUrl>Link</a>

  public addQueryParam(key:string,value:string, obj){
    if(!obj) {
      obj = {};
    }
    obj[key] = value;
    return obj;
  }

  public createQueryParam(key:string,value:string){
    var obj ={};
    obj[key]=value;
    return obj;

  }
  public createQueryParamsPaging(keys:string[],values:string[],pageParameter:string,pageValue:number){
    var obj = this.createQueryParams(keys, values);
    obj[pageParameter] = ""+pageValue;
    return obj;

  }
  public createQueryParams(keys:string[],values:string[]){
    var obj ={};
    if(!keys || !values || keys.length != values.length){
      return obj;
    }else{
      for(var i=0; i< keys.length; i++){
          obj[keys[i]]=values[i];
      }
    }
    return obj;

  }
  public createQueryParamsString(keys:string[],values:string[]){
    let obj ="?";
    if(!keys || !values || keys.length != values.length || keys.length === 0){
      return "";
    }else{
      for(let i=0; i< keys.length; i++){
        obj+=(i==0?"":"&")+encodeURIComponent(keys[i])+"="+encodeURIComponent(values[i]);
      }
    }
    return obj;

  }


}
