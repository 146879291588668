import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output, PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Session, User} from './utils/helper.class';
import {RouterHelper} from '../utils/routerHelper.class';
import {Subscriber} from "rxjs";
import {MenuItem} from "../sharedComponents/menu";
import {UserManagementService} from "../services/user-management.service";
import {
  NotificationConfiguration,
  NotificationsSidebarComponent
} from "../notifications/notifications-sidebar/notifications-sidebar.component";
import {isPlatformServer} from "@angular/common";

declare var UIkit;

@Component({
  selector: 'user-mini',
  template: `
    <ul class="uk-navbar-nav">
      <li>
        <a *ngIf="!loggedIn && !mobileView" (click)="logIn()">Sign in</a>
        <ng-container *ngIf="loggedIn && !mobileView">
          <a class="login uk-icon">
            <svg height="60" width="60">
              <circle cx="30" cy="30" r="20" stroke-width="2"></circle>
              <text x="50%" y="50%" text-anchor="middle" dy=".4em" font-size="16">
                {{firstLetters ? firstLetters : 'AN'}}
              </text>
            </svg>
          </a>
          <div *ngIf="isClient" class="uk-navbar-dropdown uk-dropdown" uk-dropdown="pos: bottom-right">
            <ul class="uk-nav uk-navbar-dropdown-nav">
              <ng-container *ngFor="let item of userMenuItems ">
                <li *ngIf="item.needsAuthorization && isAuthorized || !item.needsAuthorization"
                    [ngClass]="item.customClass"
                    [class.uk-active]="isTheActiveSubMenu(item)">
                  <a *ngIf="item.route" [routerLink]="item.route"
                     [queryParams]="item.params">{{item.title}}</a>
                  <a *ngIf="!item.route && item.url" [href]="item.url" [class.custom-external]="item.target != '_self'"
                     [target]="item.target">{{item.title}}</a>
                </li>
              </ng-container>
              <li *ngIf="userMenuItems.length > 0" class="uk-nav-divider"></li>
              <li><a (click)="logOut()">Log out</a></li>
            </ul>
          </div>
        </ng-container>
        <a *ngIf="!loggedIn && mobileView" (click)="logIn()">
          <icon name="login" ratio="1.5" [flex]="true"></icon>
        </a>
        <a *ngIf="loggedIn && mobileView" href="#account" class="login uk-icon" uk-toggle>
          <span class="uk-position-relative">
            <svg height="30" width="30">
              <circle cx="15" cy="15" r="15" stroke-width="2"></circle>
              <text x="50%" y="50%" text-anchor="middle" dy=".35em" font-size="14">
                {{firstLetters ? firstLetters : 'AN'}}
              </text>
            </svg>
            <span *ngIf="notificationsSidebar?.unreadCount > 0" class="uk-text-secondary notification">
              <icon name="circle" ratio="0.8"></icon>
            </span>
          </span>
        </a>
        <div *ngIf="loggedIn" #account id="account" class="uk-offcanvas" uk-offcanvas="flip: true; overlay: true;">
          <div class="uk-offcanvas-bar uk-padding-remove">
            <nav class="uk-navbar uk-background-default" uk-sticky>
              <div class="uk-navbar-right">
                <button class="uk-navbar-toggle uk-icon uk-close" (click)="closeCanvas(account)">
                  <icon name="close" ratio="1.5" visuallyHidden="close account"></icon>
                </button>
              </div>
            </nav>
            <ng-container *ngIf="!showNotifications">
              <div class="uk-padding uk-padding-remove-top uk-flex uk-flex-middle">
                <div class="login">
                  <svg height="60" width="60" style="max-width: 60px; height: 60px;">
                    <circle cx="30" cy="30" r="20" stroke-width="2"></circle>
                    <text x="50%" y="50%" text-anchor="middle" dy=".4em" font-size="16">
                      {{firstLetters ? firstLetters : 'AN'}}
                    </text>
                  </svg>
                </div>
                <h5 class="uk-margin-small-left uk-margin-remove-bottom uk-margin-remove-top uk-text-truncate">
                  {{user.fullname}}
                </h5>
              </div>
              <ul class="uk-nav uk-nav-primary uk-list uk-margin-top" uk-nav>
                <ng-container *ngFor="let item of userMenuItems ">
                  <li *ngIf="item.needsAuthorization && isAuthorized || !item.needsAuthorization" [ngClass]="item.customClass">
                    <a *ngIf="item.route" [routerLink]="item.route" (click)="closeCanvas(account)">
                        {{item.title}}<span *ngIf="item.items.length > 0" class="uk-nav-parent-icon"></span></a>
                    <a *ngIf="!item.route && item.url" (click)="closeCanvas(account)" [href]="item.url" [class.custom-external]="item.target != '_self'"
                       [target]="item.target">{{item.title}}<span *ngIf="item.items.length > 0" class="uk-nav-parent-icon"></span></a>
                  </li>
                </ng-container>
                <ng-container *ngIf="notificationConfiguration">
                  <li *ngIf="userMenuItems.length > 0" class="uk-nav-divider"></li>
                  <li *ngIf="notificationConfiguration">
                    <a (click)="toggleNotifications()">
                      <div class="uk-flex uk-flex-middle uk-width-1-1">
                        <span class="uk-width-expand">Notifications</span>
                        <icon *ngIf="notificationsSidebar?.unreadCount > 0" class="uk-margin-small-left uk-text-secondary uk-margin-right" name="circle" [flex]="true" ratio="0.8"></icon>
                      </div>
                    </a>
                  </li>
                </ng-container>
                <li *ngIf="userMenuItems.length > 0" class="uk-nav-divider"></li>
                <li><a (click)="logOut()">Log out</a></li>
              </ul>
            </ng-container>
            <notification-sidebar [class.uk-hidden]="!showNotifications" #notificationsSidebar *ngIf="notificationConfiguration" [mobile]="true"
                                  (showNotificationsEmitter)="showNotifications = $event"
                                  [configuration]="notificationConfiguration" [user]="user"></notification-sidebar>
          </div>
        </div>
      </li>
    </ul>
  `
})
export class UserMiniComponent implements OnInit, OnChanges {
  isClient: boolean = false;
  @Input() user: User;
  public loggedIn: boolean = false;
  public isAuthorized: boolean = false;
  @Input() public mobileView: boolean = false;
  public firstLetters: string = "";
  public routerHelper: RouterHelper = new RouterHelper();
  @Input() userMenuItems;
  @Input() notificationConfiguration: NotificationConfiguration;
  @ViewChild('notificationsSidebar') notificationsSidebar: NotificationsSidebarComponent;
  public showNotifications = false;
  private subscriptions = [];
  
  constructor(private router: Router, private route: ActivatedRoute, private userManagementService: UserManagementService,
              @Inject(PLATFORM_ID) private platform: any) {
      this.isClient = !isPlatformServer(this.platform);
  }
  
  ngOnInit() {
    this.initUser();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.initUser();
    }
  }
  
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      if (subscription instanceof Subscriber) {
        subscription.unsubscribe();
      }
    });
  }
  
  closeCanvas(canvas) {
    UIkit.offcanvas(canvas).hide();
  }
  
  initUser() {
    if (this.user) {
      this.loggedIn = true;
      this.parseName();
      this.isAuthorized = Session.isClaimsCurator(this.user) || Session.isPortalAdministrator(this.user);
    } else {
      this.loggedIn = false;
      this.isAuthorized = false;
      this.user = null;
    }
  }
  
  logOut() {
    if (this.user) {
      this.userManagementService.logout();
    }
  }
  
  logIn() {
    this.userManagementService.login();
  }
  
  isTheActiveSubMenu(menuItem: MenuItem): boolean {
    let currentRoute = this.router.url.split('?')[0];
    return menuItem.route == currentRoute || menuItem.route == (currentRoute + "/");
  }
  
  parseName() {
    this.firstLetters = "";
    if (this.user && this.user.firstname) {
      this.firstLetters += this.user.firstname.substr(0, 1);
    }
    if (this.user && this.user.lastname) {
      this.firstLetters += this.user.lastname.substr(0, 1);
    }
    if (!this.firstLetters && this.user && this.user.fullname) {
      let matches = this.user.fullname.match(/\b(\w)/g);
      this.firstLetters += matches.join('');
    }
  }
  
  toggleNotifications() {
    this.showNotifications = !this.showNotifications;
  }
}
