import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError, TimeoutError} from "rxjs";
import {catchError} from "rxjs/operators";
import {Session} from "./login/utils/helper.class";
import {GuardsCheckEnd, GuardsCheckStart, Router} from "@angular/router";
import {LoginErrorCodes} from "./login/utils/guardHelper.class";
import {properties} from "../../environments/environment";
import * as url from "url";

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  
  private static UNAUTHORIZED_WHITELIST = [properties.orcidAPIURL, properties.registryUrl? (properties.registryUrl + 'verification/'):null, properties.eoscDataTransferAPI].filter(value => !!value);
  private url: string = null;

  constructor(private router: Router) {
    this.router.events.forEach(event => {
      if(event instanceof GuardsCheckStart) {
        this.url = event.url;
      } else if(event instanceof GuardsCheckEnd) {
        this.url = null;
      }
    })
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
      if(err instanceof TimeoutError) {
        console.error(req.url, "Timeout");
      }
      if ((err.status === 0 && properties.registryUrl && this.isService(req, properties.registryUrl) && req.method !== 'GET') ||
        (err.status === 401 && !this.isService(req, ErrorInterceptorService.UNAUTHORIZED_WHITELIST))) {
        this.logOut();
      } else if(err.status === 403 && !this.isService(req, ErrorInterceptorService.UNAUTHORIZED_WHITELIST)) {
        this.unauthorized();
      }
      return throwError(err);
    }));
  }
  
  isService(req: HttpRequest<any>, service: string | string[]):boolean {
    if(Array.isArray(service)) {
      return !!service.find(element => req.url.indexOf(element) !== -1);
    } else {
      return req.url.indexOf(service) !== -1;
    }
  }
  
  logOut() {
    Session.removeUser();
    this.router.navigate(['/user-info'], {
      queryParams: {
        'errorCode': LoginErrorCodes.NOT_LOGIN,
        'redirectUrl': this.url?this.url:this.router.url
      }
    });
  }
  
  unauthorized() {
    this.router.navigate(['/user-info'], {
      queryParams: {
        'errorCode': LoginErrorCodes.NOT_AUTHORIZED,
        'redirectUrl': this.url?this.url:this.router.url
      }
    });
  }
}
