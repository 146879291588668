import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {IconsComponent} from "./icons.component";
import {SafeHtmlPipeModule} from "../pipes/safeHTMLPipe.module";

@NgModule({
  imports: [CommonModule, SafeHtmlPipeModule],
  declarations: [IconsComponent],
  exports: [IconsComponent]
})
export class IconsModule {}
