import {Portal} from "../openaireLibrary/utils/entities/adminTool/portal";
import {StakeholderConfiguration} from "../openaireLibrary/monitor-admin/utils/indicator-utils";
import {LayoutService} from "../openaireLibrary/dashboard/sharedComponents/sidebar/layout.service";
import {Role} from "../openaireLibrary/login/utils/helper.class";
import {Stakeholder} from "../openaireLibrary/monitor/entities/stakeholder";
import {LinksResolver} from "../search/links-resolver";
import {BelongsTo} from "../openaireLibrary/utils/entities/resultLandingInfo";
import {properties} from "../../environments/environment";
import {OpenaireEntities} from "../openaireLibrary/utils/properties/searchFields";

export class Irish {
  public static irishAdminToolsPortalType = "country";
  public static irishAdminToolsCommunity = "irish";
  public static METADATA_PREFIX = '';

  public portal: Portal = Portal.getMockCommunityInfo("noami", "Noami", ['service'], ['/search/advanced/services', '/search/find/services']);

  constructor() {
    LinksResolver.resetProperties();

    Role.ROLES.manager = 'primary dashboard manager';
    Role.ROLES.member = 'manager';

    StakeholderConfiguration.ENTITIES.stakeholder = 'Monitor';
    StakeholderConfiguration.ENTITIES.stakeholders = 'Monitors';
    StakeholderConfiguration.ENTITIES.funder = 'RFO';
    StakeholderConfiguration.ENTITIES.funders = 'RFOs';
    StakeholderConfiguration.ENTITIES.organization = 'RPO';
    StakeholderConfiguration.ENTITIES.organizations = 'RPOs';
    StakeholderConfiguration.ENTITIES.datasource = 'Repository';
    StakeholderConfiguration.ENTITIES.datasources = 'Repositories';
    StakeholderConfiguration.ENTITIES.datasource = 'Repository';
    StakeholderConfiguration.ENTITIES.researcher = 'Researcher';
    StakeholderConfiguration.ENTITIES.researchers = 'Researchers';

    StakeholderConfiguration.STAKEHOLDER_CATEGORIES = [
      {name: 'All', plural: 'All', value: 'all'},
      {name: 'Template', plural: 'Templates', value: 'templates'},
      {name: 'Monitor', plural: 'Monitors', value: 'standalone'},
    ];

    StakeholderConfiguration.TYPES = [
      {value: 'funder', label: StakeholderConfiguration.ENTITIES.funder},
      {value: 'organization', label: StakeholderConfiguration.ENTITIES.organization},
      {value: 'country', label: StakeholderConfiguration.ENTITIES.country},
      {value: 'datasource', label: StakeholderConfiguration.ENTITIES.datasource},
      {value: 'researcher', label: StakeholderConfiguration.ENTITIES.researcher}
    ];

    StakeholderConfiguration.FUNDER_TYPES = [
      {value: null, label: 'None'},
      {value: 'private', label: 'Private'},
      {value: 'government', label: 'Government'}
    ];

    StakeholderConfiguration.VISIBILITIES = [
      {icon: 'earth', value: "PUBLIC", label: 'Public'},
    ];

    StakeholderConfiguration.CACHE_INDICATORS = true;

    LayoutService.HEADER_HEIGHT = '60px';

    Role.GROUP = 'irish.';

    /** OA Indicator */
    Irish.initializeOAIndicators();

    /* Landing belongs to*/
    BelongsTo.result = {
      fields: [
        {path: ['countries'], value: 'Ireland'},
        {path: ['organizations', 'country'], value: 'Ireland'},
        {path: ['fundedByProjects', 'funderJurisdiction'], value: 'IE'}
      ],
      message: 'This ((result)) is not specific to Ireland. For more details, please visit ' +
          '<a href="https://' + (properties.environment !== 'production' ? 'beta.' : '') + 'explore.openaire.eu/search/result?((type_id))=((id))" target="_blank">OpenAIRE Explore.</a>'
    };
    BelongsTo.project = {
      fields: [
        {path: ['organizations', 'country'], value: 'Ireland'},
        {path: ['funding', 'funderJurisdiction'], value: 'IE'}
      ],
      message: 'This ' + OpenaireEntities.PROJECT + ' is not specific to Ireland. For more details, please visit ' +
          '<a href="https://' + (properties.environment !== 'production' ? 'beta.' : '') + 'explore.openaire.eu/search/project?projectId=((id))" target="_blank">OpenAIRE Explore.</a>'
    };
    BelongsTo.organization = {
      fields: [
        {path: ['country'], value: 'Ireland'}
      ],
      message: 'This ' + OpenaireEntities.ORGANIZATION + ' is not specific to Ireland. For more details, please visit ' +
          '<a href="https://' + (properties.environment !== 'production' ? 'beta.' : '') + 'explore.openaire.eu/search/organization?organizationId=((id))" target="_blank">OpenAIRE Explore.</a>'
    };
    BelongsTo.datasource = {
      fields: [
        {path: ['countries'], value: 'Ireland'}
      ],
      message: 'This ' + OpenaireEntities.DATASOURCE + ' is not specific to Ireland. For more details, please visit ' +
          '<a href="https://' + (properties.environment !== 'production' ? 'beta.' : '') + 'explore.openaire.eu/search/dataprovider?datasourceId=((id))" target="_blank">OpenAIRE Explore.</a>'
    };
  }

  public static initializeOAIndicators(): void {
    StakeholderConfiguration.openAccess.set('country', {
      numerator: {
        "type": null,
        "format": "NUMBER",
        "source": "stats-tool",
        "url": "raw?json=",
        "jsonPath": ["data", "0", "0", "0"],
        "chartObject": "{\"verbose\":false,\"series\":[{\"query\":{\"parameters\":[],\"select\":[{\"field\":\"publication\",\"aggregate\":\"count\"}],\"filters\":[{\"groupFilters\":[{\"field\":\"publication.indi_result_oa_with_license.oa_with_license\",\"type\":\"=\",\"values\":[\"1\"]},{\"field\":\"publication.result_refereed.refereed\",\"type\":\"=\",\"values\":[\"peerReviewed\"]}],\"op\":\"AND\"}],\"entity\":\"publication\",\"profile\":\"ie_monitor\",\"limit\":\"30\"}}]}",
        "parameters": {},
        "filters": {},
        "filtersApplied": 0
      },
      denominator: {
        "type": null,
        "format": "NUMBER",
        "source": "stats-tool",
        "url": "raw?json=",
        "jsonPath": ["data", "0", "0", "0"],
        "chartObject": "{\"verbose\":false,\"series\":[{\"query\":{\"parameters\":[],\"select\":[{\"field\":\"publication\",\"aggregate\":\"count\"}],\"filters\":[{\"groupFilters\":[{\"field\":\"publication.result_refereed.refereed\",\"type\":\"=\",\"values\":[\"peerReviewed\"]}],\"op\":\"AND\"}],\"entity\":\"publication\",\"profile\":\"ie_monitor\",\"limit\":\"30\"}}]}",
        "parameters": {},
        "filters": {},
        "filtersApplied": 0
      }
    });
    StakeholderConfiguration.openAccess.set('organization', {
      numerator: {
        "type": null,
        "format": "NUMBER",
        "source": "stats-tool",
        "url": "raw?json=",
        "jsonPath": ["data", "0", "0", "0"],
        "chartObject": "{\"verbose\":false,\"series\":[{\"query\":{\"parameters\":[],\"select\":[{\"field\":\"publication\",\"aggregate\":\"count\"}],\"filters\":[{\"groupFilters\":[{\"field\":\"publication.organization.id\",\"type\":\"=\",\"values\":[\"((__index_id__))\"]},{\"field\":\"publication.result_refereed.refereed\",\"type\":\"=\",\"values\":[\"peerReviewed\"]},{\"field\":\"publication.indi_result_oa_with_license.oa_with_license\",\"type\":\"=\",\"values\":[\"1\"]}],\"op\":\"AND\"}],\"entity\":\"publication\",\"profile\":\"((__profile__))\",\"limit\":\"30\"}}]}",
        "parameters": {},
        "filters": {},
        "filtersApplied": 0
      },
      denominator: {
        "type": null,
        "format": "NUMBER",
        "source": "stats-tool",
        "url": "raw?json=",
        "jsonPath": ["data", "0", "0", "0"],
        "chartObject": "{\"verbose\":false,\"series\":[{\"query\":{\"parameters\":[],\"select\":[{\"field\":\"publication\",\"aggregate\":\"count\"}],\"filters\":[{\"groupFilters\":[{\"field\":\"publication.organization.id\",\"type\":\"=\",\"values\":[\"((__index_id__))\"]},{\"field\":\"publication.result_refereed.refereed\",\"type\":\"=\",\"values\":[\"peerReviewed\"]}],\"op\":\"AND\"}],\"entity\":\"publication\",\"profile\":\"((__profile__))\",\"limit\":\"30\"}}]}",
        "parameters": {},
        "filters": {},
        "filtersApplied": 0
      }
    });
    StakeholderConfiguration.openAccess.set('funder', {
      numerator: {
        "type": null,
        "format": "NUMBER",
        "source": "stats-tool",
        "url": "raw?json=",
        "jsonPath": ["data", "0", "0", "0"],
        "chartObject": "{\"verbose\":false,\"series\":[{\"query\":{\"parameters\":[],\"select\":[{\"field\":\"publication\",\"aggregate\":\"count\"}],\"filters\":[{\"groupFilters\":[{\"field\":\"publication.project.funder.id\",\"type\":\"=\",\"values\":[\"((__index_id__))\"]},{\"field\":\"publication.indi_result_oa_with_license.oa_with_license\",\"type\":\"=\",\"values\":[\"1\"]}],\"op\":\"AND\"}],\"entity\":\"publication\",\"profile\":\"((__profile__))\",\"limit\":\"30\"}}]}",
        "parameters": {},
        "filters": {},
        "filtersApplied": 0
      },
      denominator: {
        "type": null,
        "format": "NUMBER",
        "source": "stats-tool",
        "url": "raw?json=",
        "jsonPath": ["data", "0", "0", "0"],
        "chartObject": "{\"verbose\":false,\"series\":[{\"query\":{\"parameters\":[],\"select\":[{\"field\":\"publication\",\"aggregate\":\"count\"}],\"filters\":[{\"groupFilters\":[{\"field\":\"publication.project.funder.id\",\"type\":\"=\",\"values\":[\"((__index_id__))\"]},{\"field\":\"publication.result_refereed.refereed\",\"type\":\"=\",\"values\":[\"peerReviewed\"]}],\"op\":\"AND\"}],\"entity\":\"publication\",\"profile\":\"((__profile__))\",\"limit\":\"30\"}}]}",
        "parameters": {},
        "filters": {},
        "filtersApplied": 0
      }
    });
    StakeholderConfiguration.openAccess.set('researcher', {
      numerator: {
        "type": null,
        "format": "NUMBER",
        "source": "stats-tool",
        "url": "raw?json=",
        "jsonPath": ["data", "0", "0", "0"],
        "chartObject": "{\"verbose\":false,\"series\":[{\"query\":{\"parameters\":[],\"select\":[{\"field\":\"publication\",\"aggregate\":\"count\"}],\"filters\":[{\"groupFilters\":[{\"field\":\"publication.result_orcid.orcid\",\"type\":\"=\",\"values\":[\"((__index_id__))\"]},{\"field\":\"publication.result_refereed.refereed\",\"type\":\"=\",\"values\":[\"peerReviewed\"]},{\"field\":\"publication.indi_result_oa_with_license.oa_with_license\",\"type\":\"=\",\"values\":[\"1\"]}],\"op\":\"AND\"}],\"entity\":\"publication\",\"profile\":\"((__profile__))\",\"limit\":\"30\"}}]}",
        "parameters": {"statsProfile": "openaire_stats"},
        "filters": {},
        "filtersApplied": 0
      },
      denominator: {
        "type": null,
        "format": "NUMBER",
        "source": "stats-tool",
        "url": "raw?json=",
        "jsonPath": ["data", "0", "0", "0"],
        "chartObject": "{\"verbose\":false,\"series\":[{\"query\":{\"parameters\":[],\"select\":[{\"field\":\"publication\",\"aggregate\":\"count\"}],\"filters\":[{\"groupFilters\":[{\"field\":\"publication.result_orcid.orcid\",\"type\":\"=\",\"values\":[\"((__index_id__))\"]},{\"field\":\"publication.result_refereed.refereed\",\"type\":\"=\",\"values\":[\"peerReviewed\"]}],\"op\":\"AND\"}],\"entity\":\"publication\",\"profile\":\"((__profile__))\",\"limit\":\"30\"}}]}",
        "parameters": {"statsProfile": "openaire_stats"},
        "filters": {},
        "filtersApplied": 0
      }
    });
    StakeholderConfiguration.openAccess.set('datasource', {
      numerator: {
        "type": null,
        "format": "NUMBER",
        "source": "stats-tool",
        "url": "raw?json=",
        "jsonPath": ["data", "0", "0", "0"],
        "chartObject": "{\"verbose\":false,\"series\":[{\"query\":{\"parameters\":[],\"select\":[{\"field\":\"publication\",\"aggregate\":\"count\"}],\"filters\":[{\"groupFilters\":[{\"field\":\"publication.datasource.id\",\"type\":\"=\",\"values\":[\"((__index_id__))\"]},{\"field\":\"publication.result_refereed.refereed\",\"type\":\"=\",\"values\":[\"peerReviewed\"]},{\"field\":\"publication.indi_result_oa_with_license.oa_with_license\",\"type\":\"=\",\"values\":[\"1\"]}],\"op\":\"AND\"}],\"entity\":\"publication\",\"profile\":\"((__profile__))\",\"limit\":\"30\"}}]}",
        "parameters": {},
        "filters": {},
        "filtersApplied": 0
      },
      denominator: {
        "type": null,
        "format": "NUMBER",
        "source": "stats-tool",
        "url": "raw?json=",
        "jsonPath": ["data", "0", "0", "0"],
        "chartObject": "{\"verbose\":false,\"series\":[{\"query\":{\"parameters\":[],\"select\":[{\"field\":\"publication\",\"aggregate\":\"count\"}],\"filters\":[{\"groupFilters\":[{\"field\":\"publication.datasource.id\",\"type\":\"=\",\"values\":[\"((__index_id__))\"]},{\"field\":\"publication.result_refereed.refereed\",\"type\":\"=\",\"values\":[\"peerReviewed\"]}],\"op\":\"AND\"}],\"entity\":\"publication\",\"profile\":\"((__profile__))\",\"limit\":\"30\"}}]}",
        "parameters": {},
        "filters": {},
        "filtersApplied": 0
      }
    });
  }
}

export class StakeholderExtended extends Stakeholder {
  publications: number;
  openAccess: number;
}
