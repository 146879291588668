import { Pipe, PipeTransform} from '@angular/core'

import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  
  transform(value):SafeUrl {
    return this.sanitized.bypassSecurityTrustHtml(value.replace(/&nbsp;/g,''));
  }
}
