/**
 * Created by stefania on 7/13/17.
 */
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from 'rxjs';
import {Page} from "../utils/entities/adminTool/page";
import {PageHelpContent} from "../utils/entities/adminTool/page-help-content";
import {Portal} from "../utils/entities/adminTool/portal";
import {Entity} from "../utils/entities/adminTool/entity";
import {DivId} from "../utils/entities/adminTool/divId";
import {DivHelpContent} from "../utils/entities/adminTool/div-help-content";
import {StatisticsDisplay, StatisticsSummary} from '../connect/statistics/statisticsEntities';
import {CustomOptions} from './servicesUtils/customOptions.class';
import {catchError, map} from "rxjs/operators";
import {properties} from "../../../environments/environment";
import {Menu, MenuItem, MenuItemExtended} from '../sharedComponents/menu';

@Injectable()
export class HelpContentService {

  constructor(private http:HttpClient) {
  }

  static removeNulls(obj){
    var isArray = obj instanceof Array;
    for (var k in obj){
      if (obj[k]===null || obj[k]==='') isArray ? obj.splice(k,1) : delete obj[k];
      else if (typeof obj[k]=="object") HelpContentService.removeNulls(obj[k]);
    }
  }

  getEntities() {
    return this.http.get<Array<Entity>>(properties.adminToolsAPIURL + 'entity')
      .pipe(catchError(this.handleError));
  }

  saveEntity(entity: Entity) {
    HelpContentService.removeNulls(entity);

    return this.http.post<Entity>(properties.adminToolsAPIURL + 'entity/save', JSON.stringify(entity), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  updateEntity(entity: Entity) {
    HelpContentService.removeNulls(entity);

    return this.http.post<Entity>(properties.adminToolsAPIURL + 'entity/update', JSON.stringify(entity), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  deleteEntities(ids: string[]) {
    return this.http.post(properties.adminToolsAPIURL + 'entity/delete',JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  getCommunityEntities(pid: string) {
    return this.http.get<Array<Entity>>(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/'+pid+'/entities')
      .pipe(catchError(this.handleError));
  }

  toggleEntities(pid: string, ids: string[], status: boolean) {

    return this.http.post(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/'+pid+ '/entity/toggle?status='+ status.toString(),
      JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  // unused
  toggleEntityOfPage(pageId: string, entityId : string,status : boolean, helpContentUrl:string) {
    return this.http.post(helpContentUrl + 'page/'+pageId+'/entity/toggle?status='+ status.toString()+'&entityId='+entityId.toString(),
      CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  getAllDivIdsFull() {
    return this.http.get<Array<DivId>>(properties.adminToolsAPIURL + 'div/full')
      .pipe(catchError(this.handleError));
  }

  getDivIdsFullByPortal(page_id: string, pid: string) {
    let parameters: string = page_id ? "?&page="+page_id : "";

    return this.http.get<Array<DivId>>(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/'+pid + '/div/full'+parameters)
      .pipe(catchError(this.handleError));
  }

  // unused
  getDivId(divId: string, helpContentUrl:string) {
    return this.http.get<DivId>(helpContentUrl + 'div/'+divId)
      .pipe(catchError(this.handleError));
  }

  // unused
  getDivIdFull(divId: string, helpContentUrl:string, pid: string) {
    return this.http.get<DivId>(helpContentUrl + properties.adminToolsPortalType + "/" + pid + '/div/'+divId+"/full")
      .pipe(catchError(this.handleError));
  }

  updateDivId(divId: DivId) {
    HelpContentService.removeNulls(divId);

    return this.http.post<DivId>(properties.adminToolsAPIURL + 'div/update', JSON.stringify(divId), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  saveDivId(divId: DivId) {
    HelpContentService.removeNulls(divId);

    return this.http.post<DivId>(properties.adminToolsAPIURL + 'div/save', JSON.stringify(divId), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  deleteDivIds(ids : string[]) {
    return this.http.post(properties.adminToolsAPIURL + 'div/delete',JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  getPageIdsFromDivIds(pid: string) {
    return this.http.get<Array<string>>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + pid + '/div/pages')
      .pipe(catchError(this.handleError));
  }

  getCommunityDivHelpContents(pid: string, pageId:string = null) {
    return this.http.get<Array<DivHelpContent>>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + pid + (pageId ? '/' + pageId : '') + '/divhelpcontent')
      .pipe(catchError(this.handleError));
  }

  getDivHelpContent(id : string, pid: string) {
    return this.http.get<DivHelpContent>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + pid + '/divhelpcontent/' + id)
      .pipe(catchError(this.handleError));
  }

  insertOrUpdateDivHelpContent(divHelpContent: DivHelpContent, pid: string) {
    HelpContentService.removeNulls(divHelpContent);
    return this.http.post<DivHelpContent>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + pid + '/divhelpcontent/' + (divHelpContent._id ? 'update' : 'save'),
      JSON.stringify(divHelpContent), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }


  deleteDivHelpContents(ids : string[], pid: string) {
    return this.http.post(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + pid + '/divhelpcontent/delete',
      JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  toggleDivHelpContents(ids : string[], status : boolean, pid: string) {
    return this.http.post(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/' + pid + '/divhelpcontent/toggle?status='+ status.toString(),
      JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  // unused
  getPageHelpContents(helpContentUrl:string) {
    return this.http.get<Array<PageHelpContent>>(helpContentUrl + 'pagehelpcontent')
      .pipe(catchError(this.handleError));
  }


  getCommunityPageHelpContents(pid: string, pageId:string = null) {
    return this.http.get<Array<PageHelpContent>>(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/' + pid + (pageId ? '/' + pageId : '') + '/pagehelpcontent')
      .pipe(catchError(this.handleError));
  }
  countCommunityPageHelpContents(pid: string, classContents:boolean = false) {
    return this.http.get<Array<PageHelpContent>>(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/' + pid  + (classContents?"/divhelpcontent":"/pagehelpcontent")+'/page/count')
      .pipe(catchError(this.handleError));
  }
  getPageHelpContent(id : string, helpContentUrl:string, pid: string) {
    return this.http.get<PageHelpContent>(helpContentUrl + properties.adminToolsPortalType + '/' + pid + '/pagehelpcontent/' + id)
      .pipe(catchError(this.handleError));
  }

  savePageHelpContent(pageHelpContent: PageHelpContent, pid: string) {
    HelpContentService.removeNulls(pageHelpContent);

    return this.http.post<PageHelpContent>(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/' + pid + '/pagehelpcontent/' + (pageHelpContent._id ? 'update' : 'save'),
      JSON.stringify(pageHelpContent), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  // unused
  updatePageHelpContent(pageHelpContent: PageHelpContent, helpContentUrl:string, pid: string) {
    HelpContentService.removeNulls(pageHelpContent);

    return this.http.post<PageHelpContent>(helpContentUrl + properties.adminToolsPortalType + '/' + pid +'/pagehelpcontent/update',
      JSON.stringify(pageHelpContent), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  deletePageHelpContents(ids: string[], pid: string) {
    return this.http.post(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/' + pid + '/pagehelpcontent/delete',
      JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  togglePageHelpContents(ids : string[], status : boolean, pid: string) {
    return this.http.post(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/' + pid + '/pagehelpcontent/toggle?status='+ status.toString(),
      JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  // unused
  getCommunityPagesWithDivId(pid: string, helpContentUrl:string) {
    return this.http.get<Array<Page>>(helpContentUrl + properties.adminToolsPortalType + '/'+pid+'/pages?div=true')
      .pipe(catchError(this.handleError));
  }

  getCommunityPagesByRoute(pid: string, route: string) {
    return this.http.get<Array<Page>>(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/'+pid+'/pages'
      + (route ? '?page_route='+route : ''))
      .pipe(map(pages => (pages.length>0?pages[0]:null)), catchError(this.handleError));
  }

  getCommunityPagesByType(pid: string, type: string) {
    return this.http.get<Array<Page>>(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/'+pid+'/pages'
      + (type ? '?page_type='+type : ''))
      .pipe(catchError(this.handleError));
  }

  // unused
  getCommunityPagesWithPositions(pid: string, helpContentUrl:string) {
    return this.http.get<Array<Page>>(helpContentUrl + properties.adminToolsPortalType + '/'+pid+'/pages?with_positions=true')
      .pipe(catchError(this.handleError));
  }
  
  // TODO: double check with konstantina - there is no param that we are asking the community pages. without pid we get all portalTypes
  getAllPages(pid:string = null) {//with_positions:boolean=null) {
    // let parameters: string = "";
    // if(pid || with_positions == true || with_positions == false) {
    //   parameters = "?";
    //   if(pid) {
    //     parameters += "&pid="+pid;
    //   }
    //   if(with_positions == true || with_positions == false) {
    //     parameters += "&with_positions="+with_positions;
    //   }
    // }
    return this.http.get<Array<Page>>(properties.adminToolsAPIURL + 'page?' + (pid?"pid="+pid:""))
    //.map(res => <Array<Page>> res.json())
      .pipe(catchError(this.handleError));
  }

  getAllPagesFull() {
    return this.http.get<Array<Page>>(properties.adminToolsAPIURL + 'page/full')//+(pid?("?pid="+pid):""))
      .pipe(catchError(this.handleError));
  }

  getPageByPortal(pageId:string, pid: string) {
    return this.http.get<Page>(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/' + pid + '/page/'+pageId)
      .pipe(catchError(this.handleError));
  }

  getPageById(pageId:string) {
    return this.http.get<Page>(properties.adminToolsAPIURL +  'page/' + pageId)
      .pipe(catchError(this.handleError));
  }

  // unused
  getCommunityPageByRoute(route:string, helpContentUrl:string, pid: string) {
    return this.http.get<Page>(helpContentUrl + properties.adminToolsPortalType +'/' + pid + '/page/?page_route='+route)
      .pipe(catchError(this.handleError));
  }

  savePage(page: Page) {
    HelpContentService.removeNulls(page);

    return this.http.post<Page>(properties.adminToolsAPIURL + 'page/save', JSON.stringify(page), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  updatePage(page: Page) {
    HelpContentService.removeNulls(page);

    return this.http.post<Page>(properties.adminToolsAPIURL + 'page/update', JSON.stringify(page), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  togglePages(selectedPortalPid: string, ids : string[], status: boolean) {
    return this.http.post(properties.adminToolsAPIURL + properties.adminToolsPortalType + '/' + selectedPortalPid + '/page/toggle?status='+ status.toString(),
      JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  deletePages(ids : string[]) {
    return this.http.post(properties.adminToolsAPIURL + 'page/delete',JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

	// Menu Items

	getMenuItems(portalPid: string) {
		return this.http.get<Menu>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/full")
			.pipe(catchError(this.handleError));
	}

	saveMenuItem(menuItem: MenuItemExtended, portalPid: string) {
		HelpContentService.removeNulls(menuItem);

		return this.http.post<MenuItemExtended>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/save", JSON.stringify(menuItem), CustomOptions.getAuthOptionsWithBody())
			.pipe(catchError(this.handleError));
	}

	updateMenuItem(menuItem: MenuItemExtended, portalPid: string) {
		HelpContentService.removeNulls(menuItem);
		return this.http.post<MenuItemExtended>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/update", JSON.stringify(menuItem), CustomOptions.getAuthOptionsWithBody())
			.pipe(catchError(this.handleError));
	}

	deleteMenuItem(menuItemId: string, portalPid: string) {
		return this.http.post<MenuItem>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/delete", menuItemId, CustomOptions.getAuthOptionsWithBody())
			.pipe(catchError(this.handleError));
	}

	reorderMenuItems(menuItems, portalPid: string) {
		return this.http.post<Array<MenuItem>>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/reorder", menuItems, CustomOptions.getAuthOptionsWithBody())
			.pipe(catchError(this.handleError));
	}

	toggleMenu(status: boolean, isFeatured: boolean, portalPid: string) {
		return this.http.post<Menu>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/toggle?status=" + status + "&featured=" + isFeatured, {}, CustomOptions.getAuthOptionsWithBody())
			.pipe(catchError(this.handleError));
	}

  alignMenu(alignment: string, portalPid: string) {
		return this.http.post<Menu>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/align?alignment=" + alignment, {}, CustomOptions.getAuthOptionsWithBody())
			.pipe(catchError(this.handleError));
	}

  // unused
  getCommunities( helpContentUrl:string) {
    return this.http.get<Array<Portal>>(helpContentUrl + properties.adminToolsPortalType)
      .pipe(catchError(this.handleError));
  }

  // unused
  getCommunity(pid: string, helpContentUrl:string) {
    return this.http.get<Portal>(helpContentUrl + properties.adminToolsPortalType + '/'+pid)
      .pipe(catchError(this.handleError));
  }

  // unused
  getCommunitiesFull( helpContentUrl:string) {
    return this.http.get<Array<Portal>>(helpContentUrl + properties.adminToolsPortalType + '/full')
      .pipe(catchError(this.handleError));
  }

  getPortalsFull() {
    return this.http.get<Array<Portal>>(properties.adminToolsAPIURL +  'portal/full')
      .pipe(catchError(this.handleError));
  }

  // unused
  getCommunityFull(portal_pid: string, helpContentUrl:string) {
    return this.http.get<Portal>(helpContentUrl + properties.adminToolsPortalType + '/'+portal_pid+'/full')
      .pipe(catchError(this.handleError));
  }

  saveCommunity(portal: Portal) {
    HelpContentService.removeNulls(portal);

    return this.http.post<Portal>(properties.adminToolsAPIURL + portal.type + '/save', JSON.stringify(portal), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  updateCommunity(portal: Portal) {
    HelpContentService.removeNulls(portal);

    return this.http.post<Portal>(properties.adminToolsAPIURL + portal.type + '/update', JSON.stringify(portal),  CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  deleteCommunities(ids: string[], portalType:string) {
    return this.http.post(properties.adminToolsAPIURL + portalType + '/delete',JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    // in a real world app, we may send the error to some remote logging infrastructure
    // instead of just logging it to the console
    console.error(error);
    return throwError(error.error || 'Server error');
  }

  getCommunityStatistics(apiUrl: string, portalId: string): Observable<StatisticsSummary> {
    const url = `${apiUrl}communities/${portalId}`;
    //console.log(`getting statistics summary from: ${url}`);
    return this.http.get(url)
    //.map(res => <any>res.json())
      .pipe(map(res => res['statistics']));
  }

  getCommunityAdminStatisticsChoices(portalId: string): Observable<StatisticsDisplay> {
    const url = `${properties.adminToolsAPIURL}statistics/${portalId}`;
    //console.log(`getting admin choices for statistics from: ${url}`);
    return this.http.get<StatisticsDisplay>(url)
    //.map(stats => <StatisticsDisplay>stats.json())
      .pipe(catchError(this.handleError));
  }

  postCommunityAdminStatisticsChoices(portalId: string,
                                      entity: string,
                                      chartsOrNumbers: string,
                                      title: string,
                                      status: boolean,
                                      monitor: boolean): Observable<any> {
    const url = `${properties.adminToolsAPIURL}statistics/${portalId}/${entity}/${chartsOrNumbers}?status=${status.toString()}&monitor=${monitor.toString()}`;
    //console.log(`getting admin choices for statistics from: ${url}`);

    return this.http.post(url, title, CustomOptions.getAuthOptionsWithBody())
    //.map(stats => <any>stats.json())
      .pipe(catchError(this.handleError));
  }

  statisticsIsActiveToggle(id: string): Observable<boolean> {
    const url = properties.adminToolsAPIURL + 'statistics/' + encodeURIComponent(id) + '/toggle';
    return this.http.post<boolean>(url, {}, CustomOptions.getAuthOptionsWithBody()).pipe(catchError(this.handleError));
  }
}
