import {NgModule}            from '@angular/core';
import {CommonModule}        from '@angular/common';
import {FormsModule}         from '@angular/forms';

import {ErrorPageComponent}  from './errorPage.component';
import {RouterModule} from "@angular/router";
import { SEOServiceModule }           from '../sharedComponents/SEO/SEOService.module';

@NgModule({
  imports: [
    CommonModule, FormsModule, RouterModule, SEOServiceModule
  ],
  providers: [],
  declarations: [
    ErrorPageComponent
  ],
  exports: [
    ErrorPageComponent
  ]
})
export class ErrorModule { }
