import {NgModule} from '@angular/core';
import {InputComponent} from "./input.component";
import {SharedModule} from "../../shared/shared.module";
import {IconsModule} from "../../utils/icons/icons.module";
import {SafeHtmlPipeModule} from "../../utils/pipes/safeHTMLPipe.module";
import {ClickModule} from "../../utils/click/click.module";
import {MobileDropdownModule} from "../../utils/mobile-dropdown/mobile-dropdown.module";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";

@NgModule({
  imports: [
    SharedModule,
    IconsModule,
    SafeHtmlPipeModule,
    ClickModule,
    MobileDropdownModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule
  ],
  exports: [
    InputComponent
  ],
  declarations: [
    InputComponent
  ]
})
export class InputModule {
}
