export class Notification {
  actionType: string;
  services: string[];
  user: string;
  name: string;
  surname: string;
  title?: string;
  preview: string;
  message: string;
  stakeholder: string;
  stakeholderType: string;
  entity: string;
  entityType: string;
  date: Date;
  groups: string[];
  read: boolean  = false;
  _id: string;
  
  
  constructor(actionType: string, services: string[], message: string, entityType: string) {
    this.actionType = actionType;
    this.services = services;
    this.message = message;
    this.entityType = entityType;
  }
}

export interface NotificationUser {
  _id: string,
  read: string[]
}
