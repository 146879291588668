import {NgModule} from "@angular/core";
import {NotifyFormComponent} from "./notify-form.component";
import {CommonModule} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {InputModule} from "../../sharedComponents/input/input.module";
import {NotificationUserModule} from "../notification-user/notification-user.module";

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, InputModule, NotificationUserModule],
  declarations: [NotifyFormComponent],
  exports: [NotifyFormComponent]
})
export class NotifyFormModule {

}
