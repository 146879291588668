import { Injectable } from "@angular/core";
import { JsonldDocument, Identifier, Person, License, Citation, Dataset, Organization } from "../model/jsonld-document";
import * as _ from "lodash";

@Injectable()
export class JsonldDocumentSerializerService {
	constructor() { }
	serializeOrganization(doc: Organization): any {
		const buffer = {};
		buffer["@type"] = "Organization";
		this.serializeDocument(doc, buffer);
		if(doc.areaServed){
			buffer["areaServed"] = doc.areaServed;
		}
		if(doc.legalName){
			buffer["legalName"] = doc.legalName;
		}
		if(doc.funder ){
			buffer["funder"] = this.serializeOrganization(doc.funder );
		}
		return buffer;
	}


	serializeDocument(doc:JsonldDocument, buffer):any{
		buffer["@context"] = "http://schema.org";
		buffer["@id"] = doc.id;
		this.serializeName(doc, buffer);
		this.serializeDescription(doc, buffer);
		this.serializeIdentifier(doc, buffer);
		this.serializeURL(doc, buffer);
		this.serializeSameAs(doc, buffer);
	}
	serializeDataset(doc: Dataset): any {
		var buffer = {};
		buffer["@context"] = "http://schema.org";
		buffer["@type"] = "Dataset";
		this.serializeDocument(doc, buffer);
		if(doc.headline){
			buffer["headline"] = doc.headline;
		}
		if(doc.alternativeHeadline){
			buffer["alternativeHeadline"] = doc.alternativeHeadline;
		}
		if(doc.issn){
			buffer["issn"] = doc.issn;
		}

		if (doc.creator && doc.creator.length == 1) {
			buffer["creator"] = this.buildCreator(doc.creator[0]);
		}
		else if (doc.creator && doc.creator.length > 1) {
			const array = new Array<any>();
			for (var i = 0; i < doc.creator.length; i += 1) {
				array.push(this.buildCreator(doc.creator[i]));
			}
			buffer["creator"] = array;
		}

		if (doc.dateCreated && doc.dateCreated.length == 1) {
			buffer["dateCreated"] = doc.dateCreated[0];
		}
		else if (doc.dateCreated && doc.dateCreated.length > 1) {
			buffer["dateCreated"] = doc.dateCreated;
		}

		if (doc.dateModified && doc.dateModified.length == 1) {
			buffer["dateModified"] = doc.dateModified[0];
		}
		else if (doc.dateModified && doc.dateModified.length > 1) {
			buffer["dateModified"] = doc.dateModified;
		}

		if (doc.license && doc.license.length == 1) {
			buffer["license"] = this.buildLicense(doc.license[0]);
		}
		else if (doc.license && doc.license.length > 1) {
			const array = new Array<any>();
			for (var i = 0; i < doc.license.length; i += 1) {
				array.push(this.buildLicense(doc.license[i]));
			}
			buffer["license"] = array;
		}

		if (doc.keyword && doc.keyword.length == 1) {
			buffer["keywords"] = doc.keyword[0];
		}
		else if (doc.keyword && doc.keyword.length > 1) {
			buffer["keywords"] = _.join(doc.keyword, ", ");
		}
		if(doc.isPartOf && doc.isPartOf.length > 0){
			buffer["isPartOf"] = doc.isPartOf;
		}
		if (doc.citation && doc.citation.length == 1) {
			buffer["citation"] = this.buildCitation(doc.citation[0]);
		}
		else if (doc.citation && doc.citation.length > 1) {
			const array = new Array<any>();
			for (var i = 0; i < doc.citation.length; i += 1) {
				array.push(this.buildCitation(doc.citation[i]));
			}
			buffer["citation"] = array;
		}
		return buffer;
		// return JSON.stringify(buffer, (key, value) => {
		// 	if (value !== null) return value
		// }, 4);
	}

	serializeName(doc, buffer){
 		if (doc.title && doc.title.length == 1) {
			buffer["name"] = doc.title[0];
		}
		else if (doc.title && doc.title.length > 1) {
			buffer["name"] = doc.title;
		}
	}

	serializeDescription(doc, buffer){
    if(doc.description) {
      buffer["description"] = doc.description;
    }
	}
	serializeIdentifier(doc, buffer){
		if (doc.identifier && doc.identifier.length == 1) {
			buffer["identifier"] = this.buildIdentifier(doc.identifier[0]);
		}
		else if (doc.identifier && doc.identifier.length > 1) {
			const array = new Array<any>();
			for (var i = 0; i < doc.identifier.length; i += 1) {
				array.push(this.buildIdentifier(doc.identifier[i]));
			}
			buffer["identifier"] = array;
		}
	}
	serializeURL(doc, buffer){
		if (doc.url && doc.url.length == 1) {
			buffer["URL"] = doc.url[0];
		}
		else if (doc.url && doc.url.length > 1) {
			buffer["URL"] = doc.url;
		}
	}
	serializeSameAs(doc, buffer){
		if (doc.sameAs && doc.sameAs.length == 1) {
		buffer["sameAs"] = doc.sameAs[0];
		}
		else if (doc.sameAs && doc.sameAs.length > 1) {
			buffer["sameAs"] = doc.sameAs;
		}
	}

	buildIdentifier(item: Identifier): any {
		return {
			"@type": "PropertyValue",
			"propertyID": item.schema,
			"value": item.id
		};
	}

	buildCreator(item: Person): any {
		var person ={};
		person["@type"] = "Person";
		if(item.givenName){
			person["givenName"] = item.givenName;
		}
		if(item.familyName){
			person["familyName"] = item.familyName;
		}
		person["name"] = item.name;
		return person;
		// return {
		// 	"@type": "Person",
		// 	"givenName": item.givenName,
		// 	"familyName": item.familyName,
		// 	"name": item.name
		// };
	}

	buildLicense(item: License): any {
		const licenseBuffer = {
			"@type": "CreativeWork"
		};

		if (item.title && item.title.length == 1) {
			licenseBuffer["name"] = item.title[0];
		}
		else if (item.title && item.title.length > 1) {
			licenseBuffer["name"] = item.title;
		}

		if (item.identifier && item.identifier.length == 1) {
			licenseBuffer["identifier"] = this.buildIdentifier(item.identifier[0]);
		}
		else if (item.identifier && item.identifier.length > 1) {
			const array = new Array<any>();
			for (var i = 0; i < item.identifier.length; i += 1) {
				array.push(this.buildIdentifier(item.identifier[i]));
			}
			licenseBuffer["identifier"] = array;
		}

		return licenseBuffer;
	}

	buildCitation(item: Citation): any {
		const citationBuffer = {
			"@type": "CreativeWork"
		};

		if (item.title && item.title.length == 1) {
			citationBuffer["name"] = item.title[0];
		}
		else if (item.title && item.title.length > 1) {
			citationBuffer["name"] = item.title;
		}

		if (item.identifier && item.identifier.length == 1) {
			citationBuffer["identifier"] = this.buildIdentifier(item.identifier[0]);
		}
		else if (item.identifier && item.identifier.length > 1) {
			const array = new Array<any>();
			for (var i = 0; i < item.identifier.length; i += 1) {
				array.push(this.buildIdentifier(item.identifier[i]));
			}
			citationBuffer["identifier"] = array;
		}

		return citationBuffer;
	}

}
