import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NotificationUserComponent} from "./notification-user.component";

@NgModule({
  imports: [CommonModule],
  declarations: [NotificationUserComponent],
  exports: [NotificationUserComponent]
})
export class NotificationUserModule {

}
