<h1 *ngIf="formTitle" class="uk-margin-auto">{{formTitle}}</h1>
<ng-content select="[page-title]"></ng-content>
<ng-content select="[page-description]"></ng-content>
<div class="uk-child-width-1-1 uk-grid uk-grid-margin-large" [attr.uk-scrollspy-class]="scrollspy?'':null"
     [class.uk-grid-column-small]="smallForm" [class.uk-child-width-1-2@s]="!smallForm" [formGroup]="contactForm" uk-grid>
  <div *ngIf="contactForm.get('name')" input placeholder="Name"
       [inputClass]="'flat' + (smallForm?' x-small':'')" [formInput]="contactForm.get('name')"></div>
  <div *ngIf="contactForm.get('surname')" input placeholder="Surname"
       [inputClass]="'flat' + (smallForm?' x-small':'')" [formInput]="contactForm.get('surname')"></div>
  <div *ngIf="contactForm.get('email')" input placeholder="Email"
       [inputClass]="'flat' + (smallForm?' x-small':'')" [formInput]="contactForm.get('email')"></div>
  <div *ngIf="contactForm.get('job')" input placeholder="Job Title"
       [inputClass]="'flat' + (smallForm?' x-small':'')" [formInput]="contactForm.get('job')"></div>
  <div *ngIf="contactForm.get('affiliation')" input placeholder="Affiliation"
       [inputClass]="'flat' + (smallForm?' x-small':'')" [formInput]="contactForm.get('affiliation')"></div>
  <div *ngIf="contactForm.get('community')" input placeholder="Research Community or Infrastructure"
       [inputClass]="'flat' + (smallForm?' x-small':'')" [formInput]="contactForm.get('community')"></div>
  <div *ngIf="contactForm.get('organization')" input placeholder="Organization"
       [inputClass]="'flat' + (smallForm?' x-small':'')" [formInput]="contactForm.get('organization')"></div>
  <div *ngIf="contactForm.get('organizationType')" input type="select" placeholder="Organization Type"
       [inputClass]="'flat' + (smallForm?' x-small':'')" [formInput]="contactForm.get('organizationType')" [options]="organizationTypes"></div>
  <div *ngIf="contactForm.get('subject')" class="uk-width-1-1" input placeholder="Subject"
       [inputClass]="'flat' + (smallForm?' x-small':'')" [formInput]="contactForm.get('subject')"></div>
  <div *ngIf="contactForm.get('message')" class="uk-width-1-1" input type="textarea" rows="4" placeholder="Comments"
       [inputClass]="'flat' + (smallForm?' x-small':'')" [formInput]="contactForm.get('message')"></div>
  <div *ngIf="contactForm.get('description')" class="uk-width-1-1" input type="textarea" rows="4" placeholder="Description"
       [inputClass]="'flat' + (smallForm?' x-small':'')" [formInput]="contactForm.get('description')"></div>
  <div *ngIf="contactForm.get('recaptcha')" class="uk-flex uk-flex-center" [class.uk-width-1-1]="smallForm" [class.uk-flex-last@s]="!smallForm">
    <re-captcha #captchaElem (resolved)="handleRecaptcha($event)" [siteKey]="properties.reCaptchaSiteKey"></re-captcha>
  </div>
  <div class="uk-flex uk-flex-center" [class.uk-width-1-1]="smallForm" [class.uk-flex-column]="!smallForm">
    <div class="uk-flex uk-flex-center" [class.uk-flex-left@s]="!smallForm">
        <button class="uk-button uk-text-uppercase uk-flex uk-flex-middle"
                [class.uk-disabled]="contactForm.invalid || sending"
                [class.uk-button-primary]="!buttonClass && contactForm.valid"
                [ngClass]="(contactForm.valid?buttonClass:'')"
                (click)="send()">
          {{sendButton}}
          <span *ngIf="sending" class="uk-icon uk-margin-small-left"><loading [top_margin]="false" [size]="'small'"
                                                                              [color]="null"></loading></span>
        </button>
      </div>
    </div>
</div>
