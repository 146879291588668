import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MenuItem} from "./openaireLibrary/sharedComponents/menu";
import {Header} from "./openaireLibrary/sharedComponents/navigationBar.component";
import {ConfigurationService} from "./openaireLibrary/utils/configuration/configuration.service";
import {Irish} from "./shared/irish";
import {CustomFilterService} from "./openaireLibrary/shared/customFilter.service";
import {UserManagementService} from "./openaireLibrary/services/user-management.service";
import {Session, User} from "./openaireLibrary/login/utils/helper.class";
import {SmoothScroll} from "./openaireLibrary/utils/smooth-scroll";
import {LayoutService} from "./openaireLibrary/dashboard/sharedComponents/sidebar/layout.service";
import {StakeholderUtils} from "./openaireLibrary/monitor-admin/utils/indicator-utils";
import {ResearcherBaseComponent} from "./shared/researcher-base.component";
import {UserProfileService} from "./openaireLibrary/services/userProfile.service";
import {SearchOrcidService} from "./openaireLibrary/claims/claim-utils/service/searchOrcid.service";
import {SearchResearchResultsService} from "./openaireLibrary/services/searchResearchResults.service";
import {Identifier} from "./openaireLibrary/utils/string-utils.class";
import {ConnectHelper} from "./openaireLibrary/connect/connectHelper";
import {HelperService} from "./openaireLibrary/utils/helper/helper.service";

@Component({
  selector: 'app-root',
  template: `
      <div class="uk-background-default">
          <div *ngIf="divContents && divContents['banner']" class="uk-tile uk-tile-default uk-padding uk-border-bottom">
              <helper [texts]="divContents['banner']"></helper>
          </div>
          <navbar *ngIf="hasHeader" portal="irish_monitor" [menuItems]="menuItems" [showMenu]="true"
                  [header]="header" [user]="user" [userMenuItems]="userMenuItems"></navbar>
          <div *ngIf="loading">
              <loading [full]="true"></loading>
          </div>
          <div [class.uk-hidden]="loading" [ngClass]="rootClass">
              <div id="modal-container"></div>
              <main>
                  <router-outlet></router-outlet>
              </main>
              <help-pop-up *ngIf="hasHelpPopUp && !isBottomIntersecting"></help-pop-up>
          </div>
      </div>
      <cookie-law *ngIf="isClient" position="bottom" cookieName="cookieLawSeen-NOAMIreland">
          National Open Access Monitor - Ireland, uses cookies in order to function properly.<br>
          Cookies are small pieces of data that websites store in your browser to allow us to give you the best browsing
          experience possible.
          By using the National Open Access Monitor - Ireland portal you accept our use of cookies.
      </cookie-law>
      <footer id="bottom" class="uk-text-center uk-text-small uk-border-top">
          <div class="uk-tile-default">
              <div class="uk-section uk-container">
                  <div class="uk-flex uk-flex-middle uk-flex-center uk-margin-medium-bottom">
                      <img src="assets/logo/small.svg" alt="National Open Access Monitor Ireland" style="width: 40px;"/>
                      <div class="uk-margin-small-left">
                          Platform in <span class="uk-text-bold">Pilot</span> Phase
                      </div>
                  </div>
                  <div class="uk-margin-medium-bottom">
                    <a href="https://graph.openaire.eu" target="_blank" class="uk-width-1-1 uk-width-auto@m">
                      <img src="assets/common-assets/openaire-badge-1.png" alt="Powered by OpenAIRE graph" style="height: 17px;"></a>
                  </div>
                  <div class="uk-margin-bottom  uk-container uk-container-small">
                      The National Open Access Monitor, Ireland is delivered as part of the National Open Access Monitor
                      Project,
                      managed by the <a href="https://irel.ie/" target="_blank">Irish Research eLibrary (IReL)</a> at
                      Maynooth University.
                      The project has received funding from Ireland's <a href="https://dri.ie/norf/" target="_blank">National
                      Open Research Forum (NORF)</a>
                      under the Open Research Fund Call.
                  </div>
                  <div class="uk-margin-bottom">
                      <img title="Rialtas" src="assets/Rialtas_Mark_Std_Colour.png"
                           class=" uk-padding-xsmall uk-padding-remove-vertical" style="height: 50px;">
                      <img title="HEA" src="assets/hea-logo.png" class=" uk-padding-xsmall uk-padding-remove-vertical"
                           style="height: 30px;">
                      <img title="Maynooth University" src="assets/Maynooth University Logo.png"
                           class=" uk-padding-small uk-padding-remove-vertical" style="height: 30px;">
                    <img  title="NORF" src="assets/norf.jpg" class=" uk-padding-small uk-padding-remove-vertical" style="height: 30px;">
                    <img title="IRel" src="assets/IReL logo.png" class=" uk-padding-small uk-padding-remove-vertical"
                           style="height: 30px;">
                      <img title="OpenAIRE" src="assets/common-assets/common/Logo_Horizontal.png"
                           class=" uk-padding-small uk-padding-remove-vertical" style="height: 30px;">
                  </div>
              </div>
          </div>
      </footer>
  `
})
export class AppComponent extends ResearcherBaseComponent implements OnInit, AfterViewInit {
  loading: boolean = false;
  hasHeader: boolean = true;
  rootClass: string;
  header: Header = {
    route: "/",
    title: "Noami",
    logoUrl: 'assets/logo/main.svg',
    logoSmallUrl: 'assets/logo/small.svg',
    position: 'left',
    badge: true
  };
  menuItems: MenuItem[] = [];
  userMenuItems: MenuItem[] = [];
  irish: Irish = new Irish();
  user: User;
  isClient: boolean = false;
  stakeholderUtils: StakeholderUtils = new StakeholderUtils();
  windowWidth: number;
  compactMenuItems: boolean = false;
  hasHelpPopUp: boolean = true;
  isBottomIntersecting: boolean = false;
  divContents: any;

  constructor(protected _route: ActivatedRoute,
              protected _router: Router,
              protected cdr: ChangeDetectorRef,
              private layoutService: LayoutService,
              private smoothScroll: SmoothScroll,
              private configurationService: ConfigurationService,
              private customFilterService: CustomFilterService,
              private userManagementService: UserManagementService,
              private userProfileService: UserProfileService,
              private helper: HelperService,
              protected _searchOrcidService: SearchOrcidService,
              protected _searchResearchResultsService: SearchResearchResultsService
  ) {
    super();
    this.setProperties(this.properties.adminToolsCommunity, this.properties.adminToolsPortalType);
    this.userManagementService.fixRedirectURL = this.properties.afterLoginRedirectLink;
    this.userManagementService.allowDoubleRedirectToFixAndCurrentPage = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getWindowWidth();
    this.buildMenuItems();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.customFilterService.clearSubscriptions();
    this.configurationService.clearSubscriptions();
    this.smoothScroll.clearSubscriptions();
    this.userProfileService.clearSubscriptions();
  }

  ngOnInit() {
    this.isClient = typeof document !== 'undefined';
    this.subscriptions.push(this.layoutService.hasHelpPopUp.subscribe(value => {
      this.hasHelpPopUp = value;
      this.cdr.detectChanges();
    }));
    this.subscriptions.push(this.layoutService.isBottomIntersecting.subscribe(isBottomIntersecting => {
      this.isBottomIntersecting = isBottomIntersecting;
      this.cdr.detectChanges();
    }));
    if(this.isClient) {
      this.getWindowWidth();
    }
    if (this.properties.environment == 'beta') {
      this.header.environmentBadge = {
        asset: 'assets/badge/sandbox.svg'
      }
    } else {
      this.header.environmentBadge = {
        asset: 'assets/badge/pilot.svg',
        routerLink: '/how-it-works/about'
      }
    }
    this.subscriptions.push(this.userManagementService.getUserInfo().subscribe(user => {
      this.user = user;
      if (user) {
        this.userProfileService.initUserProfile()
      } else {
        this.userProfileService.setUserProfile(null);
      }
      this.initialize();
    }));
    this.getDivContents();
  }

  ngAfterViewInit() {
    this.subscriptions.push(this.layoutService.rootClass.subscribe(rootClass => {
      this.loading = true;
      this.cdr.detectChanges();
      if (rootClass && this.isClient) {
        let link = <HTMLLinkElement>document.getElementById('theme');
        let append = false;
        if (!link) {
          link = document.createElement('link');
          link.rel = 'stylesheet';
          link.id = 'theme';
          append = true;
        }
        link.href = rootClass + '.css';
        link.onerror = (error) => console.log(error);
        if (append) {
          document.head.appendChild(link);
        }
      }
      this.rootClass = rootClass;
      this.cdr.detectChanges();
      setTimeout(() => {
        this.loading = false;
        this.cdr.detectChanges();
      }, 500)
    }));
  }

  initialize() {
    this.buildMenuItems();
  }

  buildMenuItems() {
    if(this.compactMenuItems) {
      this.menuItems = [
        new MenuItem("home", "Home", "", "/", false, [], null, {}, null, null, null),
        new MenuItem("monitors", "Monitors", "", "", false, [], null, {}, null, null, null, "/monitors", "_blank", "internal", false,
              [
                new MenuItem("national", this.stakeholderUtils.entities.country, "", "/national", false, [], null, {}, null, null, null),
                new MenuItem("rpo", this.stakeholderUtils.entities.organization, "", "/rpo", false, [], null, {}, null, null, null, "/rpo"),
                new MenuItem("rfo", this.stakeholderUtils.entities.funder, "", "/rfo", false, [], null, {}, null, null, null, "/rfo"),
                new MenuItem("researcher", "Researcher", "", "/researcher", false, [], null, {}, null, null, null, "/researcher"),
                new MenuItem("repository", "Repository", "", "/repository", false, [], null, {}, null, null, null, "/repository")
              ]
          )
      ];
    } else {
      this.menuItems = [
        new MenuItem("home", "Home", "", "/", false, [], null, {}, null, null, null),
        new MenuItem("national", this.stakeholderUtils.entities.country + ' ' + this.stakeholderUtils.entities.stakeholder, "", "/national", false, [], null, {}, null, null, null),
        new MenuItem("rpo", this.stakeholderUtils.entities.organization + ' ' + this.stakeholderUtils.entities.stakeholders, "", "/rpo", false, [], null, {}, null, null, null, "/rpo"),
        new MenuItem("rfo", this.stakeholderUtils.entities.funder + ' ' + this.stakeholderUtils.entities.stakeholders, "", "/rfo", false, [], null, {}, null, null, null, "/rfo"),
        new MenuItem("researcher", "Researcher Monitors", "", "/researcher", false, [], null, {}, null, null, null, "/researcher"),
        new MenuItem("repository", "Repository Monitors", "", "/repository", false, [], null, {}, null, null, null, "/repository")
      ];
    }
    if (this.properties.environment != "beta") {
      this.menuItems.push(
          new MenuItem("resources", "Resources & Help", "", "", false, [], null, {}, null, null, null, "/resources", "_blank", "internal", false,
              [
                new MenuItem("how-it-works", "How it works?", "", "", false, [], null, {}, null, null, null, null, "_self"),
                new MenuItem("about", "About", "", "/how-it-works/about", false, [], null, {}, null, null, null, "/how-it-works/about"),
                new MenuItem("the-5-monitors", "The 5 Monitors", "", "/how-it-works/the-5-monitors", false, [], null, {}, null, null, null, "/the-5-monitors"),
                new MenuItem("user-actions", "User Actions", "", "/how-it-works/user-actions", false, [], null, {}, null, null, null, "/how-it-works/user-actions"),
                new MenuItem("methodology", "Methodology", "", "", false, [], null, {}, null, null, null, null, "_self"),
                new MenuItem("methodological-approach", "Methodological Approach", "", "/methodology/methodological-approach", false, [], null, {}, null, null, null, "/methodological-approach"),
                new MenuItem("terminology-construction", "Terminology & Construction", "", "/methodology/terminology", false, [], null, {}, null, null, null, "/methodology/terminology"),
                new MenuItem("help", "Help", "", "", false, [], null, {}, null, null, null, null, "_self"),
                new MenuItem("stats-logs", "Web Statistics & Activity Logs", "", "/stats-logs", false, [], null, {}, null, null, null, "/stats-logs"),
                new MenuItem("engagement-training", "Engagement & Training", "", "/engagement-training", false, [], null, {}, null, null, null, "/engagement-training"),
                new MenuItem("support", "Support", "", "/support", false, [], null, {}, null, null, null, "/support"),
                new MenuItem("privacy-policy", "Privacy Policy", "/assets/privacy-policy.pdf", "", false, [], null, {}, null, null, null, null, "_blank"),
              ]
          )
      )
    }
    if (this.user) {
      this.userMenuItems = [];
      if (this.user.orcid) {
        this.authorId = Identifier.getRawORCID(this.user.orcid);
        this.results = {
          success: (res: string | number) => {
            if (+res > 0) {
              this.userMenuItems.push(new MenuItem("my-researcher", "My Researcher Monitor", "", "/researcher/" + this.authorId, false, [], null, {}, null, null, null, null));
              this.staticUserItems();
            } else {
              this.staticUserItems();
            }
          }
        }
        this.search();
      } else {
        this.staticUserItems();
      }
    }
  }

  staticUserItems() {
    if(this.properties.environment != "beta"){
      this.userMenuItems.push(new MenuItem("myorcidlinks", "My Orcid links", "", "/my-orcid-links", false, [], null, {}, null, null, null, null));
    }
    this.userMenuItems.push(
        new MenuItem("myclaims", "My links", "", "/participate/myclaims", false, [], null, {}, null, null, null, null),
        new MenuItem("claims", "Link", "", "/participate/claim", false, [], null, {}, null, null, null, null),
        new MenuItem("upload-dois", "Upload DOIs", "", "/upload-dois", false, [], null, {}, null, null, null, null)
    );
    if (this.isAdmin) {
      this.userMenuItems.push(new MenuItem("stakeholders", "Manage profiles", "", "/admin", false, [], [], {}, {name: 'settings'}));
    }
    if (Session.isPortalAdministrator(this.user)) {
      this.userMenuItems.push(new MenuItem("adminOptions", "Super Admin options", "", "/admin/admin-tools/portals", false, [], [], {}, null, null, "uk-visible@m"));
    }
    if (Session.isPortalAdministrator(this.user) || Session.isCurator('country', this.user)) {
      this.userMenuItems.push(new MenuItem("irishOptions", "Irish options", "", "/admin/irish/admin-tools/pages", false, [], [], {}, null, null, "uk-visible@m"));
    }
  }

  private getDivContents() {
    this.subscriptions.push(this.helper.getDivHelpContents(this.properties, 'irish', '/').subscribe(contents => {
      this.divContents = contents;
    }));
  }

  setProperties(id, type = null) {
    this.properties.adminToolsCommunity = id;
    if (type) {
      this.properties.adminToolsPortalType = type;
    } else {
      ConnectHelper.setPortalTypeFromPid(id);
    }
    this.configurationService.initPortal(this.properties, this.properties.adminToolsCommunity);
  }

  public get isAdmin() {
    return Session.isPortalAdministrator(this.user) || Session.isMonitorCurator(this.user) || Session.isKindOfMonitorManager(this.user);
  }

  getWindowWidth() {
    this.windowWidth = window.innerWidth;
    let mBreakpoint = Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--uk-breakpoint-m'));
    let xlBreakpoint = Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--uk-breakpoint-xl'));
    this.compactMenuItems = this.windowWidth > mBreakpoint && this.windowWidth <= xlBreakpoint;
  }
}
