import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {EnvProperties} from "../utils/properties/env-properties";
import {CustomOptions} from "./servicesUtils/customOptions.class";
import {AdvancedAsyncSubject} from "../utils/AdvancedAsyncSubject";
import {properties} from "../../../environments/environment";
import {map} from "rxjs/operators";

export class UserProfile{
  id:string;
  aaiId:string;
  consent:boolean = false;
  constructor(consent:boolean) {
    this.consent = consent;
  }
}

@Injectable({
  providedIn: "root"
})
export class UserProfileService {
  private subscription;
  private readonly userProfileSubject: AdvancedAsyncSubject<UserProfile> = new AdvancedAsyncSubject<UserProfile>();
  constructor(private http: HttpClient) {
  }

  clearSubscriptions() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

  public get user(): UserProfile {
    return this.userProfileSubject.getValue();
  }

  public getUserProfile(): Observable<UserProfile> {
    return this.userProfileSubject.asObservable();
  }

  public setUserProfile(userProfile:UserProfile) {
    this.userProfileSubject.next(userProfile?userProfile:new UserProfile(false));
  }
  public initUserProfile(resolve: Function = null) {

    this.subscription = this.http.get<UserProfile>(properties.monitorServiceAPIURL + 'user', CustomOptions.registryOptions()).pipe(map(userProfile => {
      return userProfile;
    })).subscribe(user => {
      this.userProfileSubject.next(user);
      if (resolve) {
        resolve();
      }
    }, error => {
      this.userProfileSubject.next(new UserProfile(false));
      if (resolve) {
        resolve();
      }
    });
  }

  saveConsentInUserProfile(properties:EnvProperties): Observable<UserProfile> {
    return this.http.post<UserProfile>(properties.monitorServiceAPIURL + 'user/save', new UserProfile(true), CustomOptions.registryOptions(),);
  }
  undoConsentInUserProfile(properties:EnvProperties): Observable<UserProfile> {
    return this.http.post<UserProfile>(properties.monitorServiceAPIURL + 'user/save', new UserProfile(false), CustomOptions.registryOptions(),);
  }

}
