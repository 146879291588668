
import { Filter, Value} from '../../searchPages/searchUtils/searchHelperClasses.class';
import { SearchFields} from '../../utils/properties/searchFields';


export class RefineResultsUtils {


   public static parse (data, fields:string[], entityType:string, usedBy: string="search", staticFilter: boolean = false):Filter[] {
         // var data = this.json.refineReuslts;

         var searchFields:SearchFields = new SearchFields();
         var filters:Filter[] = [];
         if(data && Object.keys(data).length > 0 && fields){
           for(let j=0; j<fields.length; j++) {

             var filter:Filter = new Filter();
             let fieldInfo =  searchFields.getField(fields[j],entityType);
             filter.title = fieldInfo?fieldInfo.name:"UNDEFINED";
             filter.filterId = fields[j];
             filter.originalFilterId = fields[j];
             filter.filterType = fieldInfo?fieldInfo.filterType:"checkbox";
             filter.type = fieldInfo?fieldInfo.type:"keyword";
             filter.filterOperator = searchFields.getFieldOperator(fields[j]);
             filter.radioValue = "";
             //console.info("filter.title: "+filter.title+" filter.filterType: "+filter.filterType);

             let field = data[fields[j]];
             if(field){
               filter.countUnfilteredValues = field.length;
               for(let i=0; i<field.length; i++) {
                 var value:Value = new Value();
                 value.name = field[i].name;
                 value.name =this.removePartAfterCharacters(value,"||");
                 value.number = field[i].count;
                 value.id = field[i].id;
                 if(RefineResultsUtils.includeValue(value)){
                   filter.values.push(value);
                 }


             }
               if(staticFilter) {
                 filter.countAllValues = filter.values.length;
               }
               // filter.values.sort(function(a, b){return b.number - a.number})

               filters.push(filter);
           }
       }
     }
       return filters;
   }
   private static includeValue (field):boolean {
     // if( !field || !field.name || field.name == "unidentified" || field.name == "Undetermined" ){
     if( field && field.name && field.name != "unidentified" && field.name != "Undetermined"
          && !field.name.toLowerCase().includes('unknown') && !field.name.toLowerCase().includes('not available')
          && field.name.toLowerCase() != "null") {
       return true;

     }
     return false;
   }
   private static removePartAfterCharacters(field, characters):string {
     if( field.name.indexOf(characters) !=-1){
       return field.name.split(characters)[0];

     }
     return field.name;
   }
   public static inParenthesisThePartAfterCharacters(field, characters):string {
     if( field.name.indexOf(characters) !=-1){
       return field.name.split(characters)[0]+ (field.name.split(characters)[1]?(" ("+field.name.split(characters)[1]+")"):"");

     }
     return field.name;
   }
  public static keepPartAfterCharacters(name, characters):string {
    if( name.indexOf(characters) !=-1){
      return name.split(characters)[1];
    }
    return name;
  }
  }
