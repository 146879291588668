/*  common components of modal components */
import {NgModule} from '@angular/core';
import {Open} from './open.component';

@NgModule({
  imports: [],
  declarations: [
    Open
  ],
  exports: [
    Open
  ]
})
export class ModalModule {
}
