import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {AlertModal} from "../../openaireLibrary/utils/modal/alert";

declare var UIkit;

@Component({
  selector: 'help-pop-up',
  templateUrl: 'help-pop-up.component.html',
  styleUrls: ['help-pop-up.component.less']
})
export class HelpPopUpComponent implements OnInit {
  private subscriptions: any[] = [];
  isClient: boolean = false;
  helpPopUpClicked: boolean = false;
  showDrop: boolean = false;
  @ViewChild('drop') drop: ElementRef;
  @ViewChild('guideModal', { static: true }) guideModal: AlertModal;

  ngOnInit() {
    this.isClient = typeof document !== 'undefined';
    if(this.isClient) {
      this.subscriptions.push(UIkit.util.on(document, 'show', '#pop-up', (event) => {
        this.showDrop = true;
      }));
      this.subscriptions.push(UIkit.util.on(document, 'hide', '#pop-up', (event) => {
        this.showDrop = false;
      }));
    }
  }

  openGuideModal() {
    this.guideModal.alertMessage = false;
    this.guideModal.alertHeader = false;
    this.guideModal.alertFooter = false;
    this.guideModal.cancelButton = false;
    this.guideModal.okButton = false;
    this.guideModal.open();
  }
}