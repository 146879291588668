<schema2jsonld *ngIf="url" [URL]="url" [name]="title" type="other" [description]="description"></schema2jsonld>
<div *ngIf="page">
  <div class="uk-section uk-container uk-container-large uk-flex uk-flex-center"
    uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-slide-bottom-medium; delay: 200">
    <div class="uk-padding-small uk-width-1-2@l uk-width-2-3@m uk-width-1-1">
      <contact-us [sending]="sending" [scrollspy]="true"
                  [contactForm]="contactForm" (sendEmitter)="send($event)">
        <h1 page-title class="uk-visible@m uk-margin-auto uk-text-center" uk-scrollspy-class>
          Contact us<span class="uk-text-primary">.</span>
        </h1>
		<h1 page-title class="uk-hidden@m uk-heading-large uk-margin-auto uk-text-center" uk-scrollspy-class>
          Contact us<span class="uk-text-primary">.</span>
        </h1>
      </contact-us>
    </div>
  </div>
</div>
<modal-alert #modal (alertOutput)="goToHome()">
  <div class="uk-padding-small uk-padding-remove-horizontal">
    Our team will respond to your submission soon.<br>
  </div>
</modal-alert>
<ng-container *ngIf="!page">
  <a class="uk-link-reset" (click)="openFsModal()">
    <div class="uk-flex uk-flex-top">
      <icon name="description" flex="true" customClass="uk-text-primary"></icon>
      <div class="uk-margin-small-left">
        <p class="uk-text-bold uk-margin-xsmall-bottom">Give us your feedback</p>
        <span class="uk-text-small uk-text-meta">Give general feedback of this platform.</span>
      </div>
    </div>
  </a>
  <fs-modal #fsModal>
    <div class="uk-section uk-container uk-container-large uk-flex uk-flex-center">
      <div class="uk-padding-small uk-width-1-2@l uk-width-2-3@m uk-width-1-1">
        <contact-us [sending]="sending" [contactForm]="contactForm" (sendEmitter)="send($event)">
          <h1 page-title class="uk-visible@m uk-margin-auto uk-text-center" >
            Contact us<span class="uk-text-primary">.</span>
          </h1>
          <h1 page-title class="uk-hidden@m uk-heading-large uk-margin-auto uk-text-center">
            Contact us<span class="uk-text-primary">.</span>
          </h1>
        </contact-us>
      </div>
    </div>
  </fs-modal>
</ng-container>
