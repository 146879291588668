import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {NavigationStart, Router} from "@angular/router";

@Component({
  selector: 'mobile-dropdown',
  template: `
      <div class="uk-dropdown-mobile" [class.uk-open]="opened" (click)="close()">
          <div class="uk-dropdown-mobile-container" (click)="$event.stopPropagation()">
              <a class="uk-close" (click)="close()">
                  <icon name="close" [ratio]="1.2"></icon>
              </a>
              <div class="uk-content">
                  <ng-content></ng-content>
              </div>
          </div>
      </div>`
})
export class MobileDropdownComponent implements OnInit{
  @Input() toggle: HTMLAnchorElement | HTMLButtonElement;
  @Output() onClose: EventEmitter<void> = new EventEmitter;
  public opened: boolean = false;
  private static MOBILE_DROPDOWN_CONTAINER = 'mobile-dropdown-container';

  constructor(private element: ElementRef, private router: Router, private cdr: ChangeDetectorRef) {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationStart) {
        this.element.nativeElement.remove();
      }
    });
  }

  ngOnInit() {
    if(this.toggle) {
      this.toggle.onclick = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if(this.opened) {
          this.close();
        } else {
          this.open();
        }
      }
    }
  }

  open() {
    if(!this.opened) {
      let body = document.getElementsByTagName('body')[0];
      let container = document.getElementById(MobileDropdownComponent.MOBILE_DROPDOWN_CONTAINER);
      if(!container) {
        container = document.createElement('div');
        container.setAttribute('id', MobileDropdownComponent.MOBILE_DROPDOWN_CONTAINER);
        body.append(container);
      }
      let parent = this.element.nativeElement.parentElement;
      parent.removeChild(this.element.nativeElement);
      container.append(this.element.nativeElement);
      this.opened = true;
      this.cdr.markForCheck();
      body.setAttribute('style', 'overflow-y: hidden');
    }
  }

  close() {
    if(this.opened) {
      this.opened = false;
      this.cdr.detectChanges();
      this.onClose.emit();
      document.getElementsByTagName('body')[0].setAttribute('style', '');
    }
  }
}
