import {HttpHeaders} from "@angular/common/http";

export type MediaType = 'application/json' | 'text/plain'

export class CustomOptions {

  public static registryOptions(mediaType: MediaType = 'application/json'): {} {
    let httpHeaders = new HttpHeaders({
      'Content-Type': mediaType,
    });
    return {headers: httpHeaders, withCredentials: true};
  }

  public static getAuthOptionsWithBody():{} {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }), withCredentials: true
    };
  }

  public static getAuthOptions():{} {
    return this.getAuthOptionsWithBody();
  }

}
