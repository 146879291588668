<div id="help-pop-up">
  <button class="uk-button uk-button-primary uk-box-shadow-default uk-box-shadow-default-hover uk-flex uk-flex-middle" (click)="helpPopUpClicked = true">
    <icon *ngIf="!showDrop" name="help_outline" [flex]="true"></icon>
    <icon *ngIf="showDrop" name="close" [flex]="true"></icon>
    <span class="uk-margin-small-left">Help</span>
  </button>
  <div #drop id="pop-up" class="uk-drop" uk-drop="mode: click; pos: top-right; offset: 20">
    <div *ngIf="helpPopUpClicked" class="uk-card uk-card-default uk-card-body uk-box-shadow-default">
      <div class="uk-flex uk-flex-top uk-margin-medium-bottom">
        <icon name="ondemand_video" flex="true" customClass="uk-text-primary"></icon>
        <div class="uk-margin-small-left">
          <p class="uk-text-bold uk-margin-small-bottom">Basic interactivity functions</p>
          <a class="uk-link-reset" (click)="openGuideModal()">
            <img src="assets/irish-assets/interactive_functions_preview.png" alt="Basic interactive functions" loading="lazy">
          </a>
        </div>
      </div>
      <a routerLink="/methodology/terminology" target="_blank" class="uk-link-reset">
        <div class="uk-flex uk-flex-top uk-margin-medium-bottom">
          <icon name="info" flex="true" customClass="uk-text-primary"></icon>
          <div class="uk-margin-small-left">
            <p class="uk-text-bold uk-margin-xsmall-bottom">Terminology and construction</p>
            <span class="uk-text-small uk-text-meta">Do you have questions on our terminology?</span>
          </div>
        </div>
      </a>
      <contact [page]="false"></contact>
    </div>
  </div>
</div>

<modal-alert #guideModal [large]="true">
  <div>
    <img src="assets/irish-assets/interactive_functions.gif" alt="Basic interactive functions">
  </div>
</modal-alert>