export class LoginErrorCodes {
   public static NOT_LOGIN = 1;
   public static NOT_ADMIN = 2;
   public static NOT_VALID = 3;
   public static NOT_CONNECT_ADMIN = 4;
   public static NO_COMMUNITY = 5;
   public static NOT_SUBSCRIBER = 6;
   public static ACTION_REQUIRES_LOGIN = 7;
   public static NOT_AUTHORIZED = 8;
}
