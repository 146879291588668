import {Component, Input} from '@angular/core';
import 'rxjs';

@Component({
  selector: 'helper',
  template: `
  <div *ngIf=" texts  && texts.length > 0 && !tooltip" [ngClass]=styleName>
    <div *ngFor="let text of texts " [innerHTML]="text.content | safeHtml">
    </div>
  </div>
  <span *ngIf=" texts  && texts.length > 0 && tooltip" >
  <span   uk-tooltip="pos:right; delay:10; "
          title="{{buildTooltip()}}">
     <span class="uk-icon">&nbsp;<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" icon="info" ratio="1"><path d="M12.13,11.59 C11.97,12.84 10.35,14.12 9.1,14.16 C6.17,14.2 9.89,9.46 8.74,8.37 C9.3,8.16 10.62,7.83 10.62,8.81 C10.62,9.63 10.12,10.55 9.88,11.32 C8.66,15.16 12.13,11.15 12.14,11.18 C12.16,11.21 12.16,11.35 12.13,11.59 C12.08,11.95 12.16,11.35 12.13,11.59 L12.13,11.59 Z M11.56,5.67 C11.56,6.67 9.36,7.15 9.36,6.03 C9.36,5 11.56,4.54 11.56,5.67 L11.56,5.67 Z"></path><circle fill="none" stroke="#000" stroke-width="1.1" cx="10" cy="10" r="9"></circle></svg></span>
    </span>
  </span>
`
})
export class HelperComponent {
  @Input() texts = [];
  @Input() style:boolean = false;
  @Input() styleName:string = '';
  @Input() tooltip:boolean = false;
  
  constructor () {}

  buildTooltip(): string {
    var text:string="<div class='uk-padding-small uk-width-xxlarge'>";
    for(var i=0; i< this.texts.length; i++){
      text+=this.texts[i].content;
    }
    text+="</div>";
    return text;
  }

}
