import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, merge, forkJoin} from 'rxjs';
import {UserProfileService} from "../openaireLibrary/services/userProfile.service";
import {filter, map, take} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {UserManagementService} from "../openaireLibrary/services/user-management.service";
import {LoginErrorCodes} from "../openaireLibrary/login/utils/guardHelper.class";
@Injectable({
  providedIn: 'root'
})
export class HasConsentGuard {

  constructor(private router: Router,
              private _userProfileService: UserProfileService,
              private userManagementService: UserManagementService) {
  }

  check(path: string):   Observable<boolean> | boolean  {
    return forkJoin([
      this.userManagementService.getUserInfo().pipe(take(1)),
      this._userProfileService.getUserProfile().pipe(take(1))
    ]).pipe(
      map(([user, userProfile]) => {
        if (user) {
          if(userProfile && userProfile.consent){
          return true;
          }else{
            this.router.navigate(['/user-policy'], {
              queryParams: {
                'redirectUrl': path
              }
            });
            return false;
          }
        }else{
          this.router.navigate(['/user-info'], {
            queryParams: {
              'errorCode': LoginErrorCodes.NOT_LOGIN,
              'redirectUrl': path
            }
          })
          return false;
        }
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(state.url);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(state.url);
  }
/*
  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.check('/' + route.path);
  }*/
}
