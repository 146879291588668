export interface Icon {
  name?: string,
  svg?: string,
  class?: string,
  ratio?: number
}

export class MenuItem {
  _id: string = ""; // for root menu in order to close the dropdown when clicked
  title: string = "";
  type: string = "internal";
  url: string = ""; // external url
  route: string = ""; // internal  url - using angular routing and components
  routeActive: string = ""; // route to check if it is active
  needsAuthorization: boolean = false; // needs admin rights - mainly for user menu at this point
  entitiesRequired: string[] = []; // openaire entities used in page "publication, dataset, organization, software, project, datasource"
  routeRequired: string[] = []; // the routes that if aren't enable the menu item doesn't make sense
  params: any = {};
  fragment: string;
  items: MenuItem[] = [];
  icon: Icon;
  open: boolean;
  customClass: string = null;
  isFeatured: boolean;
  isActive: boolean;
  target: string = "_blank";
  badge?: string = ""; // used only for RDGraph portal (FAIRCORE4EOSC)

  constructor(id: string, title: string, url: string, route: string, needsAuthorization: boolean, entitiesRequired: string[],
              routeRequired: string[], params, icon: Icon = null, fragment = null, customClass = null, routeActive = null,
              target: string = "_blank", type: string = "internal", isFeatured: boolean = false, items: MenuItem[] = [], badge: string = "") {
    this._id = id;
    this.title = title;
    this.url = url;
    this.route = route;
    this.routeActive = routeActive;
    this.needsAuthorization = needsAuthorization;
    this.entitiesRequired = entitiesRequired;
    this.routeRequired = routeRequired;
    this.params = params;
    this.items = items;
    this.icon = icon;
    this.fragment = fragment;
    this.customClass = customClass;
    this.target = target;
    this.type = type;
    this.isFeatured = isFeatured;
    this.badge = badge;
  }

  public static isTheActiveMenu(menu: MenuItem, currentRoute: any, activeMenuItem: string = ""): boolean {
    if (menu.route && menu.route.length > 0 && MenuItem.isTheActiveMenuItem(menu, currentRoute, activeMenuItem)) {
      return true;
    } else if (menu.items.length > 0) {
      for (let menuItem of menu.items) {
        if (MenuItem.isTheActiveMenuItem(menuItem, currentRoute, activeMenuItem)) {
          return true;
        }
      }
    }
    return false;


  }

  private static isTheActiveMenuItem(menu: MenuItem, currentRoute: any, activeMenuItem: string) {
    return (
        ((menu.route == currentRoute.route || menu.route == (currentRoute.route + "/")) && currentRoute.fragment == menu.fragment))
      || (menu.routeActive && (currentRoute.route.startsWith(menu.routeActive)))
      || (menu._id && menu._id === activeMenuItem);
  }


}

export class MenuItemExtended extends MenuItem {
  isOpen: boolean = false;
  parentItemId: string;
}

export class Menu {
  portalPid: string;
  isFeaturedMenuEnabled: boolean;
  isMenuEnabled: boolean;
  featuredAlignment: MenuAlignment;
  featuredMenuItems: MenuItemExtended[] = [];
  menuItems: MenuItemExtended[] = [];
}

export class SideMenuItem extends MenuItem {
  ukIcon: string = '';
}

export enum MenuAlignment {LEFT = "LEFT", CENTER = "CENTER", RIGHT = "RIGHT"}
