import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {Session, User} from "../login/utils/helper.class";
import {map} from "rxjs/operators";
import {properties} from "../../../environments/environment";
import {StringUtils} from "../utils/string-utils.class";
import {CustomOptions} from "./servicesUtils/customOptions.class";
import {AdvancedAsyncSubject} from "../utils/AdvancedAsyncSubject";
import {isArray} from "rxjs/internal-compatibility";

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  private readonly getUserInfoSubject: AdvancedAsyncSubject<User> = new AdvancedAsyncSubject<User>();
  private static LOGIN = 'openid_connect_login';
  private static LOGOUT: string = 'openid_logout';
  private static USERINFO: string = 'userInfo';
  public fixRedirectURL: string = null;
  public allowDoubleRedirectToFixAndCurrentPage: boolean = false;
  private redirectUrl: string = null;
  private subscription;
  private readonly routerSubscription;
  
  constructor(private http: HttpClient) {
    this.updateUserInfo();
  }
  
  clearSubscriptions() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  public static userInfoUrl(index = 0): string {
    return (isArray(properties.loginServiceURL)?properties.loginServiceURL[index]:properties.loginServiceURL) + UserManagementService.USERINFO;
  }

  public get user(): User {
    return this.getUserInfoSubject.getValue();
  }
  
  public getUserInfo(): Observable<User> {
    return this.getUserInfoSubject.asObservable();
  }

  public getUserInfoAt(index = 0): Observable<User> {
    return this.http.get<User>(UserManagementService.userInfoUrl(index), CustomOptions.registryOptions()).pipe(map(userInfo => {
      return new User(userInfo);
    }));
  }


  public updateUserInfo(resolve: Function = null) {
    this.subscription = this.getUserInfoAt().subscribe(user => {
      this.getUserInfoSubject.next(user);
      if (resolve) {
        resolve();
      }
    }, error => {
      this.getUserInfoSubject.next(null);
      if (resolve) {
        resolve();
      }
    });
  }

  public setRedirectUrl(redirectTo: string | string [] = null) {
    let urls = Array.isArray(redirectTo)?redirectTo:(redirectTo?[redirectTo]:[])
    for(let url of urls) {
      let parts = url.split('?');
      let path = properties.baseLink + parts[0];
      let params = null;
      if (parts.length == 2) {
        params = parts[1];
      }
      let hash = path.indexOf("#");
      let fragment = (hash !== -1) ? path.slice(hash + 1) : null;
      if (fragment) {
        path = path.slice(0, hash);
      } else {
        fragment = "";
      }
      if (!path.includes('/reload')) {
        Session.setReloadUrl(location.protocol + "//" + location.host, path, params, fragment);
      }
      this.redirectUrl = StringUtils.URIEncode(location.protocol + "//" + location.host + this.fixRedirectURL);
    }
      if(urls.length == 0){
      this.redirectUrl = StringUtils.URIEncode(location.href);
      Session.setReloadUrl(location.protocol + "//" + location.host, location.pathname, location.search, location.hash);
    }
  }
  
  public login(redirect: string = null) {
    Session.clearReloadUrl();
    if(redirect) {
      this.redirectUrl = redirect;
    } else if (this.fixRedirectURL) {
      this.setRedirectUrl(this.allowDoubleRedirectToFixAndCurrentPage?[this.fixRedirectURL,location.href.split(location.host)[1]]:this.fixRedirectURL);
    } else {
      this.setRedirectUrl();
    }
    let loginURL: string | string[] = isArray(properties.loginServiceURL)?properties.loginServiceURL.map(url => url + UserManagementService.LOGIN):(properties.loginServiceURL + UserManagementService.LOGIN);
    window.location.href = this.setURL(loginURL) + this.redirectUrl;
  }

  public logout() {
    Session.clearReloadUrl();
    this.setRedirectUrl();
    Session.removeUser();
    let logoutURL: string | string[] = isArray(properties.loginServiceURL)?properties.loginServiceURL.map(url => url + UserManagementService.LOGOUT):(properties.loginServiceURL + UserManagementService.LOGOUT);
    window.location.href = this.setURL(logoutURL) + this.redirectUrl;
  }

  setURL(url: string | string[]) {
    if(isArray(url)) {
      let redirectURL = '';
      url.forEach((url, index) => {
        if(index === 0) {
          redirectURL = url + "?redirect=";
        } else {
          redirectURL += encodeURIComponent(url + "?redirect=");
        }
      });
      return redirectURL;
    } else {
      return url + "?redirect=";
    }
  }
}
